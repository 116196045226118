import { modSharedService } from "./modShared.service";
import { FormControl, AbstractControl } from "@angular/forms";
import { Injectable } from "@angular/core";

@Injectable()
export class CustomValidators {

  constructor(private modShared: modSharedService) { }

  Numeric(ctlControl: AbstractControl): { [key: string]: boolean } | null {

    let strValue: string = "";
    let dblNumber: number; 

    try {

      if (ctlControl.value == undefined) {

        // Undefined is bad, raise an error
        return { 'Numeric': true };

      } else {

        strValue = ctlControl.value.toString();
        strValue = strValue.replace(",", "");
        strValue = strValue.replace("$", "");

        dblNumber = Number(strValue);

        if (isNaN(dblNumber)) {

          // Non-blank and not a number.
          return { 'Numeric': true };

        }

      }

      return null;
      
    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }


  NumericGreaterThanZero(ctlControl: AbstractControl): { [key: string]: boolean } | null {

    let strValue: string = "";
    let dblNumber: number;

    try {

      if (ctlControl.value == undefined) {

        // Undefined is bad, raise an error
        return { 'NumericGreaterThanZero': true };

      } else {

        strValue = ctlControl.value.toString();
        strValue = strValue.replace(",", "");
        strValue = strValue.replace("$", "");

        dblNumber = Number(strValue);

        if (strValue.length !== 0) {

          if (isNaN(dblNumber)) {

            return { 'NumericGreaterThanZero': true };

          } else {

            if (dblNumber <= 0) {

              return { 'NumericGreaterThanZero': true };

            }

          }

        } else {

          return { 'NumericGreaterThanZero': true };

        }

      }

      return null;

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  NumericOrEmptyGreaterThanOrEqualToZero(ctlControl: AbstractControl): { [key: string]: boolean } | null {

    let strValue: string = "";
    let dblNumber: number;

    try {

      if (ctlControl.value == undefined) {

        // Undefined is bad, raise an error
        return { 'NumericOrEmptyGreaterThanOrEqualToZero': true };

      } else {

        strValue = ctlControl.value.toString();
        strValue = strValue.replace(",", "");
        strValue = strValue.replace("$", "");

        dblNumber = Number(strValue);

        if (strValue.length !== 0) {

          if (isNaN(dblNumber)) {

            return { 'NumericOrEmptyGreaterThanOrEqualToZero': true };

          } else {

            // it's a number, make sure it's greater than or equal to zero
            if (dblNumber < 0) {

              return { 'NumericOrEmptyGreaterThanOrEqualToZero': true };

            } // dblNumber < 0

          } // else IsNaN 

        } // strValue.Length !== 0

      } // else: not Defined

      return null;

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }



  NumericOrEmpty(ctlControl: AbstractControl): { [key: string]: boolean } | null {

    let strValue: string = "";
    let dblNumber: number; 

    try {

      if (ctlControl.value == undefined) {

        // Undefined is bad, raise an error
        return { 'NumericOrEmpty': true };

      } else {

        strValue = ctlControl.value.toString();
        strValue = strValue.replace(",", "");
        strValue = strValue.replace("$", "");

        dblNumber = Number(strValue);

        if (strValue.length !== 0) {

          if (isNaN(dblNumber)) {

            return { 'NumericOrEmpty': true };

          }

        }

      }

      return null;

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  CheckboxRequired(ctlControl: AbstractControl): { [key: string]: boolean } | null {

    try {

      if (ctlControl.value == undefined) {

        // Undefined is bad, raise an error
        return { 'CheckboxRequired': true };

      } else {

        // We have a value, make sure it's not 0
        if (ctlControl.value == false) {

          return { 'CheckboxRequired': true };

        }

      }

      return null;

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }


  DropdownRequired(ctlControl: AbstractControl): { [key: string]: boolean } | null {

    try {

      if (ctlControl.value == undefined) {

        // Undefined is bad, raise an error
        return { 'DropdownRequired': true };

      } else {

        // We have a value, make sure it's not 0
        if (ctlControl.value == 0) {

          return { 'DropdownRequired': true };

        }

      }

      return null;

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

}
