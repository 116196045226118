import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { modSharedService } from 'app/shared/services/modShared.service';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl, RequiredValidator } from '@angular/forms';
import { ApiResponse } from '../../shared/models/api-response.model';
import { AccountService } from '../../shared/services/account.service';
import { ABCMessage } from '../../shared/models/abc-message.model';
import { ClientsService } from '../../shared/services/clients.service';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';

interface Client {
  Ident: number,
  ParentClientIdent: number,
  Name1: string;
  PublicName1: string,
  ClientNumber: string,
  LeftBound: number,
  RightBound: number,
  ClientTreeIdent: number,
  SortOrder: number,
  Children: Client[];
}


@Component({
  selector: 'app-change-client-focus',
  templateUrl: './change-client-focus.component.html',
  styleUrls: ['./change-client-focus.component.css']
})
export class ChangeClientFocusComponent implements OnInit {

  // Properties
  mfrmForm: UntypedFormGroup;
  mtrTree = new NestedTreeControl<Client>(node => node.Children);
  mobjClientStructure = new MatTreeNestedDataSource<Client>();
  mclRootClient: Client = null;
  mndCurrentNode: any;
  mbolDisplayTree: boolean = false; 

  // Input parameters

  // Global Arrays
  marSearchResults: any[] = [];

  constructor(public activeModal: NgbActiveModal,
    private modShared: modSharedService,
    private svcAccountService: AccountService,
    private svcClientsService: ClientsService) { }

  ngOnInit() {


    try {

      this.mfrmForm = new UntypedFormGroup({
        'ClientNameOrNumber': new UntypedFormControl("", [])
      });

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  Cancel() {

    let objResult: any = {};

    try {

      // Don't need any logic here, either they added docs or not.
      objResult.RecordSaved = false;
      this.activeModal.close(objResult);
      this.modShared.MessagesCleared.next();

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  Search() {

    let strClientNameOrNumber: string = ""; 
    let arClientTree: Client[] = [];

    try {

      strClientNameOrNumber = this.mfrmForm.controls.ClientNameOrNumber.value;

      if (isNaN(+strClientNameOrNumber) || strClientNameOrNumber == "") {

        this.svcClientsService.SearchClientsByName(strClientNameOrNumber)
          .subscribe(

            (rspResponse: ApiResponse) => {

              if (rspResponse.Succeeded) {

                // Successful.
                this.marSearchResults = this.modShared.ExtractDataFromPayload(rspResponse, "Client");
                this.mbolDisplayTree = false; 

              } // Succeeded

            });


      } else {

        this.svcClientsService.SearchClientByClientNumber(strClientNameOrNumber)
          .subscribe(

            (rspResponse: ApiResponse) => {

              if (rspResponse.Succeeded) {

                // Successful.
                this.marSearchResults = this.modShared.ExtractDataFromPayload(rspResponse, "Client");

                this.mclRootClient = this.modShared.ExtractValueFromPayload(rspResponse, "ClientStructure");
                arClientTree.push(this.mclRootClient);

                this.mobjClientStructure.data = arClientTree;
                this.mtrTree.dataNodes = arClientTree;

                if (this.mtrTree.dataNodes[0] != undefined) {

                  this.mndCurrentNode = this.mtrTree.dataNodes[0];

                  this.ExpandNode(this.mtrTree.dataNodes[0].Ident);

                  this.mbolDisplayTree = true;

                } 

              } // Succeeded

            });

      } 



    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  ExpandNode(intClientIdent: number) {

    let ndNodeToExpand: any;

    try {

      if (intClientIdent == this.mtrTree.dataNodes[0].Ident) {

        // They are grabbing the root, just grab it
        ndNodeToExpand = this.mtrTree.dataNodes[0];

      } else {

        ndNodeToExpand = this.mtrTree.getDescendants(this.mtrTree.dataNodes[0]).find(x => x.Ident == intClientIdent);

      }

      if (ndNodeToExpand !== undefined) {

        this.mtrTree.expand(ndNodeToExpand);

        if (ndNodeToExpand.ParentClientIdent !== 0) {

          this.ExpandNode(ndNodeToExpand.ParentClientIdent);

        }

      }


    } catch (ex) {

      this.modShared.LogClientError(ex);

    }


  }


  hasChild = (_: number, clClient: Client) => !!clClient.Children && clClient.Children.length > 0;

  SelectClient(intClientIdent: number, itmClient: any, bolFromTree: boolean) {

    let objResult: any = {};
    let arClientTree: Client[] = [];

    try {

      if (bolFromTree
          || itmClient.LeftBound == (itmClient.RightBound - 1)) {

        // No logic needed, just return the client info.
        objResult.RecordSaved = true;
        objResult.ClientIdent = intClientIdent;

        this.activeModal.close(objResult);
        this.modShared.MessagesCleared.next();

      } else {

        // Need to pull back the tree
        this.svcClientsService.GetClientAndStructureByIdent(itmClient.Ident)
          .subscribe(

            (rspResponse: ApiResponse) => {

              if (rspResponse.Succeeded) {

                // Successful.
                this.mclRootClient = this.modShared.ExtractValueFromPayload(rspResponse, "ClientStructure");
                arClientTree.push(this.mclRootClient);

                this.mobjClientStructure.data = arClientTree;
                this.mtrTree.dataNodes = arClientTree;
                this.mndCurrentNode = this.mtrTree.dataNodes[0];

                this.ExpandNode(this.mtrTree.dataNodes[0].Ident);

                this.mbolDisplayTree = true;

              } // Succeeded

            });

      } 

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }


  }

}
