import { Directive, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[disableControl]'
})
export class DisableControlDirective {

  //MTP - Changing this as a result of the angular v8-v16 changes. 10/27/2023
  //@Input() set disableControl(condition: boolean) {
  //  const action = condition ? 'disable' : 'enable';
  //  this.ngControl.control[action]();
  //  //this.ngControl.control.disable();
  //}

  //@Input('appDisable') set disableControl(condition: boolean) {
  //  const action = condition ? 'disable' : 'enable';
  //  setTimeout(() => {
  //    this.ngControl.control[action]();
  //  });
  //}

  @Input() disableControl;
  ngOnChanges(changes) {
    if (changes['disableControl']) {
      const action = this.disableControl ? 'disable' : 'enable';
      this.ngControl.control[action]();
    }
  }


  constructor(private ngControl: NgControl) {
  }

}
