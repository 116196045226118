import { Component, OnDestroy, OnInit } from '@angular/core';
import { modSharedService } from '../shared/services/modShared.service';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';
import { ApiResponse } from '../shared/models/api-response.model';
import { MaintenanceService } from '../shared/services/maintenance.service';
import { CustomValidators } from '../shared/services/custom-validators';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientsService } from 'app/shared/services/clients.service';
import { Subject, Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { Validator } from '../shared/dynamic-controls/form-controls/form-control-base';
import { ABCMessage } from '../shared/models/abc-message.model';
import { AccountService } from '../shared/services/account.service';
import { AuthenticationService } from '../shared/services/authentication.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-claim-submission',
  templateUrl: './claim-submission.component.html',
  styleUrls: ['./claim-submission.component.css']
})
export class ClaimSubmissionComponent implements OnInit, OnDestroy {

  // Subscriptions
  OnNavigation: Subscription; 


  menmScreenModes = {
    Search: 0,
    Edit: 1
  }

  menmSubmissionType = {
    Domestic: 1,
    Foreign: 2
  } 

  ngOnDestroy() {

    // This is needed to allow for same-navigation reload.

    try {

      if (this.OnNavigation) {

        this.OnNavigation.unsubscribe();

      }


    } catch (ex) {

      this.modShared.LogClientError(ex);

    }


  }

  // Dropdowns
  marAllState: any[] = [];
  marClientState: any[] = [];
  marDebtorState: any[] = [];
  marCountry: any[] = [];
  marClaimSubmissionLegalStatus: any[] = []; 
  marTriState: any[] = [];
  marClaimSubmissionTerm: any[] = [];
  marClaimSubmissionDocumentType: any[] = [];
  marClaimSubmissionStatus: any[] = [];

  // Data
  marFilesAttached: string[] = [];

  // Properties
  ScreenMode: number = this.menmScreenModes.Edit;
  mfrmForm: UntypedFormGroup;
  mfilFileAttachmentData: File = null;
  mstrAttachmentFileName: string = "";
  mstrUserFolderGUID: string = "";
  mstrAttachmentFileUploadBytes: string = "";
  mitmClient: any = {};
  mintSubmissionType: number

  constructor(private modShared: modSharedService,
    private svcAccountService: AccountService,
    private svcClientsService: ClientsService,
    private svcMaintenanceService: MaintenanceService,
    private valCustomValidators: CustomValidators,
    private rtRouter: Router) {

    try {

      if (this.rtRouter.getCurrentNavigation() != undefined
        && this.rtRouter.getCurrentNavigation().extras != undefined
        && this.rtRouter.getCurrentNavigation().extras.state != undefined
        && this.rtRouter.getCurrentNavigation().extras.state.ClaimSubmissionType != undefined) {

        this.mintSubmissionType = this.rtRouter.getCurrentNavigation().extras.state.ClaimSubmissionType;


      } else {

        this.mintSubmissionType = 2; 

        console.log("No State!");

      }

      this.OnNavigation = this.rtRouter.events.subscribe((e: any) => {
        // If it is a NavigationEnd event re-initalise the component
        if (e instanceof NavigationEnd) {

          if (this.rtRouter.getCurrentNavigation() != undefined
            && this.rtRouter.getCurrentNavigation().extras != undefined
            && this.rtRouter.getCurrentNavigation().extras.state != undefined
            && this.rtRouter.getCurrentNavigation().extras.state.ClaimSubmissionType != undefined) {

            this.mintSubmissionType = this.rtRouter.getCurrentNavigation().extras.state.ClaimSubmissionType;

          }

          this.ngOnInit(); 
        }
      });


    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  ngOnInit() {

    let arTablesToRetrieve: string[] = [];
    let dtStartDate: Date = new Date();

    try {

      this.mfrmForm = new UntypedFormGroup({
        'ClientNo': new UntypedFormControl("", []),
        'ClientEmail': new UntypedFormControl("", []),
        'ClientName': new UntypedFormControl("", []),
        'ClientContact': new UntypedFormControl("", []),
        'ClientAddress1': new UntypedFormControl("", []),
        'ClientAddress2': new UntypedFormControl("", []),
        'ClientAddress3': new UntypedFormControl("", []),
        'ClientCity': new UntypedFormControl("", []),
        'ClientStateIdent': new UntypedFormControl(0, []),
        'ClientZip': new UntypedFormControl("", []),
        'ClientCountryIdent': new UntypedFormControl(0, []),
        'ClientPhone': new UntypedFormControl("", []),
        'ClientFax': new UntypedFormControl("", []),
        'DebtorName': new UntypedFormControl("", [Validators.required]),
        'DebtorOwner': new UntypedFormControl("", []),
        'DebtorContact': new UntypedFormControl("", [Validators.required]),
        'DebtorAddress1': new UntypedFormControl("", [Validators.required]),
        'DebtorAddress2': new UntypedFormControl("", []),
        'DebtorAddress3': new UntypedFormControl("", []),
        'DebtorCity': new UntypedFormControl("", [Validators.required]),
        'DebtorStateIdent': new UntypedFormControl(0, []),
        'DebtorZip': new UntypedFormControl("", [Validators.required]),
        'DebtorCountryIdent': new UntypedFormControl(0, [this.valCustomValidators.DropdownRequired]),
        'DebtorPhone': new UntypedFormControl("", [Validators.required]), 
        'DebtorFax': new UntypedFormControl("", []),
        'CustomerNo': new UntypedFormControl("", [Validators.required]),
        'DebtorEmail': new UntypedFormControl("", []),
        'DebtorBank': new UntypedFormControl("", []),
        'BankPhone': new UntypedFormControl("", []),
        'ClaimSubmissionLegalStatusIdent': new UntypedFormControl(0, []),
        'DebtAmt': new UntypedFormControl("", [this.valCustomValidators.NumericGreaterThanZero]),
        'Currency': new UntypedFormControl("", [Validators.required]),
        'OldestInv': new UntypedFormControl("", []),
        'LastPay': new UntypedFormControl("", []),
        'ClaimSubmissionTermIdent': new UntypedFormControl(0, [this.valCustomValidators.DropdownRequired]),
        'RecGoodsTriStateIdent': new UntypedFormControl(0, [this.valCustomValidators.DropdownRequired]),
        'BillExchTriStateIdent': new UntypedFormControl(0, [this.valCustomValidators.DropdownRequired]),
        'BillLadingTriStateIdent': new UntypedFormControl(0, [this.valCustomValidators.DropdownRequired]),
        'ProtestTriStateIdent': new UntypedFormControl(0, []),
        'BankDemandTriStateIdent': new UntypedFormControl(0, []),
        'Status': new UntypedFormControl("", []),
        'Other': new UntypedFormControl("", []),
        'Documents': new UntypedFormControl("", []),
        'Mail': new UntypedFormControl(false, []),
        'Fax': new UntypedFormControl(false, []),
        'Comments': new UntypedFormControl("", []),
        'FileAttachment': new UntypedFormControl({ value: '', disabled: true }, []),
        'FileAttachmentUpload': new UntypedFormControl(null, []),
        'ClientIdent': new UntypedFormControl(0, []),
        'ClaimSubmissionTypeIdent': new UntypedFormControl(this.mintSubmissionType, []),
        'ClientTerritory': new UntypedFormControl("", []),
        'Referral': new UntypedFormControl("", []),
        'Email': new UntypedFormControl(false, []),
        'FinalDemandTriStateIdent': new UntypedFormControl(false, [])
      });

      arTablesToRetrieve.push("State");
      arTablesToRetrieve.push("ClaimSubmissionLegalStatus");
      arTablesToRetrieve.push("TriState");
      arTablesToRetrieve.push("ClaimSubmissionTerm");
      arTablesToRetrieve.push("ClaimSubmissionStatus");
      arTablesToRetrieve.push("ClaimSubmissionDocumentType");
      
      this.svcMaintenanceService.GetAllByList(arTablesToRetrieve)
        .subscribe(

          (rspResponse: ApiResponse) => {

            if (rspResponse.Succeeded) {

              // Successful.
              this.marAllState = this.modShared.ExtractDropdownFromPayload(rspResponse, "StateGetAll");
              this.marClientState = this.modShared.ExtractDropdownFromPayload(rspResponse, "StateGetAll");
              this.marDebtorState = this.modShared.ExtractDropdownFromPayload(rspResponse, "StateGetAll");
              this.marClaimSubmissionLegalStatus = this.modShared.ExtractDropdownFromPayload(rspResponse, "ClaimSubmissionLegalStatusGetAll");
              this.marTriState = this.modShared.ExtractDropdownFromPayload(rspResponse, "TriStateGetAll").filter(x => x.Ident != 0)
                .sort((a, b) => a.Ident > b.Ident ? 1 : -1);
              this.marClaimSubmissionTerm = this.modShared.ExtractDropdownFromPayload(rspResponse, "ClaimSubmissionTermGetAll");
              this.marClaimSubmissionStatus = this.modShared.ExtractDataFromPayload(rspResponse, "ClaimSubmissionStatusGetAll");
              this.marClaimSubmissionDocumentType = this.modShared.ExtractDataFromPayload(rspResponse, "ClaimSubmissionDocumentTypeGetAll");

              this.mfrmForm.get('ClientCountryIdent').valueChanges.subscribe(intCountryIdent => {

                this.marClientState = this.marAllState.slice().filter(itmState => itmState.CountryIdent == intCountryIdent || itmState.Ident == 0);

                if (this.marClientState.find(itmState => itmState.Ident == this.mfrmForm.controls.ClientStateIdent.value) == undefined) {

                  this.mfrmForm.controls.ClientStateIdent.patchValue(0);

                }

              });

              this.mfrmForm.get('DebtorCountryIdent').valueChanges.subscribe(intCountryIdent => {

                this.marDebtorState = this.marAllState.slice().filter(itmState => itmState.CountryIdent == intCountryIdent || itmState.Ident == 0);

                if (this.marDebtorState.find(itmState => itmState.Ident == this.mfrmForm.controls.DebtorStateIdent.value) == undefined) {

                  this.mfrmForm.controls.DebtorStateIdent.patchValue(0);

                }

              });

              this.svcClientsService.GetClientAndStructureByIdent(this.modShared.mintClientFocusIdent)
                .subscribe(

                  (rspResponse: ApiResponse) => {

                    if (rspResponse.Succeeded) {

                      // Successful.
                      this.mitmClient = this.modShared.ExtractDataFromPayload(rspResponse, "Client")[0];

                      this.PopulateClientFields();

                      // Have to get Country Separately because it pushed US to the top
                      this.svcMaintenanceService.GetAllActiveCountry()
                        .subscribe(

                          (rspResponse: ApiResponse) => {

                            if (rspResponse.Succeeded) {

                              // Successful.
                              // Don't want to extract Dropdown because we want US to bubble to the top
                              this.marCountry = this.modShared.ExtractDataFromPayload(rspResponse, "Country");

                            } // Succeeded

                          });

                    } // Succeeded

                  });


            } // Succeeded

          });


      if (this.mintSubmissionType == modSharedService.enmClaimSubmissionFormType.Domestic) {

        this.mfrmForm.controls.Currency.patchValue("USD");
        this.mfrmForm.controls.BillExchTriStateIdent.clearValidators(); 

      } else {

        this.mfrmForm.controls.Currency.patchValue("");

      }


    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  ClearClaim() {

    let bolContinue: boolean = true;

    try {

      if (this.mfrmForm.dirty) {

        bolContinue = confirm("You have made changes to this claim, are you sure you want to clear this information?");
        
      }

      if (bolContinue) {

        this.ClearControls();

      }



    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  } 

  PopulateClientFields() {

    try {

      this.mfrmForm.controls.ClientNo.patchValue(this.mitmClient.ClientNumber); 
      this.mfrmForm.controls.ClientEmail.patchValue(this.mitmClient.Email);
      this.mfrmForm.controls.ClientName.patchValue(this.mitmClient.PublicName1);
      this.mfrmForm.controls.ClientAddress1.patchValue(this.mitmClient.Address1);
      this.mfrmForm.controls.ClientAddress2.patchValue(this.mitmClient.Address2);
      this.mfrmForm.controls.ClientAddress3.patchValue(this.mitmClient.Address3);
      this.mfrmForm.controls.ClientCity.patchValue(this.mitmClient.City);
      this.mfrmForm.controls.ClientStateIdent.patchValue(this.mitmClient.StateIdent);
      this.mfrmForm.controls.ClientZip.patchValue(this.mitmClient.Zip);
      this.mfrmForm.controls.ClientCountryIdent.patchValue(this.mitmClient.CountryIdent);
      this.mfrmForm.controls.ClientPhone.patchValue(this.mitmClient.Phone);
      this.mfrmForm.controls.ClientFax.patchValue(this.mitmClient.Fax);
      this.mfrmForm.controls.ClientIdent.patchValue(this.mitmClient.Ident);

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }


  } 

  GetClaimSubmissionEmail(): string {

    try {

      return modSharedService.gstrClaimSubmissionEmailAddress; 

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }


  } 

  EditEnabled(): boolean {

    try {

      return this.ScreenMode === this.menmScreenModes.Edit;

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  } // EditEnabled()

  Cancel() {

    let bolContinue: boolean = true;

    try {

      if (this.mfrmForm.dirty) {

        bolContinue = confirm("You have made changes to this claim, are you sure you want to cancel?"); 

      }

      if (bolContinue) {

        this.rtRouter.navigate(['/dashboard']);

      }
           


    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  
  ClearControls() {

    let objSave: any = null;
    let dtStartDate: Date = new Date();

    try {

      // Save the search controls 
      objSave = this.modShared.SaveSearchParameters(this.mfrmForm);

      this.mfrmForm.reset();

      // Reset initial values
      // TODO: Fill in
      this.mfrmForm.setValue({
        ClientNo: "",
        ClientEmail: "",
        ClientName: "",
        ClientContact: "",
        ClientAddress1: "",
        ClientAddress2: "",
        ClientAddress3: "",
        ClientCity: "",
        ClientStateIdent: 0,
        ClientZip: "",
        ClientCountryIdent: 0,
        ClientPhone: "",
        ClientFax: "",
        DebtorName: "",
        DebtorOwner: "",
        DebtorContact: "",
        DebtorAddress1: "",
        DebtorAddress2: "",
        DebtorAddress3: "",
        DebtorCity: "",
        DebtorStateIdent: 0,
        DebtorZip: "",
        DebtorCountryIdent: 0,
        DebtorPhone: "",
        DebtorFax: "",
        CustomerNo: "",
        DebtorEmail: "",
        DebtorBank: "",
        BankPhone: "",
        ClaimSubmissionLegalStatusIdent: 0,
        DebtAmt: "",
        Currency: "",
        OldestInv: "",
        LastPay: "",
        ClaimSubmissionTermIdent: 0,
        RecGoodsTriStateIdent: 0,
        BillExchTriStateIdent: 0,
        BillLadingTriStateIdent: 0,
        ProtestTriStateIdent: 0,
        BankDemandTriStateIdent: 0,
        Status: "",
        Other: "",
        Documents: "",
        Mail: false,
        Fax: false,
        Comments: "",
        FileAttachmentUpload: null,
        ClientIdent: 0,
        ClaimSubmissionTypeIdent: this.mintSubmissionType,
        ClientTerritory: "",
        Referral: "",
        Email: false,
        FinalDemandTriStateIdent: false,
        FileAttachment: {
          value: '',
          FileAttachmentUpload: null }
        });

      // Repopulate the search controls 
      this.modShared.PopulateFormFromItem(this.mfrmForm, objSave);

      if (this.mintSubmissionType == modSharedService.enmClaimSubmissionFormType.Domestic) {

        this.mfrmForm.controls.Currency.patchValue("USD");

      } else {

        this.mfrmForm.controls.Currency.patchValue("");

      }

      this.PopulateClientFields(); 

      this.modShared.MessagesCleared.next();

    } catch (ex) {

      this.modShared.LogClientError(ex)

    }

  }

  IsDomesticSubmission(): boolean {

    try {

      return (this.mintSubmissionType == modSharedService.enmClaimSubmissionFormType.Domestic);

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  } 

  FileSelected(arFiles) {

    let rdrReader = new FileReader();

    try {

      console.log(arFiles);

      this.mfilFileAttachmentData = <File>arFiles[0];
      this.mstrAttachmentFileName = this.mfilFileAttachmentData.name;

      rdrReader.onload = function (readerEvt: any) {

        var arrayBuffer = readerEvt.target.result.toString().split('base64,')[1];

        //document.querySelector('#hidden_upload_item').innerHTML = arrayBuffer;
        this.mstrAttachmentFileUploadBytes = arrayBuffer;

      }.bind(this);

      rdrReader.readAsDataURL(this.mfilFileAttachmentData);

      // Alexandru Troponete
      
    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  UploadFileAttachment() {

    try {

      if (this.mfilFileAttachmentData != null || this.mstrAttachmentFileName != "") {

        this.svcAccountService.AddAttachmentToClaimSubmission(this.mstrAttachmentFileUploadBytes, this.mstrAttachmentFileName, this.mstrUserFolderGUID)
          .subscribe(

            (rspResponse: ApiResponse) => {

              if (rspResponse.Succeeded) {

                // Successful.
                this.mstrUserFolderGUID = this.modShared.ExtractValueFromPayload(rspResponse, "UserFolderGUID");

                this.marFilesAttached.push(this.mstrAttachmentFileName);

                this.mfilFileAttachmentData = null;
                this.mstrAttachmentFileName = "";

                this.mfrmForm.controls.FileAttachmentUpload.setValue(null);

              } // Succeeded

            });

      } else {

        this.modShared.MessagesCleared.next();
        this.modShared.AddMessage(new ABCMessage(0, "No File Selected!", modSharedService.enmABCMessageSeverity.Severe, ""));

      }

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  ClearAttachments() {

    try {

      this.svcAccountService.ClearAttachmentsFromClaimSubmission(this.mstrUserFolderGUID)
        .subscribe(

          (rspResponse: ApiResponse) => {

            if (rspResponse.Succeeded) {

              // Successful.
              this.marFilesAttached = [];

              this.mfilFileAttachmentData = null;
              this.mstrAttachmentFileName = "";

              this.mfrmForm.controls.FileAttachmentUpload.setValue(null);

            } // Succeeded

          });


    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  SubmitClaim(cmdSubmitClaim: any) {

    try {

      if (this.modShared.ValidateForm(this.mfrmForm, [])) {

        this.svcAccountService.AddClaimSubmission(this.mfrmForm, this.mstrUserFolderGUID, this.mfrmForm.controls.Status.value, this.mfrmForm.controls.Documents.value)
          .subscribe(

            (rspResponse: ApiResponse) => {

              if (rspResponse.Succeeded) {

                // Successful.
                this.modShared.MessagesCleared.next();
                this.modShared.AddMessage(new ABCMessage(0, "Your claim has been successfully submitted!", modSharedService.enmABCMessageSeverity.SaveSuccess, ""));

                this.rtRouter.navigate(['/dashboard']);

              } // Succeeded

            });

      } else {

        cmdSubmitClaim.focus();

      } 

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }


  } 
  
}
