import { Component, OnInit } from '@angular/core';
import { modSharedService } from '../../shared/services/modShared.service';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { ApiResponse } from '../../shared/models/api-response.model';
import { MaintenanceService } from '../../shared/services/maintenance.service';
import { CustomValidators } from '../../shared/services/custom-validators';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalVars } from '../../globalvars';
import { ReportingService } from 'app/shared/services/reporting.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {

  items: any;
  menuCollapsed: boolean;

  constructor(private modShared: modSharedService,
    private svcReportingService: ReportingService,
    private svcMaintenanceService: MaintenanceService,
    private svcModalService: NgbModal, private globalVars: GlobalVars) { }

  ngOnInit() {

    try {

      this.getReportTypes();

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  getReportTypes() {

    try {

      this.svcReportingService.GetAllActiveReportTypesAndReports().subscribe(res => {

        if (res && res.Payload && res.Payload.length && res.Payload[0].Data && res.Payload[0].Data.length) {

          let reportTypes = [];
          this.items = [];

          res.Payload[0].Data.forEach(d => {

            if (reportTypes.indexOf(d.ReportTypeName) < 0) {

              reportTypes.push(d.ReportTypeName)

            }

          });

          reportTypes.forEach(r => {

            let obj = {
              category: r,
              children: res.Payload[0].Data.filter(x => x.ReportTypeName === r)
            }

            this.items.push(obj);

          });

          this.items[0].active = true;

        }

      }, err => {
        this.modShared.LogClientError(err);
      });

    }
    catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }


  openAndCloseMenu() {

    this.menuCollapsed = !this.menuCollapsed;

  }

}
