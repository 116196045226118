import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { modSharedService } from 'app/shared/services/modShared.service';
import { UntypedFormGroup, FormControl, Validators, AbstractControl, RequiredValidator } from '@angular/forms';
import { ApiResponse } from '../../../shared/models/api-response.model';
import { AccountService } from '../../../shared/services/account.service';

@Component({
  selector: 'app-collector-information',
  templateUrl: './collector-information.component.html',
  styleUrls: ['./collector-information.component.css']
})
export class CollectorInformationComponent implements OnInit {

  // Properties
  mfrmForm: UntypedFormGroup;
  mitmABCUser: any = {};

  // Input parameters
  @Input() mintABCUserIdent: number = 0;

  // Global Arrays

  constructor(public activeModal: NgbActiveModal,
    private modShared: modSharedService,
    private svcAccountService: AccountService) { }

  ngOnInit() {

    try {

      this.mfrmForm = new UntypedFormGroup({});

      this.svcAccountService.GetABCUserInformation(this.mintABCUserIdent)
        .subscribe(

          (rspResponse: ApiResponse) => {

            if (rspResponse.Succeeded) {

              // Successful.
              this.mitmABCUser = this.modShared.ExtractDataFromPayload(rspResponse, "ABCUser")[0];


            } // Succeeded

          });


    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  Cancel() {

    let objResult: any = {};

    try {

      // Don't need any logic here, either they added docs or not.
      objResult.RecordSaved = false;
      this.activeModal.close(objResult);
      this.modShared.MessagesCleared.next();

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

}
