import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { modSharedService } from 'app/shared/services/modShared.service';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl, RequiredValidator } from '@angular/forms';
import { ApiResponse } from '../../../shared/models/api-response.model';
import { AccountService } from '../../../shared/services/account.service';
import { CustomValidators } from '../../../shared/services/custom-validators';

@Component({
  selector: 'app-add-activity',
  templateUrl: './add-activity.component.html',
  styleUrls: ['./add-activity.component.css']
})
export class AddActivityComponent implements OnInit {

  // Properties
  mfrmForm: UntypedFormGroup;

  // Input parameters
  @Input() mintAccountIdent: number = 0;

  // Global Arrays
  marFollowUpSetting: any[] = []; 

  constructor(public activeModal: NgbActiveModal,
    private modShared: modSharedService,
    private valCustomValidators: CustomValidators,
    private svcAccountService: AccountService) { }

  ngOnInit() {

    try {

      this.mfrmForm = new UntypedFormGroup({
        'FollowUpSettingIdent': new UntypedFormControl(modSharedService.enmFollowUpSetting.Immediately, [this.valCustomValidators.DropdownRequired]),
        'Notes': new UntypedFormControl("", [Validators.required])
      });

      // At some point we should put these in the DB. I don't like that they're hard coded but that's how they are now.
      this.marFollowUpSetting.push({ Ident: modSharedService.enmFollowUpSetting.Immediately, Name1: "Follow-Up Immediately." });
      this.marFollowUpSetting.push({ Ident: modSharedService.enmFollowUpSetting.Current, Name1: "Allow Account to follow current schedule." });

      
    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  Cancel() {

    let objResult: any = {};

    try {

      // Don't need any logic here, either they added docs or not.
      objResult.RecordSaved = false;
      this.activeModal.close(objResult);
      this.modShared.MessagesCleared.next();

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  AddActivity() {

    let objResult: any = {};

    try {

      if (this.modShared.ValidateForm(this.mfrmForm, [])) {

        this.svcAccountService.AddActivity(this.mintAccountIdent, this.mfrmForm.controls.Notes.value, this.mfrmForm.controls.FollowUpSettingIdent.value)
          .subscribe(

            (rspResponse: ApiResponse) => {

              if (rspResponse.Succeeded) {

                // Successful.
                objResult.RecordSaved = true;
                this.activeModal.close(objResult);
                this.modShared.MessagesCleared.next();

              } // Succeeded

            });

      } 


    } catch (ex) {

      this.modShared.LogClientError(ex);

    }



  } 

}
