// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.attorney-radio-group {
  display: flex;
  flex-direction: column;
  margin: 0px 0;
  align-items: flex-start;
}

.attorney-radio-button {
  margin: 0px;
}

:host ::ng-deep .mat-radio-outer-circle {
  height: 10px;
  width: 10px;
}

:host ::ng-deep .mat-radio-container {
  height: 12px;
  width: 12px;
}

:host ::ng-deep .mat-radio-outer-circle {
  height: 12px;
  width: 12px;
}

:host ::ng-deep .mat-radio-inner-circle {
  height: 12px;
  width: 12px;
}

:host ::ng-deep .mat-radio-button .mat-radio-ripple {
  height: 24px; /*double of your required circle radius*/
  width: 24px; /*double of your required circle radius*/
  left: calc(50% - 12px); /*'10px'-same as your required circle radius*/
  top: calc(50% - 12px); /*'10px'-same as your required circle radius*/
}
`, "",{"version":3,"sources":["webpack://./src/app/claim-submission/claim-submission.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,YAAY,EAAE,wCAAwC;EACtD,WAAW,EAAE,wCAAwC;EACrD,sBAAsB,EAAE,6CAA6C;EACrE,qBAAqB,EAAE,6CAA6C;AACtE","sourcesContent":[".attorney-radio-group {\r\n  display: flex;\r\n  flex-direction: column;\r\n  margin: 0px 0;\r\n  align-items: flex-start;\r\n}\r\n\r\n.attorney-radio-button {\r\n  margin: 0px;\r\n}\r\n\r\n:host ::ng-deep .mat-radio-outer-circle {\r\n  height: 10px;\r\n  width: 10px;\r\n}\r\n\r\n:host ::ng-deep .mat-radio-container {\r\n  height: 12px;\r\n  width: 12px;\r\n}\r\n\r\n:host ::ng-deep .mat-radio-outer-circle {\r\n  height: 12px;\r\n  width: 12px;\r\n}\r\n\r\n:host ::ng-deep .mat-radio-inner-circle {\r\n  height: 12px;\r\n  width: 12px;\r\n}\r\n\r\n:host ::ng-deep .mat-radio-button .mat-radio-ripple {\r\n  height: 24px; /*double of your required circle radius*/\r\n  width: 24px; /*double of your required circle radius*/\r\n  left: calc(50% - 12px); /*'10px'-same as your required circle radius*/\r\n  top: calc(50% - 12px); /*'10px'-same as your required circle radius*/\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
