import { Component, OnInit } from '@angular/core';
import { modSharedService } from '../../shared/services/modShared.service';
import { Router } from '@angular/router';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientsService } from '../../shared/services/clients.service';
import { ChangeClientFocusComponent } from '../../dashboard/change-client-focus/change-client-focus.component';
import { ApiResponse } from '../../shared/models/api-response.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  // Subscriptions
  private OnEscalationsSet: Subscription;

  constructor(public modShared: modSharedService,
              private rtRouter: Router,
              private svcClientsService: ClientsService,
              private svcModalService: NgbModal) { }

  mstrUsername: string;
  marEscalations: any[] = []; 
  
  ngOnInit() {

    try {

        this.mstrUsername = this.modShared.mstrUsername;

        if (this.modShared.mbolRestrictAccess === 'true' && this.rtRouter.url != '/system/ChangePassword') {

            //todo update message to alert once messaging is merged.
            this.rtRouter.navigate(['/system/ChangePassword']);

        }

      this.OnEscalationsSet = this.modShared.EscalationsSet.subscribe(
        () => {

          this.marEscalations = JSON.parse(this.modShared.marEscalations); 

        });

      this.marEscalations = JSON.parse(this.modShared.marEscalations);

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  SearchByEscalation(intCustomerResponseIdent: number) {

    try {

      if (this.rtRouter.url == "/manage/Accounts") {

        this.modShared.SearchAccount.next(intCustomerResponseIdent);

      } else {

        this.rtRouter.navigate(['/manage/Accounts'], { state: { data: { EscalationCustomerResponseIdent: intCustomerResponseIdent } } });

      }


    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  } 

  ClientFocus(): string {

    try {

      return this.modShared.mstrClientFocusNumber + " - " + this.modShared.mstrClientFocusName;

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }


  } 

  HasClientFocus(): boolean {

    try {

      return (this.modShared.mintClientFocusIdent != 0);

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }


  } 


  ChangeFocus() {

    let rfModal: NgbModalRef = null;
    let itmClient: any = {};

    try {

      // Display the modal
      rfModal = this.svcModalService.open(ChangeClientFocusComponent, { backdrop: 'static', size: 'xl' });
      rfModal.result.then((objResult) => {

        if (objResult.RecordSaved) {

          this.svcClientsService.GetClientAndStructureByIdent(objResult.ClientIdent)
            .subscribe(

              (rspResponse: ApiResponse) => {

                if (rspResponse.Succeeded) {

                  // Successful.
                  itmClient = this.modShared.ExtractDataFromPayload(rspResponse, "Client")[0];

                  this.modShared.mstrClientFocusName = itmClient.PublicName1;
                  this.modShared.mstrClientFocusNumber = itmClient.ClientNumber;
                  this.modShared.mintClientFocusIdent = itmClient.Ident;

                  this.modShared.SetEscalations(this.modShared.ExtractDataFromPayload(rspResponse, "Escalation"));

                } // Succeeded

              });

          this.modShared.SetLoading(false);

        }

      }, (reason) => {

        // They dismissed without making a selection

      });


    } catch (ex) {

      this.modShared.LogClientError(ex);

    }


  } 

  NavigateToEntity(entity: string) {

    try {

      this.rtRouter.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.rtRouter.navigate(['/system/referenceTables', { entity: entity }]));  

    } catch (ex) {

      this.modShared.LogClientError(ex)

    }
  }

  onLogout() {

    try {

      this.modShared.mstrAuthToken = '';
      this.rtRouter.navigate(['/']);

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }
  } 
}
