// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-header {
  padding: 0 0.75rem;
}
.card-header h1 {
  margin: 7px 0;
}

.card-body {
  background-color: #f5f5f5;
}

.appNamelogo-height {
  height: 100px;
}

.width100 {
  width: 100%;
}

.appLogo {
  height: 40px;
  width: 130px;
  float: right;
}

.logoDiv {
  margin-top: -25px;
}

.login-footer {
  text-align: right;
  line-height: 1;
}
.login-footer p {
  margin: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/login/login.component.scss","webpack://./../../../../ABC%20Core/Client%20Portal/Website/Web/src/app/login/login.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;ACCF;ADCE;EACE,aAAA;ACCJ;;ADGA;EACE,yBAAA;ACAF;;ADGA;EACE,aAAA;ACAF;;ADGA;EACE,WAAA;ACAF;;ADGA;EACE,YAAA;EACA,YAAA;EACA,YAAA;ACAF;;ADGA;EACE,iBAAA;ACAF;;ADGA;EACE,iBAAA;EACA,cAAA;ACAF;ADEE;EACE,WAAA;ACAJ","sourcesContent":[".card-header {\r\n  padding: 0 0.75rem;\r\n\r\n  h1 {\r\n    margin: 7px 0;\r\n  }\r\n}\r\n\r\n.card-body {\r\n  background-color: #f5f5f5;\r\n}\r\n\r\n.appNamelogo-height {\r\n  height: 100px;\r\n}\r\n\r\n.width100 {\r\n  width: 100%;\r\n}\r\n\r\n.appLogo {\r\n  height: 40px;\r\n  width: 130px;\r\n  float: right;\r\n}\r\n\r\n.logoDiv {\r\n  margin-top: -25px;\r\n}\r\n\r\n.login-footer {\r\n  text-align: right;\r\n  line-height: 1;\r\n\r\n  p {\r\n    margin: 5px;\r\n  }\r\n}\r\n",".card-header {\n  padding: 0 0.75rem;\n}\n.card-header h1 {\n  margin: 7px 0;\n}\n\n.card-body {\n  background-color: #f5f5f5;\n}\n\n.appNamelogo-height {\n  height: 100px;\n}\n\n.width100 {\n  width: 100%;\n}\n\n.appLogo {\n  height: 40px;\n  width: 130px;\n  float: right;\n}\n\n.logoDiv {\n  margin-top: -25px;\n}\n\n.login-footer {\n  text-align: right;\n  line-height: 1;\n}\n.login-footer p {\n  margin: 5px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
