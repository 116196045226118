import { Component, OnInit } from '@angular/core';
import { modSharedService } from '../../shared/services/modShared.service';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';
import { ApiResponse } from '../../shared/models/api-response.model';
import { MaintenanceService } from '../../shared/services/maintenance.service';
import { CustomValidators } from '../../shared/services/custom-validators';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientsService } from 'app/shared/services/clients.service';
import { Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ABCMessage } from '../../shared/models/abc-message.model';
import { AccountService } from '../../shared/services/account.service';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { DecimalPipe } from '@angular/common';
import { CCVHelpComponent } from './ccv-help/ccvhelp.component';

@Component({
  selector: 'app-payment-portal-payment',
  templateUrl: './payment-portal-payment.component.html',
  styleUrls: ['./payment-portal-payment.component.css']
})
export class PaymentPortalPaymentComponent {

  menmScreenModes = {
    CannotProcessAtThisTime: -1,
    Documents: 1,
    Confirmation: 2,
    Payment: 3,
    Receipt: 4
  }

  // Subscriptions

  // Dropdowns

  // Data
  mfrmForm: UntypedFormGroup;
  marAccount: any[] = [];
  marDocuments: any[] = [];
  marDocumentsToPay: any[] = [];
  marAllState: any[] = [];
  marState: any[] = [];
  marYears: any[] = []; 

  // Properties
  mstrClientName1: string = "";
  mstrClientAddress1: string = "";
  mstrClientCity: string = "";
  mstrClientZip: string = "";
  mstrClientStateName1: string = "";
  ScreenMode: number = this.menmScreenModes.Documents;
  mstrABCFn: string = "";
  mstrAccountName1: string = "";
  mstrAccountNumber: string = "";
  mstrAccountAddress1: string = "";
  mstrAccountCity: string = "";
  mstrAccountStateName1: string = "";
  mstrAccountZip: string = "";
  mmnyTotalPaymentAmount: number = 0;
  mbolHasStagingPayment: boolean = false;
  mmnyMinPaymentPortalThreshold: number = 0;
  mmnyMaxPaymentPortalThreshold: number = 0;


  constructor(private modShared: modSharedService,
    private svcMaintenanceService: MaintenanceService,
    private svcModalService: NgbModal,
    private svcAccounts: AccountService, 
    private valCustomValidators: CustomValidators,
    private rtRouter: Router,
    private mobjDecimalPipe: DecimalPipe) {

    try {


    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  ngOnInit() {

    let arTablesToRetrieve: string[] = [];
    let dtStartDate: Date = new Date();
    let itmYear: any;

    try {

      this.mfrmForm = new UntypedFormGroup({
        'BillingAddress1': new UntypedFormControl("", [Validators.required]),
        'BillingCity': new UntypedFormControl("", [Validators.required]),
        'BillingCountryIdent': new UntypedFormControl(modSharedService.enmCountry.USA, []),
        'BillingStateIdent': new UntypedFormControl(0, [Validators.required, this.valCustomValidators.DropdownRequired]),
        'BillingZipCode': new UntypedFormControl("", [Validators.required]),
        'BillingFirstName': new UntypedFormControl("", [Validators.required]),
        'BillingLastName': new UntypedFormControl("", [Validators.required]),
        'CardNumber': new UntypedFormControl("", [Validators.required]),
        'ExpirationMonth': new UntypedFormControl("", [Validators.required, this.valCustomValidators.NumericGreaterThanZero]),
        'ExpirationYear': new UntypedFormControl("", [Validators.required, this.valCustomValidators.NumericGreaterThanZero]),
        'CardCCV': new UntypedFormControl("", [Validators.required, this.valCustomValidators.NumericGreaterThanZero]),
        'EmailConfirmation': new UntypedFormControl("", [Validators.required])
      });

      // Pull the Account information

      this.svcAccounts.GetClientAndDebtorInformation()
        .subscribe(

          (rspResponse: ApiResponse) => {

            if (rspResponse.Succeeded) {

              // Successful.
              this.marAccount = this.modShared.ExtractDataFromPayload(rspResponse, "AccountInformation");
              this.marDocuments = this.modShared.ExtractDataFromPayload(rspResponse, "Documents");

              this.mstrClientName1 = this.marAccount[0].ClientName1;
              this.mstrClientAddress1 = this.marAccount[0].ClientAddress1;
              this.mstrClientCity = this.marAccount[0].ClientCity;
              this.mstrClientStateName1 = this.marAccount[0].ClientStateName1;
              this.mstrClientZip = this.marAccount[0].ClientZip;

              this.mstrABCFn = this.marAccount[0].ClaimIdent;
              this.mstrAccountName1 = this.marAccount[0].AccountName1;
              this.mstrAccountNumber = this.marAccount[0].AccountNumber;
              this.mstrAccountAddress1 = this.marAccount[0].AccountAddress1;
              this.mstrAccountCity = this.marAccount[0].AccountCity;
              this.mstrAccountStateName1 = this.marAccount[0].AccountStateName1;
              this.mstrAccountZip = this.marAccount[0].AccountZip;
              this.mbolHasStagingPayment = this.marAccount[0].HasStagingPayment;

              // Update the payment fields
              this.mfrmForm.controls.BillingAddress1.patchValue(this.marAccount[0].AccountAddress1);
              this.mfrmForm.controls.BillingCity.patchValue(this.marAccount[0].AccountCity);
              this.mfrmForm.controls.BillingStateIdent.patchValue(this.marAccount[0].AccountStateIdent);
              this.mfrmForm.controls.BillingZipCode.patchValue(this.marAccount[0].AccountZip);

              // Minimum and Maximum amounts
              this.mmnyMinPaymentPortalThreshold = this.marAccount[0].MinPaymentPortalThreshold;
              this.mmnyMaxPaymentPortalThreshold = this.marAccount[0].MaxPaymentPortalThreshold;

              // Check to see if they should be allowed to process a payment
              if (this.mbolHasStagingPayment) {

                this.ScreenMode = this.menmScreenModes.CannotProcessAtThisTime;

              }

              if (this.marDocuments.length == 0) {

                this.ScreenMode = this.menmScreenModes.CannotProcessAtThisTime;
                this.modShared.AddMessage(new ABCMessage(0, "No Documents found for Claim.", modSharedService.enmABCMessageSeverity.Severe, ""));

              }



            } // Succeeded

          });

      arTablesToRetrieve.push("State");

      this.svcMaintenanceService.GetAllByList(arTablesToRetrieve)
        .subscribe(

          (rspResponse: ApiResponse) => {

            if (rspResponse.Succeeded) {

              // Successful.
              this.marAllState = this.modShared.ExtractDropdownFromPayload(rspResponse, "StateGetAll");
              this.marState = this.modShared.ExtractDropdownFromPayload(rspResponse, "StateGetAll");

              // Set up the watchers for when a country box changes
              this.mfrmForm.get('BillingCountryIdent').valueChanges.subscribe(intCountryIdent => {

                this.FilterState(intCountryIdent);  

              });


            } // Succeeded

            this.FilterState(this.mfrmForm.controls.BillingCountryIdent.value);

          });


      for (let intCounter = 0; intCounter <= 10; intCounter++) {

        itmYear = new Object();
        itmYear.Value = new Date().getFullYear() + intCounter;

        this.marYears.push(itmYear);

      }


    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  FilterState(intCountryIdent: number) {

    try {

      this.marState = this.marAllState.slice().filter(itmState => itmState.CountryIdent == intCountryIdent || itmState.Ident == 0);

      if (this.marState.find(itmState => itmState.Ident == this.mfrmForm.controls.BillingStateIdent.value) == undefined) {

        this.mfrmForm.controls.BillingStateIdent.patchValue(0);

      }

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }


  } 

  PayDocInFull(itmDocument: any) {

    try {

      itmDocument.PaymentAmount = this.FormatWithDecimal(itmDocument.Balance);

      console.log(itmDocument.PaymentAmount);

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  } 

  PayAllInvoices() {

    try {

      for (let itmDocument of this.marDocuments) {

        itmDocument.PaymentAmount = this.FormatWithDecimal(itmDocument.Balance);

      }

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }


  } 

  FormatWithDecimal(dblAmount: number): string {

    let strResult: string = "";

    try {

      dblAmount = Number(dblAmount.toString().replace(",", ""));

      if (dblAmount != 0) {

        strResult = this.mobjDecimalPipe.transform(dblAmount, '0.2-2');

      }

      strResult = strResult.toString().replace(",", ""); 


    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

    return strResult;

  }

  CheckScreenMode(intScreenMode: number): boolean {

    let bolResult: boolean = false; 

    try {

      bolResult = (this.ScreenMode == intScreenMode); 


    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

    return bolResult;

  }

  CompletePayment() {

    let elMessages: HTMLElement; 

    try {

      if (this.modShared.ValidateForm(this.mfrmForm, [])) {

        if (confirm("This will complete your payment and charge your credit card. Are you sure you want to do this? Click 'OK' to confirm.")) {

          this.svcAccounts.CompletePaymentPortalPayment(this.mmnyTotalPaymentAmount, this.mfrmForm.controls.EmailConfirmation.value, this.mfrmForm.controls.BillingFirstName.value,
            this.mfrmForm.controls.BillingLastName.value, this.mfrmForm.controls.BillingAddress1.value, this.mfrmForm.controls.BillingCity.value, this.mfrmForm.controls.BillingStateIdent.value,
            this.mfrmForm.controls.BillingZipCode.value, this.mfrmForm.controls.CardNumber.value, this.mfrmForm.controls.ExpirationMonth.value, this.mfrmForm.controls.ExpirationYear.value,
            this.mfrmForm.controls.CardCCV.value, this.marDocumentsToPay)
            .subscribe(

              (rspResponse: ApiResponse) => {

                if (rspResponse.Succeeded) {

                  // Successful.
                  this.ProgressStep(1);

                  window.sessionStorage.clear();

                } // Succeeded

              });

        }

      }

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }


  } 

  VerifyDocuments() {

    // One of the few optimistic continues, because we're looking for 
    // failure cases
    let bolContinue = true;
    let bolValidDocumentFound = false;
    let itmDocument: any = {};
    let arMessages = [];
    let msgValidation = {};
    let mnyTotalBalance = 0;
    let bolAllDocsPaidInFull: boolean = true;
    let bolExpensesPaid: boolean = false;

    try {

      this.modShared.MessagesCleared.next(); 
      this.mmnyTotalPaymentAmount = 0;
      this.marDocumentsToPay = [];

      // Loop through each of the documents, make sure: 
      //  - The values are numeric
      //  - The amounts aren't greater than the Balance
      //  - That they entered one document 
      for (var intCounter = 0; intCounter < this.marDocuments.length; intCounter++) {

        itmDocument = this.marDocuments[intCounter];

        if (itmDocument.PaymentAmount != null && itmDocument.PaymentAmount != '') {

          // Check to make sure the number is numeric 
          if (isNaN(itmDocument.PaymentAmount)) {

            // Not a number, throw an error
            this.modShared.AddMessage(new ABCMessage(0, "Document Number: " + itmDocument.DocumentNumber + " -- This value must be numeric.", modSharedService.enmABCMessageSeverity.Severe, ""));

            bolContinue = false;

          } else {

            // It's numeric, make sure it's less than the balance. 
            if (itmDocument.PaymentAmount > itmDocument.Balance
              || itmDocument.PaymentAmount <= 0) {

              // Nope, Too much money on one doc.
              this.modShared.AddMessage(new ABCMessage(0, "Document Number: " + itmDocument.DocumentNumber + " -- The amount paid must be greater than zero and less than or equal to the Balance on the document.", modSharedService.enmABCMessageSeverity.Severe, ""));

              bolContinue = false;

            } else {

              // All good, add it to the list and increment the payment amount
              this.mmnyTotalPaymentAmount = parseFloat((this.mmnyTotalPaymentAmount + parseFloat(itmDocument.PaymentAmount)).toFixed(2));
              this.marDocumentsToPay.push(itmDocument);

              bolValidDocumentFound = true;

              if (itmDocument.PaymentAmount != itmDocument.Balance
                && itmDocument.DocumentIdent != 0) {

                // They aren't paying this doc off in full, which is "fine", so long as they aren't trying to pay CE/Interest
                bolAllDocsPaidInFull = false;                

                console.log(itmDocument.PaymentAmount); 
                console.log(itmDocument.Balance); 

              }

              if (itmDocument.DocumentIdent == 0) {

                bolExpensesPaid = true;

              } 

            } // ELSE: PaymentAmount > Balance

          } // ELSE: isNAN

        } else {

          // They aren't paying this doc, mark it so we can check Interest/Expenses
          bolAllDocsPaidInFull = false;


        }// PaymentAmount != null

        mnyTotalBalance = parseFloat((mnyTotalBalance + parseFloat(itmDocument.Balance)).toFixed(2));

      } // for (...)

      if (bolExpensesPaid && !bolAllDocsPaidInFull) {

        this.modShared.AddMessage(new ABCMessage(0, "You can only pay Interest/Expenses if you are also paying all other invoices in full.", modSharedService.enmABCMessageSeverity.Severe, ""));

        bolContinue = false;

      } 

      if (!bolValidDocumentFound) {

        // No valid Document, raise an error
        this.modShared.AddMessage(new ABCMessage(0, "You must enter at least one valid payment on one of the below documents to continue.", modSharedService.enmABCMessageSeverity.Severe, ""));

        bolContinue = false;

      }

      if ((this.mmnyTotalPaymentAmount <= 100 && this.mmnyTotalPaymentAmount < this.mmnyMinPaymentPortalThreshold)
        && this.mmnyTotalPaymentAmount != mnyTotalBalance
        && this.mmnyTotalPaymentAmount != 0) {

        console.log(this.mmnyTotalPaymentAmount);

        bolContinue = false;

        this.modShared.AddMessage(new ABCMessage(0, "Invalid Payment Amount. We can only accept payments less than or equal to $100.00 if the account is being paid in full.", modSharedService.enmABCMessageSeverity.Severe, ""));

      }

      // Make sure the total payment amount isn't too high or low
      if ((this.mmnyTotalPaymentAmount < this.mmnyMinPaymentPortalThreshold ||
            this.mmnyTotalPaymentAmount > this.mmnyMaxPaymentPortalThreshold)
          && !(this.mmnyTotalPaymentAmount <= 100
              && (this.mmnyTotalPaymentAmount == mnyTotalBalance
                  || this.mmnyTotalPaymentAmount == 0))) {

        bolContinue = false;

        this.modShared.AddMessage(new ABCMessage(0, "Invalid Payment Amount. This portal can only accept partial payments between $" + this.mmnyMinPaymentPortalThreshold.toFixed(2) + " and $" + this.mmnyMaxPaymentPortalThreshold.toFixed(2) + ".", modSharedService.enmABCMessageSeverity.Severe, ""));

      }

      // Now that you're done looking, check to see if you found a valid doc
      if (bolContinue) {

        // Everything is good!
        this.ProgressStep(1);


      } 

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  ProgressStep(intStepDirection: number) {

    try {

      this.ScreenMode += intStepDirection;

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  LoadCCVHelp() {

    let rfModal: NgbModalRef = null;

    try {

      // Display the modal -- Note, omitting "Backdrop" as we want them to be able to hit Escape and/or click outside the modal
      rfModal = this.svcModalService.open(CCVHelpComponent, { size: 'md' });

      rfModal.result.then((objResult) => {

      }, (reason) => {

        // They dismissed without making a selection

      });

      setTimeout(() => {

        let modal = document.getElementsByClassName("modal");
        modal[0].classList.add("in");

      }, 100);



    } catch (ex) {

      this.modShared.LogClientError(ex);

    }


  } 

}
