import { Injectable } from "@angular/core";

import { modSharedService } from "./modShared.service";
import { ABCDictionary } from "../models/abc.dictionary.model";

@Injectable()
export class ReportingService {

  constructor(private modShared: modSharedService) { }

  GetTotalPaymentsForWeek() {

    try {

      // Get the total payments for the week
      return this.modShared.CreateHttpPostRequest('/Reporting/GetTotalPaymentsForWeek', {}, true);


    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }
   
  GenerateReport(intReportIdent: number, intReportFormatIdent: number, strFilename: string,
    strLogoPath: string, intStoredProcedureTypeIdent: number,
    intReportReportFormatXRefIdent: number, arTableNames: string[],
    slParameters: ABCDictionary[], strReportName: string = "", intPDFType: number = 0) {

    try {

      // Search the ABC User Type table
      return this.modShared.CreateHttpPostRequest('/Reporting/GenerateReport', {

        ReportIdent: intReportIdent,
        ReportFormatIdent: intReportFormatIdent,
        Filename: strFilename,
        LogoPath: strLogoPath,
        StoredProcedureTypeIdent: intStoredProcedureTypeIdent,
        ReportReportFormatXRefIdent: intReportReportFormatXRefIdent,
        TableNames: arTableNames,
        Parameters: slParameters,
        ReportName: strReportName,
        PDFType: intPDFType

      });

    } catch (ex) {

      this.modShared.LogClientError(ex);


    }

  }

  GetReportReportFormatXRefByReportIdent(intReportIdent: number) {

    try {
      
      // Search the ABC User Type table
      return this.modShared.CreateHttpPostRequest('/Reporting/GetAllActiveReportFormatByReportIdent', {

        ReportIdent: intReportIdent

      });

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }


  GetTotalActivitiesForWeek() {

    try {

      // Get the total activities for the week
      return this.modShared.CreateHttpPostRequest('/Reporting/GetTotalActivitiesForWeek', {}, true);


    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }


  GenerateRawReport(intReportFormatIdent: number, strXMLSource: string, strXSLTFilePath: string, strReportFileName: string, intPDFType: number, strLogoPath: string) {

    try {

      // Search the ABC User Type table
      return this.modShared.CreateHttpPostRequest('/Reporting/GenerateRawReport', {

        ReportFormatIdent: intReportFormatIdent,
        XMLSource: strXMLSource,
        XSLTFilePath: strXSLTFilePath,
        ReportFileName: strReportFileName,
        PDFType: intPDFType,
        LogoPath: strLogoPath

      });

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  GetAllActiveReportTypesAndReports() {

    try {

      return this.modShared.CreateHttpPostRequest('/Reporting/GetAllActiveReportTypesAndReports', {});

    }
    catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

}
