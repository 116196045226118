import { Component } from '@angular/core';

@Component({
  selector: 'payment-portal-header',
  templateUrl: './payment-portal-header.component.html',
  styleUrls: ['./payment-portal-header.component.css']
})
export class PaymentPortalHeaderComponent {

}
