import { Injectable } from "@angular/core";

import { modSharedService } from "./modShared.service";

@Injectable()
export class ReferenceTablesService {

  constructor(private modShared: modSharedService) { }

  GetAllMaintenanceTableTypes() {

    try {
      
      return this.modShared.CreateHttpPostRequest('/ReferenceTables/GetMaintenanceTableTypes', {});

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }
}
