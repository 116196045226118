import { Injectable } from '@angular/core';


@Injectable()
export class MessagingService {

  constructor() { }

   


}
