// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alert {
  padding: 0.75rem;
}
.alert > ul {
  list-style: none;
  padding: 0;
  margin: 0;
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-size: 11px;
}`, "",{"version":3,"sources":["webpack://./src/app/core/abc-message-display/abc-message-display.component.scss","webpack://./../../../../ABC%20Core/Client%20Portal/Website/Web/src/app/core/abc-message-display/abc-message-display.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;ACCF;ADCE;EACE,gBAAA;EACA,UAAA;EACA,SAAA;EACA,kDAAA;EACA,eAAA;ACCJ","sourcesContent":[".alert {\r\n  padding: .75rem;\r\n\r\n  > ul {\r\n    list-style: none;\r\n    padding: 0;\r\n    margin: 0;\r\n    font-family: Verdana, Arial, Helvetica, sans-serif;\r\n    font-size: 11px;\r\n  }\r\n}\r\n",".alert {\n  padding: 0.75rem;\n}\n.alert > ul {\n  list-style: none;\n  padding: 0;\n  margin: 0;\n  font-family: Verdana, Arial, Helvetica, sans-serif;\n  font-size: 11px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
