import { Directive, Input, ElementRef } from "@angular/core";
import { NgControl } from "@angular/forms";

@Directive({
    selector: '[limit-to]',
    host: {
      '(keypress)': '_onKeypress($event)',
      '(paste)': '_onPaste($event)',
      '(cut)': '_onCut($event)',
      '(keyup)': '_onDelete($event)'
    }
  })
  export class MaxLengthDirective {
    constructor(private el: ElementRef, private control: NgControl){ }
    @Input('limit-to') limitTo; 

    _onKeypress(e) {
        const limit = +this.limitTo == null || +this.limitTo == undefined ? -1 : +this.limitTo;

        if(limit > 0){

            let label = document.getElementById(this.el.nativeElement.id + '_maxLen');

            if (e.target.value.length === limit) {

                    label.style["display"] = "block";
                    e.preventDefault();
                    
            }else{

                label.style["display"] = "none";

            }
        }       
    }

    _onPaste(e){
        const limit = +this.limitTo == null || +this.limitTo == undefined ? -1 : +this.limitTo;

        if(limit > 0){

            let pastedEvent = e.clipboardData;
            let pastedData = pastedEvent.getData('text');
            let ctrlCurrentVal = this.el.nativeElement.value;
            let combinedLen = ctrlCurrentVal.length + pastedData.length;
            let label = document.getElementById(this.el.nativeElement.id + '_maxLen');

            if(combinedLen === limit || combinedLen > limit) {

                let lengthToGather = Math.abs(pastedData.length - (combinedLen - limit));
                if(lengthToGather == 0 && ctrlCurrentVal !== pastedData) lengthToGather = pastedData.length;
                let newValue = this.el.nativeElement.value + pastedData.substring(0, lengthToGather);
                this.el.nativeElement.value = newValue;
                label.style["display"] = "block";
                e.preventDefault();

            }else{

                label.style["display"] = "none";

            }
        }
    }

    _onDelete(e){
        if(e.key === 'Backspace' || e.key === 'Delete'){

            const limit = +this.limitTo == null || +this.limitTo == undefined ? -1 : +this.limitTo;

            if(limit > 0){

                let ctrlCurrentVal = this.el.nativeElement.value;
                let curLen = ctrlCurrentVal.length;
                let label = document.getElementById(this.el.nativeElement.id + '_maxLen');

                if(curLen <= limit) {

                    label.style["display"] = "none";

                }else{

                    label.style["display"] = "block";
                    
                }
            }            
        }
    }

    _onCut(e){
        const limit = +this.limitTo == null || +this.limitTo == undefined ? -1 : +this.limitTo;

        if(limit > 0){

            let ctrlCurrentVal = this.el.nativeElement.value;
            let curLen = ctrlCurrentVal.length;
            let label = document.getElementById(this.el.nativeElement.id + '_maxLen');

            if(curLen <= limit) {

                label.style["display"] = "none";

            }else{

                label.style["display"] = "block";
                
            }
        }     
    }
  }