import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { FormControlBase, Validator } from './form-control-base';
import { modSharedService } from '../../services/modShared.service';

@Injectable()
export class FormControlService {

  validators = {

    email: () => Validators.email,
    required: () => Validators.required,
    min: min => Validators.min(min),
    max: max => Validators.max(max),
    pattern: pattern => Validators.pattern(pattern),
    minLength: minLength => Validators.minLength(minLength),
    maxLength: maxLength => Validators.maxLength(maxLength),

  };

  constructor(public modShared: modSharedService) {}

  toFormGroup(controls: FormControlBase<any>[]) {

    const group: any = {};

    try {

      controls.forEach(control => {

        const state = {

          value: control.value || '',
          disabled: control.disabled,

        };

        let validatorOpts: ValidatorFn[] | null;

        if (control.validators) {

          validatorOpts = this.getValidators(control.validators);

        }

        group[control.key] = new UntypedFormControl(state, validatorOpts);

      });

      return new UntypedFormGroup(group);

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  getValidators(validators: Validator) {
    
    const validatorOpts = new Array<ValidatorFn>();

    try {

      Object.keys(validators).forEach(key => {

        if (validators[key]) {

          validatorOpts.push(this.validators[key](validators[key]));

        }

      });

      return validatorOpts;

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

}
