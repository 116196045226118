import { Component, OnInit, Input } from '@angular/core';
import { modSharedService } from '../../../shared/services/modShared.service';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';
import { ApiResponse } from '../../../shared/models/api-response.model';
import { CustomValidators } from '../../../shared/services/custom-validators';
import { ReportingService } from '../../../shared/services/reporting.service';
import { ABCDictionary } from '../../../shared/models/abc.dictionary.model';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { MaintenanceService } from '../../../shared/services/maintenance.service';

@Component({
  selector: 'app-daily-statistics',
  templateUrl: './daily-statistics.component.html',
  styleUrls: ['./daily-statistics.component.css']
})
export class DailyStatisticsComponent implements OnInit {

  // Properties
  mfrmForm: UntypedFormGroup;
  mintReportIdent: number = modSharedService.enmReports.DailyStatistics;
  marReportReportFormatXRef: any[] = [];

  constructor(private modShared: modSharedService,
    private svcReporting: ReportingService,
    private svcAuth: AuthenticationService,
    private svcMaintenanceService: MaintenanceService,
    private valCustomValidators: CustomValidators,
    private router: Router) { }

  ngOnInit() {

    let dtGetDate: Date = new Date();
    let dtStartDate: Date = new Date();
    let dtEndDate: Date = new Date();

    try {

      dtStartDate = new Date((dtGetDate.getMonth() + 1) + "/" + (dtGetDate.getDate() - 1) + "/" + dtGetDate.getFullYear());

      this.mfrmForm = new UntypedFormGroup({
        'ReportReportFormatXRefIdent': new UntypedFormControl(0, [this.valCustomValidators.DropdownRequired]),
        'StartDate': new UntypedFormControl(dtStartDate, [Validators.required]),
        'EndDate': new UntypedFormControl(dtEndDate, [Validators.required])
      });

      this.svcReporting.GetReportReportFormatXRefByReportIdent(this.mintReportIdent)
        .subscribe(

          (rspResponse: ApiResponse) => {

            if (rspResponse.Succeeded) {

              // Successful.
              this.marReportReportFormatXRef = this.modShared.ExtractDropdownFromPayload(rspResponse, "ReportReportFormatXRef");

            } // Succeeded

          });

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  RunReport() {

    let intReportIdent: number;
    let intReportFormatIdent: number;
    let strFilename: string;
    let strLogoPath: string;
    let intStoredProcedureTypeIdent: number;
    let intReportReportFormatXRefIdent: number;
    let arTableNames: string[];
    let slParameters: ABCDictionary[];
    let strReportName: string;

    try {

      if (this.modShared.ValidateForm(this.mfrmForm, [])) {

        intReportIdent = this.mintReportIdent;
        intReportFormatIdent = 0;
        intReportReportFormatXRefIdent = this.mfrmForm.controls.ReportReportFormatXRefIdent.value;
        strFilename = "DailyPerformance";
        strLogoPath = "template/abc_report_logo.gif";
        intStoredProcedureTypeIdent = modSharedService.enmStoredProcedureType.Single;

        arTableNames = [];
        arTableNames.push("DailyPerformance");
        arTableNames.push("Totals");

        slParameters = [];

        slParameters.push(new ABCDictionary("StartDate", this.mfrmForm.controls.StartDate.value));
        slParameters.push(new ABCDictionary("EndDate", this.mfrmForm.controls.EndDate.value));
        slParameters.push(new ABCDictionary("ClientIdent", this.modShared.mintClientFocusIdent));
        slParameters.push(new ABCDictionary("ABCUserIdent", this.svcAuth.GetUserIdent()));

        strReportName = "Daily Statistics"

        this.svcReporting.GenerateReport(intReportIdent, intReportFormatIdent, strFilename,
          strLogoPath, intStoredProcedureTypeIdent,
          intReportReportFormatXRefIdent, arTableNames,
          slParameters, strReportName)
          .subscribe(

            (rspResponse: ApiResponse) => {

              // Nothing to do here, it's a report call.

            });
      }

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  Cancel() {

    this.router.navigateByUrl('/manage/Reports');

  }

}
