// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-item:hover {
  color: #fff !important;
  text-decoration: none;
  background-color: rgb(0, 59, 92) !important;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/base-form/base-form.component.scss","webpack://./../../../../ABC%20Core/Client%20Portal/Website/Web/src/app/shared/base-form/base-form.component.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;EACA,qBAAA;EACA,2CAAA;ACCJ","sourcesContent":[".dropdown-item:hover {\r\n    color: #fff !important;\r\n    text-decoration: none;\r\n    background-color: rgb(0, 59, 92) !important;\r\n}",".dropdown-item:hover {\n  color: #fff !important;\n  text-decoration: none;\n  background-color: rgb(0, 59, 92) !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
