import { Injectable } from '@angular/core';
import { modSharedService } from "./modShared.service";
import { ABCDictionary } from "../models/abc.dictionary.model";
import { FormGroup } from '@angular/forms';

@Injectable()
export class ClientsService {

  constructor(private modShared: modSharedService) { }


  SearchClientsByName(strName: string) {

    try {

      // Call the Services API
      return this.modShared.CreateHttpPostRequest('/Clients/SearchClientsByName', {

        Name: strName

      });

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }


  GetClientAndStructureByIdent(intClientIdent: number) {

    try {

      // Call the Services API
      return this.modShared.CreateHttpPostRequest('/Clients/GetClientAndStructureByIdent', {

        ClientIdent: intClientIdent

      });

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }



  SearchClientByClientNumber(strClientNumber: string) {

    try {

      // Call the Services API
      return this.modShared.CreateHttpPostRequest('/Clients/SearchClientByClientNumber', {

        ClientNumber: strClientNumber

      });

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }




}
