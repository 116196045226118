import { throwError as observableThrowError, Observable, Subject } from 'rxjs';
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { map, catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import { UntypedFormGroup, Form } from "@angular/forms";
import 'rxjs';

import { ApiResponse } from "../models/api-response.model";
import { LocalStorage } from '../directives/local-storage.function';
import { SessionStorage } from "../directives/session-storage.function";
import { ABCMessage } from "../models/abc-message.model";
import { UntypedFormControl } from "@angular/forms";
import { saveAs } from 'file-saver';
import { ABCDictionary } from "../models/abc.dictionary.model";
import { MessagingService } from './messaging.service';
import { environment } from '../../../environments/environment';
import * as $ from "jquery";

@Injectable()
export class modSharedService {

  // Subscriptions & Events
  MessagesAdded = new Subject<ABCMessage[]>();
  MessagesCleared = new Subject<void>();
  EscalationsSet = new Subject<void>(); 
  SearchAccount = new Subject<number>();

  mbolLoading = false;

  @LocalStorage
  mstrApplicationName: string = "ABC-Collect Client Portal";

  @LocalStorage
  mstrApplicationLogo: string = "/assets/images/abc_logo.gif";

  @LocalStorage
  mstrApplicationNameLogo: string = "/assets/images/logo.gif";

  @LocalStorage
  mstrheaderBorder: string = "/assets/images/border.gif";

  @LocalStorage
  // mstrBaseAPIUrl: string = "http://clientportaldev:5002";
  //mstrBaseAPIUrl: string = "http://CoreABCCollectPortalStaging/API";
  mstrBaseAPIUrl: string = environment.apiUrl;

  @SessionStorage
  mstrAuthToken: string;

  @SessionStorage
  mstrUsername: string;

  @SessionStorage
  mbolRestrictAccess: string = 'false'; //was unable to get bools to eval after return. TODO: Make bool

  @SessionStorage
  marUserRoles: string;

  @SessionStorage
  marUserPermissions: string;

  @SessionStorage
  mstrClientFocusName: string;

  @SessionStorage
  mstrClientFocusNumber: string;

  @SessionStorage
  mintClientFocusIdent: number;

  @SessionStorage
  marEscalations: string;


  // NOTE: If you're copying regExs from ABCC/SC, you need to double slash all the slashed from the VB code
  marValidationRegEx = [
    { key: 'onlyContainLetters', expectedPattern: '^[a-zA-Z ]*$' },
    { key: 'validPhone', expectedPattern: '^((\\+\\d{1,3}(-| )?\\(?\\d\\)?(-| )?\\d{1,5})|(\\(?\\d{2,6}\\)?))(-| )?(\\d{3,4})(-| )?(\\d{4})(( x| ext| ext )\\d{1,5}){0,1}$' },
    { key: 'validTime', expectedPattern: '^([1][012]|\\d):[0-5]\\d(:\\d+)*[ ]?[aAPp][Mm]$' },
    { key: 'onlyAlphaNumeric', expectedPattern: '^[a-zA-Z0-9]+$' },
    { key: 'validZipRange', expectedPattern: '^\\d{3}-\\d{3}$' },
    { key: 'validAttorneyZip', expectedPattern: '^\\d{3}$' },
    { key: 'validEmail', expectedPattern: '^[\\w-]+(?:\\.[\\w-]+)*@(?:[\\w-]+\\.)+[a-zA-Z]{2,7}$' },
  ]


  // Validation Text
  marValidationErrors = [
    { key: 'required', regExName: '', value: 'This field is required.' },
    { key: 'email', regExName: '', value: 'This field must be a valid email address.' },
    { key: 'pattern', regExName: 'onlyContainLetters', value: 'This field must only include characters.' },
    { key: 'minlength', regExName: '', value: 'This field minimum length is not matched.' },
    { key: 'maxlength', regExName: '', value: 'This field maximum length is not matched.' },
    { key: 'pattern', regExName: 'validPhone', value: 'This field must contain a valid phone number.' },
    { key: 'DropdownRequired', regExName: '', value: 'This field is required.' },
    { key: 'CheckboxRequired', regExName: '', value: 'You must check this box to continue.' },
    { key: 'NumericOrEmpty', regExName: '', value: 'This field must be numeric.' },
    { key: 'NumericGreaterThanZero', regExName: '', value: 'This field must be numeric and greater than 0.' },
    { key: 'Numeric', regExName: '', value: 'This field must be numeric.' },
    { key: 'pattern', regExName: 'validTime', value: 'This must be in a valid format of HH:MM AM/PM' },
    { key: 'NumericOrEmptyGreaterThanOrEqualToZero', regExName: '', value: 'This field must be numeric and greater than or equal to zero.' },
    { key: 'pattern', regExName: 'onlyAlphaNumeric', value: 'This field can only contain numbers and letters.' },
    { key: 'pattern', regExName: 'validZipRange', value: 'Invalid Range. Must be in the format: ###-### or use a 3 digit zip.' },
    { key: 'pattern', regExName: 'validAttorneyZip', value: 'Must be a three digit zip.' }

  ]


  SetLoading(bolLoading: boolean) {

    try {

      setTimeout(() => {

        this.mbolLoading = bolLoading;

      });

    } catch (ex) {

      this.LogClientError(ex);

    }

  }

  constructor(private rtRouter: Router, private http: HttpClient, private svcMessagingService: MessagingService) {
  }

  isAuthenticated(): boolean {

    try {

      return this.mstrAuthToken !== "";

    } catch (ex) {

      this.LogClientError(ex);

    }

  }

  SaveSearchParameters(frmForm: UntypedFormGroup): any {

    let objSave: any = null;

    try {

      objSave = {};

      Object.keys(frmForm.controls).forEach((strName) => {

        if (strName.includes("ForSearch")) {

          objSave[strName] = frmForm.controls[strName].value;

        }

      });

    } catch (ex) {

      this.LogClientError(ex);

    }

    return objSave;

  }

  SetSearchParametersEnabled(frmForm: UntypedFormGroup, bolEnabled: Boolean) {

    try {

      Object.keys(frmForm.controls).forEach((strName) => {

        if (strName.includes("ForSearch")) {

          if (bolEnabled) {

            frmForm.controls[strName].enable();

          } else {

            frmForm.controls[strName].enable();

          }

        }

      });

    } catch (ex) {

      this.LogClientError(ex);

    }

  }


  CreateParametersForSave(frmForm: UntypedFormGroup): any {

    let objSave: any = null;

    try {

      objSave = {};

      Object.keys(frmForm.controls).forEach((strName) => {

        objSave[strName] = frmForm.controls[strName].value;

      });


    } catch (ex) {

      this.LogClientError(ex);

    }

    return objSave;

  }

  PopulateFormFromItem(frmForm: UntypedFormGroup, itmRecord: any) {

    try {

      Object.keys(frmForm.controls).forEach((strName) => {

        if (itmRecord[strName] !== undefined) {

          frmForm.controls[strName].setValue(itmRecord[strName]);

        }

      });


    } catch (ex) {

      this.LogClientError(ex);

    }

  }

  GetFormControlName(ctlControl: UntypedFormControl): string {

    let strControlName: string = ""

    try {

      Object.keys(ctlControl.parent.controls).forEach((name) => {

        if (ctlControl === ctlControl.parent.controls[name]) {

          strControlName = name;

        }

      });

    } catch (ex) {

      this.LogClientError(ex);

    }

    return strControlName;

  }

  utf8ArrayToString(aBytes) {
    var sView = "";

    for (var nPart, nLen = aBytes.length, nIdx = 0; nIdx < nLen; nIdx++) {
      nPart = aBytes[nIdx];

      sView += String.fromCharCode(
        nPart > 251 && nPart < 254 && nIdx + 5 < nLen ? /* six bytes */
          /* (nPart - 252 << 30) may be not so safe in ECMAScript! So...: */
          (nPart - 252) * 1073741824 + (aBytes[++nIdx] - 128 << 24) + (aBytes[++nIdx] - 128 << 18) + (aBytes[++nIdx] - 128 << 12) + (aBytes[++nIdx] - 128 << 6) + aBytes[++nIdx] - 128
          : nPart > 247 && nPart < 252 && nIdx + 4 < nLen ? /* five bytes */
            (nPart - 248 << 24) + (aBytes[++nIdx] - 128 << 18) + (aBytes[++nIdx] - 128 << 12) + (aBytes[++nIdx] - 128 << 6) + aBytes[++nIdx] - 128
            : nPart > 239 && nPart < 248 && nIdx + 3 < nLen ? /* four bytes */
              (nPart - 240 << 18) + (aBytes[++nIdx] - 128 << 12) + (aBytes[++nIdx] - 128 << 6) + aBytes[++nIdx] - 128
              : nPart > 223 && nPart < 240 && nIdx + 2 < nLen ? /* three bytes */
                (nPart - 224 << 12) + (aBytes[++nIdx] - 128 << 6) + aBytes[++nIdx] - 128
                : nPart > 191 && nPart < 224 && nIdx + 1 < nLen ? /* two bytes */
                  (nPart - 192 << 6) + aBytes[++nIdx] - 128
                  : /* nPart < 127 ? */ /* one byte */
                  nPart
      );
    }

    return sView;

  }

  CreateHttpPostRequest(strAPIToCall: string, objData: any, bolSkipLoading: boolean = false, bolClearMessages: boolean = true): Observable<ApiResponse> {

    try {

      let hdrHeader: HttpHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Token': this.mstrAuthToken
      });

      // Clear out any messages
      // TODO: Do we want to do this? I like having it clear automatically, but we may want more control
      // See note above on why this is a setTimeout()
      if (bolClearMessages) {

        setTimeout(() => {

          this.MessagesCleared.next();

        });

      }

      if (!bolSkipLoading) {

        //this.mbolLoading = true;
        this.SetLoading(true);

      }

      // Create the post request
      let obvObserve = this.http.post(this.mstrBaseAPIUrl + strAPIToCall, objData, { responseType: 'arraybuffer', headers: hdrHeader, withCredentials: true, observe: 'response' })
        .pipe(
          map((httpResponse: HttpResponse<any>) => {
            let strFilename: string;
            let objBlob: any;
            let intRandom: number;

            if (httpResponse.headers.get("content-type").indexOf("application/json") >= 0) {

              // This is a normal JSON object, treat it normally.
              let apiResponse: ApiResponse = JSON.parse(this.utf8ArrayToString(new Uint8Array(httpResponse.body)));

              // Check and save any messages
              if (apiResponse.Messages.length != 0 && apiResponse.Messages[0].PublicDescription != "No Results") {

                // See note above on why this is a setTimeout()
                setTimeout(() => {

                  this.MessagesAdded.next(apiResponse.Messages);

                });

              }

              // Save your Auth Token
              if (apiResponse.Token !== '') {

                // Server sent back a token, save it in shared.
                this.mstrAuthToken = apiResponse.Token;

              }

              if (this.ExtractDataFromPayload(apiResponse, "LoadReportViewer")) {

                intRandom = Math.random() * 1000000;

                window.open("/#/reporting/ReportViewer?Ref=" + intRandom.toString(), "Report", "scrollbars=1,menubar=0,status=0,titlebar=0,toolbar=0");

                localStorage.setItem("ReportData", this.ExtractDataFromPayload(apiResponse, "ReportData").toString());
                localStorage.setItem("ReportRawXML", this.ExtractDataFromPayload(apiResponse, "ReportRawXML").toString());
                localStorage.setItem("ExcelXSLTFile", this.ExtractDataFromPayload(apiResponse, "ExcelXSLTFile").toString());
                localStorage.setItem("PDFXSLTFile", this.ExtractDataFromPayload(apiResponse, "PDFXSLTFile").toString());
                localStorage.setItem("ReportFileName", this.ExtractDataFromPayload(apiResponse, "ReportFileName").toString());
                localStorage.setItem("LogoPath", this.ExtractDataFromPayload(apiResponse, "LogoPath").toString());

              }

              //this.mbolLoading = false;
              this.SetLoading(false);

              return apiResponse;

            } else {

              // This is a blob type
              objBlob = new Blob([httpResponse.body], { type: httpResponse.headers.get("content-type") });

              if (httpResponse.headers.get("Content-Disposition").indexOf("filename=") >= 0) {

                strFilename = httpResponse.headers.get("Content-Disposition").substr(httpResponse.headers.get("Content-Disposition").indexOf("filename=") + 9);

              } else {

                strFilename = "NoFile";

              }

              // this.mbolLoading = false;
              if (!bolSkipLoading) {

                this.SetLoading(false);

              }

              //TODO: Get this working again
              saveAs(objBlob, strFilename);

            }

          }),
          catchError(this.HandleHTTPError)
        )

      return obvObserve;

    }
    catch (ex) {

      this.LogClientError(ex);

    }
  }

  CreateHttpGetRequest(strAPIToCall: string): Observable<ApiResponse> {

    try {

      let hdrHeader: HttpHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Token': this.mstrAuthToken
      });

      // Clear out any messages
      // TODO: Do we want to do this? I like having it clear automatically, but we may want more control
      // See note above on why this is a setTimeout()
      setTimeout(() => {

        this.MessagesCleared.next();

      });

      // Create the post request
      return this.http.get(this.mstrBaseAPIUrl + strAPIToCall, { headers: hdrHeader, withCredentials: true, observe: 'response' })
        .pipe(
          map((httpResponse: HttpResponse<ApiResponse>) => {
            let apiResponse: ApiResponse = httpResponse.body;

            // Check and save any messages
            if (apiResponse.Messages.length != 0) {

              // See note above on why this is a setTimeout()
              setTimeout(() => {

                this.MessagesAdded.next(apiResponse.Messages);

              });

            }

            // Save your Auth Token
            if (apiResponse.Token !== '') {

              // Server sent back a token, save it in shared.
              this.mstrAuthToken = apiResponse.Token;

            }

            return apiResponse;
          }),
          catchError(this.HandleHTTPError)
        );

    } catch (ex) {

      this.LogClientError(ex);

    }

  }

  CreateHttpPutRequest(strAPIToCall: string, objData: any): Observable<ApiResponse> {

    try {

      let hdrHeader: HttpHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Token': this.mstrAuthToken
      });

      // Clear out any messages
      // TODO: Do we want to do this? I like having it clear automatically, but we may want more control
      // See note above on why this is a setTimeout()
      setTimeout(() => {

        this.MessagesCleared.next();

      });

      // Create the post request
      return this.http.put(this.mstrBaseAPIUrl + strAPIToCall, objData, { headers: hdrHeader, withCredentials: true, observe: 'response' })
        .pipe(
          map((httpResponse: HttpResponse<ApiResponse>) => {
            let apiResponse: ApiResponse = httpResponse.body;

            // Check and save any messages
            if (apiResponse.Messages.length != 0) {

              setTimeout(() => {

                this.MessagesAdded.next(apiResponse.Messages);

              });

            }

            // Save your Auth Token
            if (apiResponse.Token !== '') {

              // Server sent back a token, save it in shared.
              this.mstrAuthToken = apiResponse.Token;

            }

            return apiResponse;
          }),
          catchError(this.HandleHTTPError)
        );

    } catch (ex) {

      this.LogClientError(ex);

    }

  }

  CreateHttpDeleteRequest(strAPIToCall: string): Observable<ApiResponse> {

    try {

      let hdrHeader: HttpHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Token': this.mstrAuthToken
      });

      // Clear out any messages
      // TODO: Do we want to do this? I like having it clear automatically, but we may want more control
      // See note above on why this is a setTimeout()
      setTimeout(() => {

        this.MessagesCleared.next();

      });

      // Create the post request
      return this.http.delete(this.mstrBaseAPIUrl + strAPIToCall, { headers: hdrHeader, withCredentials: true, observe: 'response' })
        .pipe(
          map((httpResponse: HttpResponse<ApiResponse>) => {
            let apiResponse: ApiResponse = httpResponse.body;

            // Check and save any messages
            if (apiResponse.Messages.length != 0) {

              // See note above on why this is a setTimeout()
              setTimeout(() => {

                this.MessagesAdded.next(apiResponse.Messages);

              });

            }

            // Save your Auth Token
            if (apiResponse.Token !== '') {

              // Server sent back a token, save it in shared.
              this.mstrAuthToken = apiResponse.Token;

            }

            return apiResponse;

          }),
          catchError(this.HandleHTTPError)
        );
    }

    catch (ex) {

      this.LogClientError(ex);

    }

  }

  HandleHTTPError = (errError: any) => {

    let apiResponse: ApiResponse;

    try {

      if (errError.status === 401
        || errError.status === 403) {

        if (document.documentURI.toString().indexOf("/PaymentPortal") > 0) {

          this.mstrAuthToken = '';
          this.rtRouter.navigate(['/PaymentPortal/']);

        } else {

          // Their token is no longer valid, clear it out (if it exists) and redirect
          this.mstrAuthToken = '';
          this.rtRouter.navigate(['/']);

        }

      }

      if (errError.headers.get("content-type") !== null
        && errError.headers.get("content-type").indexOf("application/json") >= 0) {

        //Grab the response from the server.  
        //apiResponse = errError.json();
        apiResponse = JSON.parse(this.utf8ArrayToString(new Uint8Array(errError.error)));

        // Check and save any messages
        if (apiResponse.Messages.length != 0 && apiResponse.Messages[0].PublicDescription != "No Results") {

          setTimeout(() => {

            this.MessagesAdded.next(apiResponse.Messages);

          });

        }

      } else {

        if (errError.headers.get("content-type") == null) {

          // Still want to log this as a general http error
          this.LogClientError(errError.status.toString() + " - " + errError.statusText);

        }

      }

      // this.mbolLoading = false;
      this.SetLoading(false);

      console.log('Internal! ==> ' + errError.status);

      if (errError['_body'] != undefined) {

        let errMsg = JSON.parse(errError['_body']);

        if (errMsg && errMsg.Messages) {
          this.showErrorMessages(errMsg.Messages)
        }

      } 

    } catch (ex) {

      this.LogClientError(ex);

    } finally {

      return observableThrowError(errError);

    }

  }

  LogClientError(objError: any) {

    let msgMessage: ABCMessage;

    try {

      console.log(objError);

      //TODO: Log this error to the database
      //Call messaging service, pass in exception object
      //this.LogFrontendExceptionToDatabase(objError)
      //  .subscribe(

      //    (rspResponse: ApiResponse) => {

      //      if (rspResponse.Succeeded) {

      //        // For now, just add a generic critical message
      //        msgMessage = new ABCMessage(1, 'Uncaptured Client: ' + objError, 1, '1001');

      //        this.AddMessage(msgMessage);

      //      }

      //    });



    } catch (ex) {

      // Not really anything you can do here? 

    }

  }

  GetRegExPatternByName(strRegExName: string) {

    let objRegEx: any = null;
    let strRegExPattern: string = "";

    try {

      objRegEx = this.marValidationRegEx.find(itmRegEx => itmRegEx.key == strRegExName)

      if (objRegEx != null) {

        strRegExPattern = objRegEx.expectedPattern;

      }

      return strRegExPattern;


    } catch (ex) {

      this.LogClientError(ex);

    }

  }


  GetRegExNameByPattern(strRegExPattern: string) {

    let objRegEx: any = null;
    let strRegExName: string = "";

    try {

      objRegEx = this.marValidationRegEx.find(itmRegEx => itmRegEx.expectedPattern == strRegExPattern)

      if (objRegEx != null) {

        strRegExName = objRegEx.key;

      }

      return strRegExName;


    } catch (ex) {

      this.LogClientError(ex);

    }

  }

  GetErrorMessage(strErrorKey: string, strExpectedPattern: string) {

    let strRegExPatternName: string = "";

    try {

      strRegExPatternName = this.GetRegExNameByPattern(strExpectedPattern);

      return this.marValidationErrors.find(itmValidation => itmValidation.key == strErrorKey
        && itmValidation.regExName == strRegExPatternName).value;


    } catch (ex) {

      this.LogClientError(ex);

    }

  }

  ExtractDataFromPayload(apiResponse: ApiResponse, strDataTable: string): any[] {

    let arResults: any[];
    let objPayload: any;


    try {

      if (apiResponse.Payload !== null) {

        objPayload = apiResponse.Payload.find(x => x.ItemName == strDataTable);

        if (objPayload != undefined) {

          arResults = objPayload.Data;

        }

      }

      return arResults;

    } catch (ex) {

      this.LogClientError(ex);

    }

  }

  ExtractDropdownFromPayload(rspResponse: ApiResponse, strDataTable: string): any[] {

    let arResults: any[];

    try {

      //TODO: I feel like this is something that should be handled in the core.

      // Pull it from the payload
      arResults = this.ExtractDataFromPayload(rspResponse, strDataTable);

      // Check if a zero record exists
      if (arResults.find(itmRecord => itmRecord.Ident == 0) == null) {

        // Nope, add it
        arResults.push({ Ident: 0, Name1: "(Please Select)", FormattedName1: "(Please Select)", Abbreviation: "" });

      }

      // Sort by Name1
      arResults.sort((a, b) => a.Name1 > b.Name1 ? 1 : -1);


    } catch (ex) {

      this.LogClientError(ex);

    }

    return arResults;

  }

  CheckUserInPermission(intABCPermissionIdent): boolean {

    let bolPassed: boolean = false;
    let arPermissions: any[] = [];
    let itmPermission: any;

    try {

      arPermissions = JSON.parse(this.marUserPermissions);

      itmPermission = arPermissions.find(x => x.Ident == intABCPermissionIdent);

      if (itmPermission != undefined) {

        bolPassed = true;

      }

    } catch (ex) {

      this.LogClientError(ex);

    }

    return bolPassed;
  }


  ExtractValueFromPayload(rspResponse: ApiResponse, strVariableName: string): any {

    let objResults: any;
    let valResult: any = undefined;

    try {

      objResults = rspResponse.Payload.find(x => x.ItemName == strVariableName);

      if (objResults !== undefined) {

        valResult = objResults.Data;

      }


    } catch (ex) {

      this.LogClientError(ex);

    }

    return valResult;

  }

  GetABCMessageSeverityPriority(intABCMessageSeverityIdent: number): number {

    let intPriority: number;

    try {

      switch (intABCMessageSeverityIdent) {

        case modSharedService.enmABCMessageSeverity.Severe: {

          intPriority = 7;
          break;

        }

        case modSharedService.enmABCMessageSeverity.SaveFailedMessages: {

          intPriority = 6;
          break;

        }

        case modSharedService.enmABCMessageSeverity.FailedValidation: {

          intPriority = 5;
          break;

        }


        case modSharedService.enmABCMessageSeverity.Moderate: {

          intPriority = 4;
          break;

        }

        case modSharedService.enmABCMessageSeverity.Low: {

          intPriority = 3;
          break;

        }

        case modSharedService.enmABCMessageSeverity.Information: {

          intPriority = 2;
          break;

        }

        case modSharedService.enmABCMessageSeverity.SaveSuccess: {

          intPriority = 1;
          break;

        }

        default: {

          intPriority = 0;

        }

      }

      return intPriority;

    } catch (ex) {

      this.LogClientError(ex);

    }

  }

  public AddMessage(msgMessage: ABCMessage) {

    let elMessages: HTMLElement; 

    try {

      setTimeout(() => {

        this.MessagesAdded.next([msgMessage]);

      });

      setTimeout(() => {

        elMessages = document.getElementById("spnMessageDispay");

        if (elMessages != null) {

          elMessages.scrollIntoView();

        } else {

          console.log("Null Message Control!");

        }

      });

    } catch (ex) {

      this.LogClientError(ex);

    }

  }

  ConvertColumnName(strColumnName: string): string {

    let strResult: string = "";

    try {

      // Convert camel case to Sentance Case
      strResult = strColumnName.replace(/([A-Z])/g, " $1");

      // Remove out numbers (Name1, etc)
      strResult = strResult.replace(/([0-9])/g, "");


    } catch (ex) {

      this.LogClientError(ex);

    }

    return strResult;

  }

  showErrorMessages(messages: any[]) {

    try {

      this.MessagesCleared.next();

      messages.forEach(x => {
        this.AddMessage(x);
      });

    } catch (ex) {

      this.LogClientError(ex);

    }

  }

  ValidateForm(frmForm: UntypedFormGroup, dataColumns, bolClearMessages: boolean = true) {

    let ctlControl: UntypedFormControl;
    let strControlLabel: string = "";
    let strExpectedPattern: string = "";
    let strCurrentControl: string = "";

    try {

      // Because things are happening multi-threaded you could be changing the .Selected value (which drives the checkmark) before the screen has a chance
      // to update. This causes angular to raise a "hey, you might be breaking something" error and stops the update. The SetTimeout forces this to happen on
      // the next available update. It's not pretty but it's necessary and should only be used in VERY extreme cases.
      if (bolClearMessages) {

        setTimeout(() => {

          this.MessagesCleared.next();

        });

      }

      let messageLabel = '';

      Object.keys(frmForm.controls).forEach(kyKey => {

        ctlControl = <UntypedFormControl>(frmForm.get(kyKey));

        if (ctlControl.enabled && !ctlControl.valid) {

          // Need to assign this to a variable because you lose scope when you go into your second
          // nested anonymous function.
          strCurrentControl = kyKey;

          Object.entries(ctlControl.errors).forEach(([strKey, objVal]) => {

            if (document.getElementsByName("lbl" + strCurrentControl).length !== 0) {

              strControlLabel = document.getElementsByName("lbl" + strCurrentControl)[0].innerText;

              // Style the label
              document.getElementsByName("lbl" + strCurrentControl)[0].parentElement.classList.add("error");
              document.getElementsByName("lbl" + strCurrentControl)[0].classList.add("error");

            } else {

              if (dataColumns && dataColumns.length) {

                let control = dataColumns.filter(x => x.key == kyKey);

                if (control && control.length) {

                  strControlLabel = control[0].label;

                } else {

                  strControlLabel = kyKey;

                }

              } else {

                strControlLabel = kyKey;

              }

            }

            if (objVal.requiredPattern != null) {

              strExpectedPattern = objVal.requiredPattern;

            } else {

              strExpectedPattern = "";

            }

            this.AddMessage(new ABCMessage(0, strControlLabel + ': ' + this.GetErrorMessage(strKey, strExpectedPattern), 1, ''));

          });

        } else {

          strCurrentControl = kyKey;

          if (document.getElementsByName("lbl" + strCurrentControl).length !== 0) {

            document.getElementsByName("lbl" + strCurrentControl)[0].classList.remove("error");
            document.getElementsByName("lbl" + strCurrentControl)[0].parentElement.classList.remove("error");

          }

        }


      });

      return frmForm.valid;

    } catch (ex) {

      this.LogClientError(ex);

    }

  } // ValidateForm

  UpdateDictionaryValue(slParameters: ABCDictionary[], strKey: string, objValue: any) {

    let adItem: ABCDictionary;

    try {

      adItem = slParameters.find(x => x.Key == strKey);

      if (adItem !== null) {

        // Found the item, update the value
        adItem.Value = objValue;

      }

    } catch (ex) {

      this.LogClientError(ex);

    }

  }

  GetDictionaryValue(slParameters: ABCDictionary[], strKey: string): any {

    let adItem: ABCDictionary;

    try {

      adItem = slParameters.find(x => x.Key == strKey);

      if (adItem !== null) {

        // Found the item, update the value
        return adItem.Value;

      } else {

        return null;

      }

    } catch (ex) {

      this.LogClientError(ex);

    }

  }

  ConvertDateStringToTime(datDate: Date): string {

    let strResult: string = "";
    let intHours: number = 0;

    try {

      intHours = datDate.getHours();

      if (intHours > 12) {

        strResult = (intHours - 12).toString() + ":" + datDate.getMinutes().toString().padStart(2, "0") + " PM";

      } else {

        strResult = intHours.toString() + ":" + datDate.getMinutes().toString().padStart(2, "0") + " AM";

      }

    } catch (ex) {

      this.LogClientError(ex);

    }

    return strResult;

  }

  GetMessageByNumber(strNumber: string, arReplacements: ABCDictionary[]) {

    try {

      // Search the ABC User Type table
      return this.CreateHttpPostRequest('/Messaging/GetMessageByNumber', {
        MessageNumber: strNumber,
        ReplacementText: arReplacements
      });

    } catch (ex) {

      this.LogClientError(ex);

    }

  }

  LogFrontendExceptionToDatabase(ObjError: any) {

    try {

      //let Info: Array<string>
      let arErrorInfo: string[];

      arErrorInfo = [ObjError.message.toString(), ObjError.stack.toString()]

      //Defaulting this to "" lets it through to the API, possible json issue?
      //strError = "Testing";

      return this.CreateHttpPostRequest('/Messaging/LogFrontendException', {
        ErrorInfo: arErrorInfo
      });

    } catch (ex) {

      // I don't think there's anything we can do here, I think this causes infinite loops.
      //this.LogClientError(ex);

    }

  }

  SetEscalations(arEscalation: any[]) {

    try {

      this.marEscalations = JSON.stringify(arEscalation);
      this.EscalationsSet.next();

    } catch (ex) {

      this.LogClientError(ex);

    }
  
  } 

  SetModalDraggable() {

    try {

      $(document).ready(function () {

        let modalContent: any = $('.modal-content');
        let modalHeader = $('.modal-header');

        modalHeader.addClass('cursor-all-scroll');

        modalContent.draggable({
          handle: '.modal-header'
        });
      });


    } catch (ex) {

      this.LogClientError(ex);

    }

  } 
  SearchAccounts(intEscalationCustomerResponse: number) {

    try {

      this.SearchAccount.next(intEscalationCustomerResponse);

    } catch (ex) {

      this.LogClientError(ex);

    }

  }


}

export namespace modSharedService {

  export const gstrMinDate: string = "1900-01-01T00:00:00";
  export const gstrMaxDate: string = "2079-06-06T00:00:00";
  export const gintMinBucketStartDay = -9999;
  export const gintMaxBucketStartDay = 9999;
  export const gstrClaimSubmissionEmailAddress = "webclaims@abc-amega.com";

  export enum enmDisbursementType {
    Cash = 2,
    ACH = 3,
    Wire = 4,
    Net = 5
  }

  export enum enmStagingInvoiceStatus {
    Active = 1,
    Cancelled = 2
  }

  export enum enmPaymentStatus {
    Unposted = 1,
    Posted = 2,
    Cancelled = 3,
    Confirmed = 4
  }

  export enum enmPaymentPlanScheduleType {

    Weekly = 1,
    BiWeekly = 2,
    Monthly = 3,
    Quarterly = 4

  }

  export enum enmPaymentPlanType {

    FullPayment = 1,
    SettlementPlan = 2

  }

  export enum enmPaymentSource {

    Attorney = 1,
    Client = 2,
    Debtor = 3

  }

  export enum enmInvoiceStatus {
    Unposted = 1,
    Posted = 2,
    Confirmed = 3,
    Hold = 4,
    Cancelled = 5,
  }

  export enum enmStagingDisbursementStatus {
    Active = 1,
    Hold = 2,
    ManualHold = 3,
    Posted = 4,
    Confirmed = 5,
    Cancelled = 6,
    Net = 7
  }

  export enum enmStagingManualEntryBatchStatus {
    Unposted = 1,
    Posted = 2,
    Cancelled = 3
  }

  export enum enmStagingInvoicePaymentStatus {
    Unposted = 1,
    Posted = 2,
    Confirmed = 3
  }

  export enum enmStagingManualEntryStatus {
    Active = 1,
    Hold = 2,
    ManualHold = 3,
    Posted = 4,
    Confirmed = 5,
    Cancelled = 6
  }

  // Enumerations
  export enum enmABCMessageSeverity {
    Severe = 1,
    Moderate = 2,
    Low = 3,
    Information = 4,
    SaveFailedMessages = 5,
    SaveSuccess = 6,
    FailedValidation = 9
  }

  export enum enmCollectionType {

    OnAccount = 2,
    PaidInFull = 3,
    MerchandiseReturn = 4,
    FindersFee = 5,
    SettledinFull = 6

  }

  export enum enmStagingDisbursementBatchStatus {
    Unposted = 1,
    Posted = 2,
    Cancelled = 3
  }

  export enum enmCountry {

    PleaseSelect = 0,
    USA = 1,
    Canada = 2,
    Unknown = 7,
    PuertoRico = 201

  }

  export enum enmClientReceivable {

    USD = 12,
    CAD = 13

  }

  export enum enmCurrencyType {

    USDollars = 15,
    CanadianDollars = 1

  }

  export enum enmCashAccounts {

    RegularTrustAccountingUSD = 1,
    CanadaCanadianDollar = 2,
    CanadaUSDollar = 3,
    NorthCarolina = 4,
    Washington = 5,
    Yen = 6,
    PoundsSterling = 7,
    Euro = 8,
    OperatingCash = 47,
    ShanghaiCash = 48

  }

  export enum enmAccountingForwardingStatus {
    InProgress = 1,
    Completed = 2,
    RemovedUncollectable = 3,
    RemovedError = 4
  }

  export enum enmAccountingEntryTypeSource {
    Attorney = 1,
    Vendor = 2
  }

  export enum enmBookAccount {
    RegCash = 1,
    NorthCarolina = 4,
    Washington = 5,
    PennsylvaniaSalesTas = 9,
    TexasSalesTax = 10,
    CanadianCash = 2
  }

  export enum enmBookAccountType {
    Cash = 1,
    SalesTax = 2,
    DueClient = 3,
    ClientReceivable = 4,
    AdvanceCosts = 5,
    AdvanceSuitFees = 6,
    AttorneyReserve = 7,
    AttorneyCosts = 8,
    AttorneySuitFee = 9,
    AttorneyHandlingFee = 10,
    Costs = 11,
    CommissionsPersonal = 12,
    CommissionsAttorney = 13,
    AttorneyFeeCharges = 14,
    BankChargesInHouse = 15,
    SHAccountIncomeCosts = 16,
    BankChagesAttorney = 17,
    MiscNotUsed = 18
  }

  export enum enmStagingInvoiceBatchStatus {
    Unposted = 1,
    Posted = 2,
  }

  export enum enmStagingInvoicePaymentBatchType {
    Attorney = 1,
    Client = 2,
  }

  export enum enmStoredProcedureType {

    Multiple = 1,
    Single = 2,
    ControlLoad = 3,
    ControlSearch = 4

  }

  export enum enmDisbursementBatchRemittanceStatus {

    Posted = 1,
    Printed = 2,
    Voided = 3

  }

  export enum enmLockableResource {
    Account = 1,
    Correspondence = 2,
    Client = 3,
    ABCUser = 4,
    Queue = 5,
    ClientTruee = 6,
    ClientRate = 7,
    StagingDisbursementBatch = 8,
    DisbursementBatch = 9,
    CheckControl = 10,
    StagingDisbursement = 11,
    StagingInvoiceBatch = 12,
    InvoiceBatch = 13,
    StagingInvoice = 14,
    StagingPaymentBatch = 15,
    PaymentBatch = 16,
    StagingManualEntryBatch = 17,
    StagingInvoicePaymentBatch = 18,
    LawList = 19,
    UDF = 20,
    Attorney = 21,
    AttorneyRate = 22,
    Escalation = 23,
    Import = 24
  }

  export enum enmQueueType {
    Escalation = 1,
    Correspondence = 2,
    Account = 3
  }

  export enum enmQueueRuleType {
    Build = 1,
    Filter = 2,
    Assignment = 3
  }

  export enum enmInvoiceType {

    Single = 1,
    Summary = 2

  }

  export enum enmClientInvoicingType {

    Single = 2,
    Summary = 3

  }

  export enum enmClientInvoiceScheduleType {

    Monthly = 2,
    BiMonthly = 3,
    Weekly = 4,
    EndOfMonth = 5

  }

  export enum enmABCPermission {

    ManageUsersAdd = 10001,
    ManageUsersEdit = 10002,
    ManageUsersView = 10003,
    ManageClientsAddRoot = 10004,
    ManageClientsAddSub = 10005,
    ManageClientsEdit = 10006,
    ManageClientsView = 10007,
    ManageReportsAdmin = 10008,
    ManageAccountsEmailAttachments = 10009,
    AccountsAdd = 10010,
    AccountsEdit = 10011,
    AccountsView = 10012,
    AccountsEditStatus = 10013,
    AccountsEditStructure = 10014,
    AccountsEditQueues = 10015,
    AccountsSendEmail = 10016,
    AccountsManageAccount = 10017,
    AccountsEditUDF = 10018,
    AccountsEditDocument = 10019,
    AccountsEditEscalation = 10020,
    AccountsReopenDoc = 10021,
    ManageQueues = 10022,
    ForceLogout = 10023,
    ManageMaintenanceView = 10024,
    ManageMaintenanceEdit = 10025,
    ManageDunning = 10026,
    ManageImport = 10027,
    ManageUDFView = 10029,
    ManageUDFEdit = 10030,
    CorrespondenceWork = 10031,
    CorrespondenceView = 10032,
    ManageLawListView = 10033,
    ManageLawListEdit = 10034,
    ManageStationsView = 10035,
    ManageStationsEdit = 10036,
    ManageAttorneyView = 10037,
    ManageAttorneyEdit = 10038,
    ManageAttorneyGlobalRates = 10039,
    AccountsEditClaim = 10040,
    ManageClientsEditBankInfo = 10041,
    AccountingMovePayment = 10044,
    AccountingBackoutPayment = 10045,
    ClaimEditClaimStatus = 10046,
    AccountingDisbursementSearch = 10047,
    AccountingInvoicePayments = 10048,
    AccountingPostedDisbursementBatches = 10049,
    AccountingPostedInvoices = 10050,
    AccountingPostedPaymentBatches = 10051,
    AccountingUnpostedDisbursementBatches = 10052,
    AccountingUnpostedInvoices = 10053,
    AccountingUnpostedManualBatches = 10054,
    AccountingUnpostedPaymentBatches = 10055,
    AttorneyViewAttorneyInformation = 10056,
    AttorneyAddEditAttorney = 10057,
    ManageClientsAddEditGlobalRates = 10058,
    ClaimForwardClaim = 10059,
    ClaimForwardCosts = 10060,
    ClaimPayAVendor = 10061,
    ClaimApplyPayDirects = 10062,
    ClaimApplyPayments = 10063,
    AccountsAddClaim = 10064,
    ClaimAdjustBalance = 10065,
    ManageReports = 10066,
    ManageVendorsEdit = 10067,
    ManageVendorsView = 10068,
    ManageAccounting = 10069,
    ManageQueuesEdit = 10070,
    ClaimsEditExpenses = 10071,
    ClaimsEditRates = 10072,
    ClaimsForwardClaimByEmail = 10073,
    AccountsUpdateCustomerResponses = 10074,
    ClaimWorkClaim = 10075,
    AccountsAddNewAccount = 10076,
    ClaimRemoveForwarding = 10077,
    UnpostedPaymentBatchesViewOnly = 10079,
    DebtorEditDebtorNumber = 10080,
    ClaimEditStation = 10081,
    ClaimEditProceedDate = 10082,
    AccountsWorkStructuredAccounts = 10083,
    ManageAutomatedReports = 10084,
    ManualPaymentPortalPayment = 10085,
    ForwardBeyondThreshold = 10086,
    ClientEditSalesRepFields = 10087,
    ClaimSubmissionFromPortal = 10088,
    ClickABCStaffAccess = 10043,
    ClickABCClientAccess = 10042
  }

  export enum enmClaimStatus {
    Starting = 0,
    Open = 1,
    Closed = 2,
    AttorneyAmicable = 3,
    AttorneySuit = 4,
    Judgement = 5,
    FFD = 6
  }

  export enum enmQueuePred {

    None = 0,
    Account_Build_AgeOfInvoice = 1,
    Account_Build_AccountBalance = 2,
    Account_Build_Client = 3,
    Account_Build_DueDate = 4,
    Account_Build_State = 5,
    Account_Build_Country = 6,
    Account_Build_AccountName = 16,
    Account_Build_PlaceDate = 17,
    Account_Build_BalanceinQueue = 18,
    Account_Build_AccountNameContains = 19,
    Account_Build_DocumentTerms = 20,
    Account_Build_ClientCountry = 29,
    Account_Build_OriginalPlacementAmount = 30,
    Account_Build_AgeAtPlacement = 32,
    Correspondence_Build_AccountQueue = 7,
    Correspondence_Build_CorrespondenceMedium = 10,
    Correspondence_Build_Client = 33,
    Escalation_Build_AccountQueue = 8,
    Escalation_Build_Response = 9,
    Account_Filter_FollowupDate = 11,
    Account_Filter_DueDate = 12,
    Account_Filter_BusinessHours = 13,
    Account_Filter_AccountBalance = 14,
    Account_Filter_ReommendRoll = 15,
    Account_Filter_AgeofInvoice = 21,
    Account_Filter_Escalation = 22,
    Account_Filter_PastDueBalance = 23,
    Account_Build_CreditRating = 24,
    Account_Assignment_ClaimType = 25,
    Account_Assignment_ClaimPlacementAmount = 26,
    Account_Assignment_AccountCountry = 27,
    Account_Assignment_ForeignAccounts = 28,
    Account_Assignment_Client = 31

  }

  export enum enmEquipmentFeeValueType {

    PercentageOfValue = 1,
    DollarAmount = 2,
    FlatPercentage = 3

  }

  export enum enmFindersFeeValueType {

    PercentageOfFee = 1,
    DollarAmount = 2,
    FlatPercentage = 3

  }

  export enum enmClientRateType {

    Amicable = 2,
    Suit = 3,
    InHouse = 4

  }

  export enum enmAttorneyRateType {

    Amicable = 1,
    Suit = 2

  }

  export enum enmAttorneyExperienceType {

    SpecialInstructions = 1,
    Experience = 2

  }

  export enum enmAttorneyForwardingRateType {

    Attorney = 1,
    SplitRate = 2,
    HourlyRate = 3

  }

  export enum enmAttorneyRateItemType {

    AgeAtPlacement = 1,
    DollarsCollected = 2

  }

  export enum enmClientRateItemType {

    AgeAtPlacement = 2,
    DollarsPlaced = 3,
    DollarsCollected = 4,
    ProceedToCollected = 5

  }

  export enum enmAttorneyRateValueType {

    Percentage = 1,
    DollarAmount = 2

  }

  export enum enmClientRateValueType {

    Percentage = 2,
    DollarAmount = 3

  }

  export enum enmRateCapType {

    HardCap = 1,
    PercentageOfPlacement = 2

  }

  export enum enmStagingInvoicePaymentBatchStatus {

    Unposted = 1,
    Posted = 2

  }

  export enum enmImportType {

    ClientAccountFeed = 1,
    ClientExtranetActivities = 2,
    ClientRollToCollections = 3,
    OCAReturnFromCollections = 4,
    RenumberAccounts = 5,
    AccountAssignment = 6,
    OpenAccountFeed = 7,
    CloseAccountFeed = 8,
    ClientAccountDeltaFeed = 9,
    ClientResponseFeed = 10,
    StationAssignment = 11,
    PayDirectImport = 12,
    ClientAccountActivity = 13

  } 

  export enum enmReports {

    CustomSelection = 1,
    ClaimDetail = 2,
    DailyStatistics = 3,
    MonthlyPerformance = 4,
    ClientSummaryMonthly = 5,
    ClientSummaryPlacement = 6

  }

  export enum enmReportFormat {

    Screen = 1,
    Excel = 2,
    PDF = 3,
    PDF_Compressed = 4,
    Excel_Compressed = 5,
    Screen_Compressed = 6,
    WordDocument = 7

  }

  export enum enmAccountQueueType {

    Automatic = 1,
    Dunning = 2,
    Manual = 3

  }

  export enum enmDocumentStatus {
    Open = 1,
    Closed = 2
  }

  export enum enmAccountStatus {
    Active = 1,
    Closed = 2,
    OCAPlacement = 3,
    HoldInAbeyance = 4,
    Blocked = 5,
    ProjectClosed = 6
  }

  export enum enmAccountContactStatusType {
    Disabled = 1,
    Enabled = 2,
    PhoneDisc = 7
  }

  export enum enmAccountContactSourceType {
    Internal = 4,
    Client = 5
  }


  export enum enmDocumentType {
    Invoice = 1,
    CreditMemo = 2,
    DebitMemo = 3,
    Payment = 4,
    UnappliedCredit = 8,
    Contract = 9,
    NegativeABCAdj = 12,
    NegativeCurrencyAdj = 14
  }

  export enum enmCustomerResponseType {

    PaymentRelated = 1,
    Escalation = 2,
    Documentation = 3,
    InProgress = 4,
    Miscellaneous = 5,
    ABCManagementReview = 10,
    Judgement = 12

  }

  export enum enmUDFTableType {
    Account = 2,
    Document = 3,
    DocumentDetail = 4
  }

  export enum enmClaimSubmissionType {
    New = 1
  }

  export enum enmClaimSubmissionFormType {
    Domestic = 1,
    International = 2
  }

  export enum enmCustomerResponse {
    ToBeActivated = 277
  }

  export enum enmCurrencyType {
    USD = 15,
    CAD = 1
  }

  export enum enmEscalationStatus {
    Open = 1,
    Closed = 2
  }

  export enum enmImportStatus {

    InStaging = 5,
    TestsPassed = 6,
    TestsFailed = 7,
    CommittedToProduction = 8,
    OnHold = 9,
    Reject = 10,
    CommitFailed = 11,
    ReportFailed = 12,
    ReportSucceeded = 13,
    Scheduled = 14,
    Running = 15,
    RunPending = 16

  }

  export enum enmFollowUpSetting {

    Current = 1,
    Immediately = 2

  }

}
