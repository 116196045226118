// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/deep/.mat-tab-label, /deep/.mat-tab-label-active {
  padding: 6px !important;
  margin: 1px !important;
}

/deep/.mat-tab-body-wrapper {
  padding: 8px !important;
  margin: 2px !important;
}


::ng-deep .mat-tab-body {
  padding-top: 10px;
  padding-bottom: 10px;
}

.small-mat-icon {
  font-size: 12px;
  height: auto;
  width: auto;
}

::ng-deep .mat-tab-label .TabError {
  color: red;
  font-weight: bold;
}

.AccountFieldset {
    height:350px;
} 
`, "",{"version":3,"sources":["webpack://./src/app/manage/accounts/accounts.component.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,sBAAsB;AACxB;;AAEA;EACE,uBAAuB;EACvB,sBAAsB;AACxB;;;AAGA;EACE,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,UAAU;EACV,iBAAiB;AACnB;;AAEA;IACI,YAAY;AAChB","sourcesContent":["/deep/.mat-tab-label, /deep/.mat-tab-label-active {\r\n  padding: 6px !important;\r\n  margin: 1px !important;\r\n}\r\n\r\n/deep/.mat-tab-body-wrapper {\r\n  padding: 8px !important;\r\n  margin: 2px !important;\r\n}\r\n\r\n\r\n::ng-deep .mat-tab-body {\r\n  padding-top: 10px;\r\n  padding-bottom: 10px;\r\n}\r\n\r\n.small-mat-icon {\r\n  font-size: 12px;\r\n  height: auto;\r\n  width: auto;\r\n}\r\n\r\n::ng-deep .mat-tab-label .TabError {\r\n  color: red;\r\n  font-weight: bold;\r\n}\r\n\r\n.AccountFieldset {\r\n    height:350px;\r\n} \r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
