import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormControlBase } from '../form-controls/form-control-base';
import { modSharedService } from '../../services/modShared.service';
import { ABCMessage } from '../../models/abc-message.model';
import { InjectSetupWrapper } from '../../../../../node_modules/@angular/core/testing';

@Component({
  selector: 'app-control',
  templateUrl: './dynamic-form-control.component.html',
  styles: [
    `.full-width {
        width: 50%
      }
      .side-margin {
        margin: 0 10px;
      }
      .padding {
        padding-bottom: 1em;
      }
      .style {
        width: 200px;
        display: block;
        height: 25px;
        color: #555;
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 0px;
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
      }
      .upload {
        border: 1px solid #ccc;
        display: inline-block;
        padding: 6px 12px;
        cursor: pointer;
        margin-bottom: 15px;
        float: left;
      }
      .hiddenText{
      display : none
      }
      .control-row {
        margin-bottom: 20px;
      }
      `,
  ],
})
  export class DynamicFormControlComponent {
  @Input() control: FormControlBase<any>;
  @Input() form: UntypedFormGroup;
  @Input() tab: any;

  @Output() controlChanged: EventEmitter<any> = new EventEmitter();

  constructor(public modShared: modSharedService) {}

  public onControlChange(value, control) {
    if (control.hasOwnProperty("onChange")) {
      this.controlChanged.emit({
        control: control,
        value: value
      });
    }
  }
}
