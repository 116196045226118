import { Router, ActivatedRoute, Params } from '@angular/router';
import { Component, OnInit, Input, Output, EventEmitter, ViewContainerRef } from '@angular/core';
import { modSharedService } from '../../shared/services/modShared.service';

@Component({
  selector: 'dynamic',
  templateUrl: './dynamic.component.html',
  styleUrls: ['./dynamic.component.scss']
})

export class DynamicComponent implements OnInit {

  constructor(private vcRef: ViewContainerRef, public modShared: modSharedService, private activatedRoute: ActivatedRoute, private router: Router) {}
    entity: string;
    parent: string;
    ident: number;
    pIdent: string;

  ngOnInit() {

     try {

       this.activatedRoute.params.subscribe((params: Params) => {

          this.entity = params['entity'] || null;
          this.parent = params['parent'] || null;
          this.ident = params['ident'] || null;
          this.pIdent = params['pIdent'] || null;

       });

     } catch (ex) {

       this.modShared.LogClientError(ex);

     }

  }

}
