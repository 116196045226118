import { Component, OnInit, Input, Output } from '@angular/core';
import { modSharedService } from '../../shared/services/modShared.service';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl, RequiredValidator } from '@angular/forms';
import { MaintenanceService } from '../../shared/services/maintenance.service';
import { ApiResponse } from '../../shared/models/api-response.model';
import { ABCDictionary } from '../../shared/models/abc.dictionary.model';

//for account structure
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';

@Component({
  selector: 'app-searchable-lookup',
  templateUrl: './searchable-lookup.component.html',
  styleUrls: ['./searchable-lookup.component.scss']
})
export class SearchableLookupComponent implements OnInit {

  @Input() mfrmScreenLocked: UntypedFormControl;
  @Input() mfrmFormControl: UntypedFormControl;
  @Input() mstrTableName: string = "";
  @Input() mstrOverrideProcedure: string = "";
  @Input() mintMaxRecords: number = 50;
  @Input() marAdditionalParameters: UntypedFormControl[] = [];
  @Input() mstrDisplayColumn: string = "Name1";
  
  marResults: any[] = [];
  marColumns: any[] = [];
  mbolDisabled: boolean = false;

  mfrmSearch: UntypedFormGroup;

  constructor(private modShared: modSharedService,
              private svcMaintenance: MaintenanceService) { }

  ngOnInit() {

    try {

      this.mfrmSearch = new UntypedFormGroup({

        'Name1': new UntypedFormControl("", [])
        
      });

      if (this.mfrmScreenLocked !== undefined) {

        this.mbolDisabled = this.mfrmScreenLocked.value;

        this.mfrmScreenLocked.valueChanges.subscribe(bolLocked => {

          this.LockScreen(bolLocked);

        });
        
        // Check to see if this already has a record loaded, if so use the table name to pull the Name1
        if (this.mfrmFormControl !== undefined
          && this.mfrmFormControl !== null) {

          if (this.mfrmFormControl.value != 0) {

            this.svcMaintenance.GetSearchableLookupValueByTableIdent(this.mfrmFormControl.value, this.mstrTableName, this.mstrDisplayColumn)
              .subscribe(

                (rspResponse: ApiResponse) => {

                  if (rspResponse.Succeeded) {

                    // Successful.
                    this.mfrmSearch.controls.Name1.patchValue(this.modShared.ExtractDataFromPayload(rspResponse, "Lookup")[0][this.mstrDisplayColumn]);

                  } // Succeeded

                });

          }

        } 

        this.LockScreen(this.mbolDisabled);

      }


    } catch (ex) {

      this.modShared.LogClientError(ex);

    } 
    
  }

  LockScreen(bolLocked: boolean) {

    try {

      this.mbolDisabled = bolLocked;

      for (let strControl in this.mfrmSearch.controls) {

        if (bolLocked) {

          this.mfrmSearch.get(strControl).disable();

        } else {

          this.mfrmSearch.get(strControl).enable();

        }

      }

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }


  } 


  SelectRecord(itmItem: any, intIndex: number) {

    try {

      this.marColumns = [];
      this.marResults = [];
      
      if (itmItem[this.mstrDisplayColumn] !== undefined) {

        this.mfrmSearch.controls.Name1.patchValue(itmItem[this.mstrDisplayColumn]);

      } else {

        // Some older code just returns "Name"
        this.mfrmSearch.controls.Name1.patchValue(itmItem.Name);

      }

      this.mfrmFormControl.patchValue(itmItem.Ident);


    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }


  Search() {

    let intParentRecordIdent: number = 0;
    let slAdditionalParameters: ABCDictionary[] = []; 
    let strControlName: string = "";

    try {

      this.modShared.MessagesCleared.next(); 

      // Loop through and add any additional parameters
      for (let ctlControl of this.marAdditionalParameters) {

        strControlName = this.modShared.GetFormControlName(ctlControl);

        slAdditionalParameters.push(new ABCDictionary(strControlName, ctlControl.value));

      }

      this.svcMaintenance.SearchableLookupByName1(this.mfrmSearch.controls.Name1.value,
                                                    this.mstrTableName, this.mstrOverrideProcedure, slAdditionalParameters,
                                                    this.mintMaxRecords)
        .subscribe(
          
          (rspResponse: ApiResponse) => {

            let objColumn: any;

            if (rspResponse.Succeeded) {

              // Successful.
              this.marResults = this.modShared.ExtractDataFromPayload(rspResponse, "Results");
              this.marColumns = [];

              if (this.marResults.length !== 0) {

                // Pull out the columns to create the base object.
                Object.entries(this.marResults[0]).forEach(([strKey, objVal]) => {

                  if (strKey.indexOf("Ident") == -1) {

                    objColumn = new Object;
                    objColumn.ColumnID = strKey;
                    objColumn.ColumnName = this.modShared.ConvertColumnName(strKey);

                    this.marColumns.push(objColumn);

                  }


                });

              }

                            
            } // Succeeded

          });


    } catch (ex) {

      this.modShared.LogClientError(ex);

    }


  }

}
