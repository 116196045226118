import { Injectable } from "@angular/core";

import { modSharedService } from "./modShared.service";
import { ABCDictionary } from "../models/abc.dictionary.model";

@Injectable()
export class MaintenanceService {

    private editIdent: any = undefined;
    private selectedStatesForNewAttorney: any = undefined;

    constructor(private modShared: modSharedService) { }

    GetEditIdent() {
        return this.editIdent;
    }

    SetEditIdent(value: any) {
        this.editIdent = value;
    }

    Search(entityName: string, entityObject: any) {

        try {

            return this.modShared.CreateHttpPostRequest('/Maintenance/Search/' + entityName, entityObject);

        } catch (ex) {

            this.modShared.LogClientError(ex);

        }

    }

  GetSelectedStatesForNewAttorney() {
    return this.selectedStatesForNewAttorney;
  }

  SetSelectedStatesForNewAttorney(value: any) {
    this.selectedStatesForNewAttorney = value;
  }

  GetSearchableLookupValueByTableIdent(intRecordIdent: number, strTableName: string, strColumnName: string) {

    try {

      // Pull back the searchable lookup
      return this.modShared.CreateHttpPostRequest('/Maintenance/GetSearchableLookupValueByTableIdent', {

        RecordIdent: intRecordIdent,
        TableName: strTableName,
        ColumnName: strColumnName

      });


    } catch (ex) {

      this.modShared.LogClientError(ex);

    }


  } 

  SearchableLookupByName1(strName1: string,
    strTableName: string,
    strOverrideProcedure: string,
    slAdditionalParameters: ABCDictionary[],
    intMaxRecords: number = 100) {

        try {

            // Search the ABC User Type table
            return this.modShared.CreateHttpPostRequest('/Maintenance/GetSearchableLookupByName1', {

                Name1: strName1,
                TableName: strTableName,
                OverrideProcedure: strOverrideProcedure,
                MaxRecords: intMaxRecords,
                AdditionalParameters: slAdditionalParameters

            });

        } catch (ex) {

            this.modShared.LogClientError(ex);

        }

    }

    Get(entityName: string, entityId: number) {

        try {

            return this.modShared.CreateHttpGetRequest('/Maintenance/' + entityName + '/' + entityId);

        } catch (ex) {

            this.modShared.LogClientError(ex);

        }

    }


  GetAllActiveCountry() {

    try {

      // Search the ABC User Type table
      return this.modShared.CreateHttpPostRequest('/Maintenance/GetAllActiveCountry', {

      });

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }


  GetAllByList(arTablesToRetrieve: string[], bolSkipLoading: boolean = false) {

    try {

      // Search the ABC User Type table
      return this.modShared.CreateHttpPostRequest('/Maintenance/GetAllByList', {

        TablesToRetrieve: arTablesToRetrieve

      }, bolSkipLoading, false);

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

    GetAll(entityName: string) {

        try {

            return this.modShared.CreateHttpGetRequest('/Maintenance/' + entityName);

        } catch (ex) {

            this.modShared.LogClientError(ex);

        }

    }

    Add(entityName: string, entityObject: any) {

        try {

            return this.modShared.CreateHttpPostRequest('/Maintenance/' + entityName, entityObject);

        } catch (ex) {

            this.modShared.LogClientError(ex);

        }

    }

    Update(entityName: string, entityObject: any) {

        try {

            return this.modShared.CreateHttpPutRequest('/Maintenance/' + entityName, entityObject);

        } catch (ex) {

            this.modShared.LogClientError(ex);

        }

    }

    Upsert(entityName: string, entityObject: any) {

        try {

            return this.modShared.CreateHttpPostRequest('/Maintenance/Upsert/' + entityName, entityObject);

        } catch (ex) {

            this.modShared.LogClientError(ex);

        }

    }

    Delete(entityName: string, entityId: number) {

        try {

            return this.modShared.CreateHttpDeleteRequest('/Maintenance/' + entityName + '/' + entityId);

        } catch (ex) {

            this.modShared.LogClientError(ex);

        }

    }

    SearchABCUserType(strName1: string, bolActiveOnly: boolean) {

        try {

            // Search the ABC User Type table
            return this.modShared.CreateHttpPostRequest('/Maintenance/SearchABCUserTypeByDetails', {

                Name1: strName1,
                ActiveOnly: bolActiveOnly

            });

        } catch (ex) {

            this.modShared.LogClientError(ex);

        }

    }

    GetABCUserTypeByIdent(intIdent: number) {

        try {

            // Search the ABC User Type table
            return this.modShared.CreateHttpPostRequest('/Maintenance/GetABCUserTypeByIdent', {
                Ident: intIdent
            });


        } catch (ex) {

            this.modShared.LogClientError(ex);

        }

    }

    SaveABCUserType(intIdent: number, strName: string, strDesc1: string, bolActive: boolean) {

        try {

            // Search the ABC User Type table
            return this.modShared.CreateHttpPostRequest('/Maintenance/SaveABCUserType', {
                Ident: intIdent,
                Name1: strName,
                Desc1: strDesc1,
                Active: bolActive
            });


        } catch (ex) {

            this.modShared.LogClientError(ex);

        }

    }

    UpdateStagingInvoicesAndDisbursement(intAttorneyIdent: number) {

        try {

            return this.modShared.CreateHttpPutRequest('/Maintenance/' + intAttorneyIdent, {});

        } catch (ex) {

            this.modShared.LogClientError(ex);

        }

    }

    UnassignClaims(intStationIdent: number) {

        try {

            return this.modShared.CreateHttpPostRequest('/Maintenance/UnassignClaims/' + intStationIdent, {});

        } catch (ex) {

            this.modShared.LogClientError(ex);

        }

    }

    GetABCUsersForStation() {

        try {

            return this.modShared.CreateHttpGetRequest('/Maintenance/GetABCUsersForStation');

        } catch (ex) {

            this.modShared.LogClientError(ex);

        }

    }

    UpdateStationHistory(intStationIdent: number) {

        try {

            return this.modShared.CreateHttpPutRequest('/Maintenance/' + intStationIdent, {});

        } catch (ex) {

            this.modShared.LogClientError(ex);

        }

    }

    UpdateRankByVendorContactIdent(intVendorContactIdent: number, intSortOrder: number, intUserIdent: number) {

        try {

            return this.modShared.CreateHttpPostRequest('/Maintenance/' + intVendorContactIdent + '/' + intSortOrder + '/' + intUserIdent, {});

        } catch (ex) {

            this.modShared.LogClientError(ex);

        }

    }

    SearchManageUserDefinedFields(intUDFTableType: number, bolActiveOnly: boolean) {

        try {

            // Search the ABC User Type table
            return this.modShared.CreateHttpPostRequest('/Maintenance/SearchManageUserDefinedFields', {

                UDFTableType: intUDFTableType,
                ActiveOnly: bolActiveOnly

            });

        } catch (ex) {

            this.modShared.LogClientError(ex);

        }

    }

  EditUDF(intIdent: number, bolLockRecord: boolean) {

        try {

            // Search the ABC User Type table
            return this.modShared.CreateHttpPostRequest('/Maintenance/EditUDF', {
              Ident: intIdent,
              LockRecord: bolLockRecord
            });


        } catch (ex) {

            this.modShared.LogClientError(ex);

        }

  }

  SaveUDF(intIdent: number, intUDFTableTypeIdent: number, strFieldLabel: string, bolActive: boolean) {

        try {

            // Search the ABC User Type table
            return this.modShared.CreateHttpPostRequest('/Maintenance/SaveUDF', {
                Ident: intIdent,
                UDFTableTypeIdent: intUDFTableTypeIdent,
                FieldLabel: strFieldLabel,
                Active: bolActive
            });


        } catch (ex) {

            this.modShared.LogClientError(ex);

        }

  }

  ChangeUDFSortOrder(intUDFIdent: number, intNewRank: number) {

    try {

      // Search the ABC User Type table
      return this.modShared.CreateHttpPostRequest('/Maintenance/ChangeUDFSortOrder', {

        UDFIdent: intUDFIdent,
        NewRank: intNewRank

      });

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  UnlockUDF(intUDFIdent: number) {

    try {

      return this.modShared.CreateHttpPostRequest('/Maintenance/UnlockUDF', {

        UDFIdent: intUDFIdent

      });

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

}
