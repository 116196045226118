import {Injectable} from '@angular/core';

@Injectable()
export class GlobalVars {
  public pageSize: number;
  public siteURL: string;
  public draggable: boolean;

  constructor() {
    this.pageSize = 10;
    this.siteURL = "http://localhost:4200/#/system/referenceTables;entity=";
    this.draggable = false;
  }
}
