import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

// Custom Directives
import { DropdownDirective } from "./directives/dropdown.directive";
import { MaxLengthDirective } from "./directives/maxlength.directive";
import { PanelMenuModule } from 'primeng/panelmenu';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from "@swimlane/ngx-datatable";

@NgModule({

  declarations: [
    DropdownDirective,
    MaxLengthDirective
  ],
  exports: [
    DropdownDirective,
    MaxLengthDirective,
    CommonModule,
    PanelMenuModule
  ],
  imports: [    
    NgbModalModule,
    NgxDatatableModule
  ]
})
export class SharedModule {
  
}
