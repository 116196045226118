// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.full-width {
        width: 50%
      }
      .side-margin {
        margin: 0 10px;
      }
      .padding {
        padding-bottom: 1em;
      }
      .style {
        width: 200px;
        display: block;
        height: 25px;
        color: #555;
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 0px;
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
      }
      .upload {
        border: 1px solid #ccc;
        display: inline-block;
        padding: 6px 12px;
        cursor: pointer;
        margin-bottom: 15px;
        float: left;
      }
      .hiddenText{
      display : none
      }
      .control-row {
        margin-bottom: 20px;
      }
      `, "",{"version":3,"sources":["webpack://./src/app/shared/dynamic-controls/dynamic-form-control/dynamic-form-control.component.ts"],"names":[],"mappings":"AAAA;QACQ;MACF;MACA;QACE,cAAc;MAChB;MACA;QACE,mBAAmB;MACrB;MACA;QACE,YAAY;QACZ,cAAc;QACd,YAAY;QACZ,WAAW;QACX,sBAAsB;QACtB,sBAAsB;QACtB,kBAAkB;QAClB,4CAA4C;QAC5C,qEAAyG;MAC3G;MACA;QACE,sBAAsB;QACtB,qBAAqB;QACrB,iBAAiB;QACjB,eAAe;QACf,mBAAmB;QACnB,WAAW;MACb;MACA;MACA;MACA;MACA;QACE,mBAAmB;MACrB","sourcesContent":[".full-width {\n        width: 50%\n      }\n      .side-margin {\n        margin: 0 10px;\n      }\n      .padding {\n        padding-bottom: 1em;\n      }\n      .style {\n        width: 200px;\n        display: block;\n        height: 25px;\n        color: #555;\n        background-color: #fff;\n        border: 1px solid #ccc;\n        border-radius: 0px;\n        box-shadow: inset 0 1px 1px rgba(0,0,0,.075);\n        transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;\n      }\n      .upload {\n        border: 1px solid #ccc;\n        display: inline-block;\n        padding: 6px 12px;\n        cursor: pointer;\n        margin-bottom: 15px;\n        float: left;\n      }\n      .hiddenText{\n      display : none\n      }\n      .control-row {\n        margin-bottom: 20px;\n      }\n      "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
