// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
    .tab-pane {
      padding-top: 20px;
    }
    .tab-container {
      width: 100%;
    }
    .control-container {
      width: 100%;
    }
    `, "",{"version":3,"sources":["webpack://./src/app/core/dynamic-form-components/form-builder/form-builder.component.ts"],"names":[],"mappings":";IACI;MACE,iBAAiB;IACnB;IACA;MACE,WAAW;IACb;IACA;MACE,WAAW;IACb","sourcesContent":["\n    .tab-pane {\n      padding-top: 20px;\n    }\n    .tab-container {\n      width: 100%;\n    }\n    .control-container {\n      width: 100%;\n    }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
