// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ref-table-heading {
  background: #69b3e7;
  color: black;
  /* margin: 6px 0 0px; */
  font-size: 20px;
}

.ref-table-navbar {
  background: #69b3e7;
  color: #fff;
  min-height: 40px;
  height: 40px;
  margin-bottom: 0px;
}

::ng-deep .navbar {
  padding: 0rem 0.5rem;
}

.client-tree-invisible {
  display: none;
}

.client-tree ul,
.client-tree li {
  margin-top: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.client-split {
  height: 600px;
  white-space: nowrap;
}

code {
  color: #bb7a00;
}

.vl {
  border-left: 1px solid grey;
  width: 2px;
}

.selectedClient {
  background-color: #dc6a3f;
  font-weight: bold;
}

::ng-deep .mat-tab-body {
  padding-top: 10px;
  padding-bottom: 10px;
}

.small-mat-icon {
  font-size: 12px;
  height: auto;
  width: auto;
}

.Tree-Button {
  font-size: 12px;
  border: 5px;
}

::ng-deep .mat-tab-label .TabError {
  color: red;
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/app/dashboard/change-client-focus/change-client-focus.component.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,YAAY;EACZ,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE,mBAAmB;EACnB,WAAW;EACX,gBAAgB;EAChB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,aAAa;AACf;;AAEA;;EAEE,aAAa;EACb,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,2BAA2B;EAC3B,UAAU;AACZ;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,eAAe;EACf,WAAW;AACb;;AAEA;EACE,UAAU;EACV,iBAAiB;AACnB","sourcesContent":[".ref-table-heading {\r\n  background: #69b3e7;\r\n  color: black;\r\n  /* margin: 6px 0 0px; */\r\n  font-size: 20px;\r\n}\r\n\r\n.ref-table-navbar {\r\n  background: #69b3e7;\r\n  color: #fff;\r\n  min-height: 40px;\r\n  height: 40px;\r\n  margin-bottom: 0px;\r\n}\r\n\r\n::ng-deep .navbar {\r\n  padding: 0rem 0.5rem;\r\n}\r\n\r\n.client-tree-invisible {\r\n  display: none;\r\n}\r\n\r\n.client-tree ul,\r\n.client-tree li {\r\n  margin-top: 0;\r\n  margin-bottom: 0;\r\n  list-style-type: none;\r\n}\r\n\r\n.client-split {\r\n  height: 600px;\r\n  white-space: nowrap;\r\n}\r\n\r\ncode {\r\n  color: #bb7a00;\r\n}\r\n\r\n.vl {\r\n  border-left: 1px solid grey;\r\n  width: 2px;\r\n}\r\n\r\n.selectedClient {\r\n  background-color: #dc6a3f;\r\n  font-weight: bold;\r\n}\r\n\r\n::ng-deep .mat-tab-body {\r\n  padding-top: 10px;\r\n  padding-bottom: 10px;\r\n}\r\n\r\n.small-mat-icon {\r\n  font-size: 12px;\r\n  height: auto;\r\n  width: auto;\r\n}\r\n\r\n.Tree-Button {\r\n  font-size: 12px;\r\n  border: 5px;\r\n}\r\n\r\n::ng-deep .mat-tab-label .TabError {\r\n  color: red;\r\n  font-weight: bold;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
