import { Component, OnInit } from '@angular/core';
import { modSharedService } from '../../shared/services/modShared.service';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';
import { ApiResponse } from '../../shared/models/api-response.model';
import { MaintenanceService } from '../../shared/services/maintenance.service';
import { CustomValidators } from '../../shared/services/custom-validators';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientsService } from 'app/shared/services/clients.service';
import { Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { RecordLockingService } from '../../shared/services/record-locking.service';
import { Validator } from '../../shared/dynamic-controls/form-controls/form-control-base';
import { ABCMessage } from '../../shared/models/abc-message.model';
import { AccountService } from '../../shared/services/account.service';
import { ABCDictionary } from '../../shared/models/abc.dictionary.model';
import { ReportingService } from '../../shared/services/reporting.service';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { DatePipe } from '@angular/common';
import { AttorneyInformationComponent } from './attorney-information/attorney-information.component';
import { CollectorInformationComponent } from './collector-information/collector-information.component';
import { AddActivityComponent } from './add-activity/add-activity.component';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.css']
})
export class AccountsComponent implements OnInit {

  // Subscriptions
  private OnAccountSearchForEscalation: Subscription;

  marSearchResults: any[] = [];

  menmScreenModes = {
    Search: 0,
    Edit: 1
  }

  // Dropdowns
  marCustomerResponseForSearch: any[] = [];
  marEscalationCustomerResponseForSearch: any[] = [];

  // Data
  marDocuments: any[] = []; 
  marActivity: any[] = [];

  // Properties
  ScreenMode: number = this.menmScreenModes.Search;
  mfrmForm: UntypedFormGroup;
  mintRecordIdent: number = 0;
  mintClaimIdent: number = 0; 
  mitmAccount: any = {}; 
  mitmPrimaryContact: any = {};
  mintAttorneyIdent: number = 0;
  mstrAttorneyName: string = ""; 
  mstrAttorneyCaseNumber: string = ""; 
  mstrCurrency: string = "";
  mstrCollectorName: string = "";
  mintCollectorABCUserIdent: number = 0;
  mstrCollectorPhone: string = ""; 

  mbolFireSearch: boolean = false;
  mintEscalationCustomerResponseIdent: number = 0; 

  constructor(private modShared: modSharedService,
    private svcAccountService: AccountService,
    private svcMaintenanceService: MaintenanceService,
    private valCustomValidators: CustomValidators,
    private svcLockingService: RecordLockingService,
    private svcModalService: NgbModal,
    private svcReporting: ReportingService,
    private svcDatePipe: DatePipe,
    private svcAuth: AuthenticationService,
    private rtRouter: Router) {

    try {

      if (this.rtRouter.getCurrentNavigation() != undefined
        && this.rtRouter.getCurrentNavigation().extras != undefined
        && this.rtRouter.getCurrentNavigation().extras.state != undefined
        && this.rtRouter.getCurrentNavigation().extras.state.data.EscalationCustomerResponseIdent != undefined) {

        this.mbolFireSearch = true;
        this.mintEscalationCustomerResponseIdent = this.rtRouter.getCurrentNavigation().extras.state.data.EscalationCustomerResponseIdent;

      } else {

        console.log("No State!");

      }


    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  ngOnInit() {

    let arTablesToRetrieve: string[] = [];
    let dtStartDate: Date = new Date();

    try {

      this.mfrmForm = new UntypedFormGroup({
        'DocumentNumberForSearch': new UntypedFormControl("", []),
        'AccountNumberForSearch': new UntypedFormControl("", []),
        'CustomerNameForSearch': new UntypedFormControl("", []),
        'ClaimNumberForSearch': new UntypedFormControl("", []), // TODO: Remove, testing only
        'CustomerResponseIdentForSearch': new UntypedFormControl(0, []),
        'EscalationCustomerResponseIdentForSearch': new UntypedFormControl(0, []),
        'IncludeWorkedEscalation': new UntypedFormControl(true, [])
      });


      this.svcAccountService.GetScreenInformationForAccountSearch(this.modShared.mintClientFocusIdent)
        .subscribe(

          (rspResponse: ApiResponse) => {

            if (rspResponse.Succeeded) {

              // Successful.
              this.marEscalationCustomerResponseForSearch = this.modShared.ExtractDropdownFromPayload(rspResponse, "EscalationCustomerResponse");
              this.marCustomerResponseForSearch = this.modShared.ExtractDropdownFromPayload(rspResponse, "CustomerResponse");

            } // Succeeded

          });

      if (this.mbolFireSearch) {

        this.mfrmForm.controls.EscalationCustomerResponseIdentForSearch.patchValue(this.mintEscalationCustomerResponseIdent);
        this.Search(); 

      } 

      this.OnAccountSearchForEscalation = this.modShared.SearchAccount.subscribe(
        (intEscalationCustomerResponseIdent) => {

          this.mfrmForm.controls.EscalationCustomerResponseIdentForSearch.patchValue(intEscalationCustomerResponseIdent);
          this.Search(); 

        });


    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  EditEnabled(): boolean {

    try {

      return this.ScreenMode === this.menmScreenModes.Edit;

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  } // EditEnabled()

  Search() {

    try {

      console.log(this.modShared.mintClientFocusIdent); 

      this.svcAccountService.SearchAccounts(this.modShared.mintClientFocusIdent, this.mfrmForm.controls.DocumentNumberForSearch.value, this.mfrmForm.controls.AccountNumberForSearch.value,
                                            this.mfrmForm.controls.CustomerNameForSearch.value, this.mfrmForm.controls.ClaimNumberForSearch.value,
                                            this.mfrmForm.controls.EscalationCustomerResponseIdentForSearch.value, this.mfrmForm.controls.CustomerResponseIdentForSearch.value,
                                            true)
        .subscribe(

          (rspResponse: ApiResponse) => {

            if (rspResponse.Succeeded) {

              // Successful.
              this.marSearchResults = this.modShared.ExtractDataFromPayload(rspResponse, "Account");

            } // Succeeded

          });


    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  Cancel() {

    let bolContinue: boolean = false;

    try {

      this.ScreenMode = this.menmScreenModes.Search;
      this.ClearControls();

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  FormatAddress(itmItem: any) {

    try {

      itmItem.DisplayAddress = itmItem.Address1;

      if (itmItem.Address2.toString().trim() != "") {

        if (itmItem.DisplayAddress.toString().trim() != "") {

          itmItem.DisplayAddress += "<br />";

        } 

        itmItem.DisplayAddress += itmItem.Address2;

      }

      if (itmItem.Address3.toString().trim() != "") {


        if (itmItem.DisplayAddress.toString().trim() != "") {

          itmItem.DisplayAddress += "<br />";

        } 

        itmItem.DisplayAddress += itmItem.Address3;

      }

      if (itmItem.DisplayAddress.toString().trim() != "") {

        itmItem.DisplayAddress += "<br />";

      } 

      itmItem.DisplayAddress += itmItem.City;

      if (itmItem.StateName != "(Please Select)") {

        if (itmItem.DisplayAddress.toString().trim() != "") {

          itmItem.DisplayAddress += ", ";

        } 

        itmItem.DisplayAddress += itmItem.StateName;

      }

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  } 

  GetAccountDetails() {

    let itmAttorney: any = {};
    let itmCollector: any = {};

    try {

      this.svcAccountService.GetAccountDetails(this.mintRecordIdent, this.mintClaimIdent)
        .subscribe(

          (rspResponse: ApiResponse) => {

            if (rspResponse.Succeeded) {

              // Successful.
              this.mitmAccount = this.modShared.ExtractDataFromPayload(rspResponse, "Account")[0];
              this.mitmPrimaryContact = this.modShared.ExtractDataFromPayload(rspResponse, "AccountContact")[0];
              this.marDocuments = this.modShared.ExtractDataFromPayload(rspResponse, "Document");
              this.marActivity = this.modShared.ExtractDataFromPayload(rspResponse, "Activity");
              itmAttorney = this.modShared.ExtractDataFromPayload(rspResponse, "Attorney")[0];
              itmCollector = this.modShared.ExtractDataFromPayload(rspResponse, "Collector")[0];

              if (itmAttorney != undefined) {

                this.mintAttorneyIdent = itmAttorney.Ident;
                this.mstrAttorneyName = itmAttorney.Name1;
                this.mstrAttorneyCaseNumber = itmAttorney.AttorneyCaseNumber;

              }

              if (itmCollector != undefined) {

                this.mintCollectorABCUserIdent = itmCollector.Ident;
                this.mstrCollectorName = itmCollector.CollectorName;
                this.mstrCollectorPhone = itmCollector.Phone;

              }


              // Set up the Address display for the front end.
              this.FormatAddress(this.mitmAccount);
              this.FormatAddress(this.mitmPrimaryContact);

              this.ScreenMode = this.menmScreenModes.Edit;

            } // Succeeded

          });



    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  } 


  EditRecord(itmAccount: any, intIndex: number) {

    try {

      this.mintRecordIdent = itmAccount.Ident;
      this.mintClaimIdent = itmAccount.FileNumber;

      this.GetAccountDetails(); 


    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  FormatDateAndTime(strDate: string): string {

    let strResult: string = "";
    let datNewDate: Date;

    try {

      datNewDate = new Date(strDate);

      if (datNewDate.getFullYear() != 1900) {

        strResult = datNewDate.toLocaleDateString() + " " + datNewDate.toLocaleTimeString();

      }

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

    return strResult

  }

  ShowCollectorInfo(intABCUserIdent: number) {

    let rfModal: NgbModalRef = null;

    try {

      // Display the modal
      rfModal = this.svcModalService.open(CollectorInformationComponent, { backdrop: 'static', size: 'lg' });
      rfModal.componentInstance.mintABCUserIdent = intABCUserIdent;

      rfModal.result.then((objResult) => {

      }, (reason) => {

        // They dismissed without making a selection

      });


    } catch (ex) {

      this.modShared.LogClientError(ex);

    }


  }

  ShowAttorneyInfo() {

    let rfModal: NgbModalRef = null;

    try {

      // Display the modal
      rfModal = this.svcModalService.open(AttorneyInformationComponent, { backdrop: 'static', size: 'lg' });
      rfModal.componentInstance.mintAttorneyIdent = this.mintAttorneyIdent;
      rfModal.componentInstance.mstrAttorneyCaseNumber = this.mstrAttorneyCaseNumber;

      rfModal.result.then((objResult) => {

      }, (reason) => {

        // They dismissed without making a selection

      });


    } catch (ex) {

      this.modShared.LogClientError(ex);

    }


  } 

  ClearControls() {

    let objSave: any = null;
    let dtStartDate: Date = new Date();

    try {

      // Save the search controls 
      objSave = this.modShared.SaveSearchParameters(this.mfrmForm);

      this.mintRecordIdent = 0;
      this.mfrmForm.reset();

      // Reset initial values
      // TODO: Fill in
      this.mfrmForm.setValue({
        DocumentNumberForSearch: "",
        AccountNumberForSearch: "",
        CustomerNameForSearch: "",
        ClaimNumberForSearch: "1531988",    // TODO: Remove, Testing only.
        CustomerResponseIdentForSearch: 0,
        EscalationCustomerResponseIdentForSearch: 0,
        IncludeWorkedEscalation: true
      });

      // Repopulate the search controls 
      this.modShared.PopulateFormFromItem(this.mfrmForm, objSave);

      this.modShared.MessagesCleared.next();

    } catch (ex) {

      this.modShared.LogClientError(ex)

    }

  }

  FormatDate(strDate: string): string {

    let strResult: string = "";
    let datNewDate: Date;

    try {

      datNewDate = new Date(strDate);

      if (datNewDate.getFullYear() != 1900) {

        strResult = datNewDate.toLocaleDateString();

      }

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

    return strResult

  }

  AddActivity() {

    let rfModal: NgbModalRef = null;

    try {

      // Display the modal
      rfModal = this.svcModalService.open(AddActivityComponent, { backdrop: 'static', size: 'lg' });
      rfModal.componentInstance.mintAccountIdent = this.mintRecordIdent;

      rfModal.result.then((objResult) => {

        if (objResult.RecordSaved) {

          // Need to go out and re-grab the activities.
          this.GetAccountDetails(); 

        } 


      }, (reason) => {

        // They dismissed without making a selection


      });


    } catch (ex) {

      this.modShared.LogClientError(ex);

    }


  } 

  PrintCurrencyType() : string {

    let strResult = ""; 

    try {

      if (this.marDocuments.length != 0) {

        strResult = this.marDocuments[0].CurrencyTypeName;

      } 


    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

    return strResult;

  } 


}
