import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ɵConsole, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, NgForm, ValidatorFn, Validators } from '@angular/forms';
import { modSharedService } from '../../../shared/services/modShared.service';
import { FormControlBase } from '../../../shared/dynamic-controls/form-controls/form-control-base';
import { FormControlService } from '../../../shared/dynamic-controls/form-controls/form-control.service';
import { MaintenanceService } from '../../../shared/services/maintenance.service';
import { ApiResponse } from '../../../shared/models/api-response.model';
import { BaseFormComponent } from '../../../shared/base-form/base-form.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-form-builder',
  templateUrl: './form-builder.component.html',
  providers: [FormControlService],
  styles: [
    `
    .tab-pane {
      padding-top: 20px;
    }
    .tab-container {
      width: 100%;
    }
    .control-container {
      width: 100%;
    }
    `,
  ],
})

export class FormBuilderComponent implements OnInit {
  @Input() title: string;
  @Input() controls: FormControlBase<any>[] = [];
  @Input() tabs: any[] = [];
  @Input() masterFormEvents: Observable<boolean>;
  @Output() formSubmit = new EventEmitter<any>();
  @Output() formReset = new EventEmitter<UntypedFormGroup>();
  nextEvent: any;
  selectedTab: any;
  form: UntypedFormGroup;
  dynamicDataSourceControls: any;
  initialData: any[] = [];
  columns: any[] = [];  

  constructor(private formControl: FormControlService, public modShared: modSharedService, private cdr: ChangeDetectorRef, private svcMaintenanceService: MaintenanceService) { }

  ngOnInit() {

    try {

      this.nextEvent = this.masterFormEvents.subscribe((saveContinue) => {
          this.Save(saveContinue)
      });

      this.selectedTab = this.tabs.length > 0 ? this.tabs[0] : null;
      this.SetLabels();
      this.SetDynamicValues();
      this.form = this.formControl.toFormGroup(this.controls);

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  ngOnDestroy(){
    
    this.nextEvent.unsubscribe();

  }

  Save(saveContinue: boolean){

    this.formSubmit.emit({form: this.form, saveAndContinue: saveContinue});

  }

  SetLabels(){

    try{

      this.controls.forEach(x => {

          x.label = x.label.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1");
          x.label = x.label.replace(/[^0-9](?=[0-9])/g, '$& ');
        
      });

      this.tabs.forEach(x =>{

        x.label = x.label.replace(/([A-Z])/g, ' $1');
        x.label = x.label.replace(/[^0-9](?=[0-9])/g, '$& ');

      });
      
    }

    catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  SetDynamicValues() {

    try {
      this.dynamicDataSourceControls = [];
      this.dynamicDataSourceControls = this.controls.filter(control => control.datasource != undefined && control.datasource != null && control.datasource != null);

      this.dynamicDataSourceControls.forEach(x => {

        if (x.datasource.proc === "uspGetAllActiveABCUsersForStationAssignment") {

          this.svcMaintenanceService.GetABCUsersForStation().subscribe((rspResponse: ApiResponse) => {

            if (rspResponse.Succeeded) {

              var resultOptions: any = rspResponse.Payload[0].Data;

              x.options = [];

              x.options.push({
                "key": "", "value": "Please Select"
              });

              resultOptions.forEach(y => {

                x.options.push({
                  "key": y[x.datasource.key], "value": y[x.datasource.value]
                });

              });
              this.cdr.detectChanges();

            }

          });

        } else {

          if (x.options.length <= 2) {

            x.options = [];

            this.svcMaintenanceService.GetAll(x.datasource.entity)
              .subscribe(
                (rspResponse: ApiResponse) => {

                if (rspResponse.Succeeded) {

                  var resultOptions: any = rspResponse.Payload[0].Data;
                    
                  x.options.push({
                    "key": "", "value": "Please Select"
                  });
                  resultOptions.forEach(y => {
                    x.options.push({
                      "key": y[x.datasource.key], "value": y[x.datasource.value]
                    });
                  });
                  this.cdr.detectChanges();

                }

            });

          }

        }

      });

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  handleControlChange(event) {
    let actions = event.control.onChange;

    if (actions.hasOwnProperty("updateSelects")) {
      if (event.value == undefined || event.value == null || event.value == "") return;
      this.updateSelectDependents(event.control, event.value, actions.updateSelects);
    }

    if (actions.hasOwnProperty("updateValidations")) {
      if (event.value == undefined || event.value == null || event.value == "") return;
      this.updateValidationDependents(event.control, event.value, actions.updateValidations);
    }
  }

  // Called on change of a control with dependent select field sources.
  // Updates the options available in the dependent select fields.
  updateSelectDependents(changedControl, newValue, dependentSelects) {
    try {
      dependentSelects.forEach((updateConfig) => {
        let control = this.controls.find(control => control.key == updateConfig.controlKey);
        control.options = [];
        var queryParams = {};
        queryParams[updateConfig.columnName] = newValue;

        this.svcMaintenanceService.Search(control.datasource.entity, queryParams).subscribe(
          (rspResponse: ApiResponse) => {
            if (rspResponse.Succeeded) {
              var resultOptions: any = rspResponse.Payload[0].Data;              
              resultOptions.forEach(y => {                      
                  control.options.push({
                    "key": y[control.datasource.key], "value": y[control.datasource.value]
                  });                
              });
            }
          });
      });
    }
    catch (ex) {
      this.modShared.LogClientError(ex);
    }
  }

  // Called on change of a control with dependent validation field sources.
  // Updates the dependent validation fields.
  updateValidationDependents(changedControl, newKey, dependentValidations) {
    try {
      dependentValidations.forEach((updateConfig) => {
        let control = this.controls.filter(control => control.key == updateConfig.controlKey);
        if (control && control.length > 0 && updateConfig.selectedValue == newKey) {
          this.form.controls[updateConfig.controlKey].setValidators([Validators.minLength(9), Validators.maxLength(9)]);
          this.form.controls[updateConfig.controlKey].updateValueAndValidity();
        } else {
          this.form.controls[updateConfig.controlKey].clearValidators();
          this.form.controls[updateConfig.controlKey].updateValueAndValidity();
        }
      });
    }
    catch (ex) {
      this.modShared.LogClientError(ex);
    }
  }

  RemitData(tabs) {    
        
    this.controls.forEach(element => {
      let replacementText = element.key;    
      element.value = this.form.controls[replacementText].value;  
    });    

    this.controls.forEach(element1 => {
      if(element1.key.startsWith("Remit")) {
        let shortenedKey = element1.key.replace("RemitTo", "").replace("Remit" ,"");          
        this.controls.forEach(element2 => {
          if(element2.key == shortenedKey) {              
            element1.value = element2.value;                            
          }
        });
      }
    });
    
    this.form = this.formControl.toFormGroup(this.controls);

    if (tabs.length) {
      let idx = tabs.indexOf(this.selectedTab);
      this.selectedTab = tabs[idx + 1];
    }

    this.cdr.detectChanges();

  }

}
