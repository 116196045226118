import { Component, OnInit, ChangeDetectorRef} from '@angular/core';
import { ABCMessage } from '../../shared/models/abc-message.model';
import { modSharedService } from '../../shared/services/modShared.service';
import { OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'abc-message-display',
  templateUrl: './abc-message-display.component.html',
  styleUrls: ['./abc-message-display.component.scss']
})
export class ABCMessageDisplayComponent implements OnInit, OnDestroy {

  // modShared Subscriptions
  private OnMessagesAdded: Subscription;
  private OnMessagesCleared: Subscription;

  marMessages: ABCMessage[] = [];

  constructor(private modShared: modSharedService, private cdr: ChangeDetectorRef) { }

  ngOnInit() {

    try {
      
    //  if () {
        this.OnMessagesAdded = this.modShared.MessagesAdded.subscribe(
          (arMessages: ABCMessage[]) => {
            
            this.marMessages.push(...arMessages);
            this.cdr.detectChanges();
  
          });
  
    //  }
      
      this.OnMessagesCleared = this.modShared.MessagesCleared.subscribe(
        () => {

          this.marMessages = [];

        });
      
    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  ngOnDestroy() {

    try {

      this.OnMessagesAdded.unsubscribe();
      this.OnMessagesCleared.unsubscribe();

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  DetermineErrorStyle() {

    let intMaxABCMessageSeverity: number = modSharedService.enmABCMessageSeverity.SaveSuccess;
    let intMaxABCMessageSeverityPriority: number = 0;
    let intCurrentMessagePriority: number = 0;
    let objClass;

    try {

      // Loop through each of the errors, checking their severity
      // and defaulting to the highest priority
      for (let msgMessage of this.marMessages) {

        intCurrentMessagePriority = this.modShared.GetABCMessageSeverityPriority(msgMessage.ABCMessageSeverityIdent);

        if (intCurrentMessagePriority > intMaxABCMessageSeverityPriority) {

          intMaxABCMessageSeverity = msgMessage.ABCMessageSeverityIdent;
          intMaxABCMessageSeverityPriority = intCurrentMessagePriority;

        }

      }

      switch (intMaxABCMessageSeverity) {

        case modSharedService.enmABCMessageSeverity.Severe:
        case modSharedService.enmABCMessageSeverity.SaveFailedMessages:
        case modSharedService.enmABCMessageSeverity.FailedValidation: {

          objClass = { 'alert-danger': true };
          break;

        }

        case modSharedService.enmABCMessageSeverity.Low:
        case modSharedService.enmABCMessageSeverity.Moderate: {

          objClass = { 'alert-warning': true };
          break;

        }

        case modSharedService.enmABCMessageSeverity.Information: {

          objClass = { 'alert-primary': true };
          break;

        }

        case modSharedService.enmABCMessageSeverity.SaveSuccess: {

          objClass = { 'alert-success': true };
          break;

        }

        default: {

          objClass = { 'alert-warning': true };
          break;

        }

      } // switch(intMaxABCMessageSeverity)

      return objClass;


    } catch (ex) {

      this.modShared.LogClientError(ex);

    }


  }

}
