import { Component, OnInit } from '@angular/core';
import { modSharedService } from '../../shared/services/modShared.service';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';
import { ApiResponse } from '../../shared/models/api-response.model';
import { MaintenanceService } from '../../shared/services/maintenance.service';
import { CustomValidators } from '../../shared/services/custom-validators';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientsService } from 'app/shared/services/clients.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ABCMessage } from '../../shared/models/abc-message.model';
import { AccountService } from '../../shared/services/account.service';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { FileNumberHelpComponent } from './file-number-help/file-number-help.component';
import { _isTestEnvironment } from '@angular/cdk/platform';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-payment-portal-login',
  templateUrl: './payment-portal-login.component.html',
  styleUrls: ['./payment-portal-login.component.css']
})
export class PaymentPortalLoginComponent {


  menmScreenModes = {
    AuthByDetails: 1,
    AuthByGUID: 2
  }

  // Subscriptions

  // Dropdowns
  marCountry: any[] = [];
  marAllState: any[] = [];
  marState: any[] = []; 

  // Data
  mfrmForm: UntypedFormGroup;

  // Properties
  mbolCaptchaResolved: boolean = false;
  mstrCaptchaResponse: string = ""; 
  ScreenMode: number = this.menmScreenModes.AuthByDetails;
  mbolHasPaymentPortal: boolean = environment.hasPaymentPortal;

  constructor(private modShared: modSharedService,
    private svcMaintenanceService: MaintenanceService,
    private svcModalService: NgbModal,
    private valCustomValidators: CustomValidators,
    private svcAuth: AuthenticationService,
    private rtRouter: Router,
    private arRoute: ActivatedRoute) {

    let bolHasPaymentPortal: boolean = false;

    try {

      if (!this.mbolHasPaymentPortal) {

        // They don't have a payment portal, reroute them. 
        this.rtRouter.navigate(['/']);

      } else {

        this.arRoute.queryParams.subscribe(arParameters => {

          if (arParameters['GUID'] != undefined) {

            // Attempt to auth by GUID
            this.svcAuth.AuthenticateByPaymentPortalGUID(arParameters['GUID'])
              .subscribe(

                (rspResponse: ApiResponse) => {

                  if (rspResponse.Succeeded) {

                    // Successful.
                    this.rtRouter.navigate(['/PaymentPortal/PaymentPortalPayment']);

                  } else {

                    // Could not Auth, switch over to details (assuming Payment Portal is enabled and someone isn't trying to trick the system)
                    bolHasPaymentPortal = this.modShared.ExtractValueFromPayload(rspResponse, "HasPaymentPortal");

                    console.log("This" + bolHasPaymentPortal);

                    if (bolHasPaymentPortal != undefined
                      && !bolHasPaymentPortal) {

                      this.rtRouter.navigate(['/']);

                    } else {

                      this.ScreenMode = this.menmScreenModes.AuthByDetails;

                    }


                  } // Succeeded

                });

            this.ScreenMode = this.menmScreenModes.AuthByGUID;

          } else {

            this.ScreenMode = this.menmScreenModes.AuthByDetails;

          }

        }); 

      } 

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  ngOnInit() {

    let arTablesToRetrieve: string[] = [];
    let dtStartDate: Date = new Date();

    try {

      this.mfrmForm = new UntypedFormGroup({
        'ClaimIdent': new UntypedFormControl("", [Validators.required, this.valCustomValidators.Numeric]),
        'Address1': new UntypedFormControl("", []),
        'City': new UntypedFormControl("", []),
        'CountryIdent': new UntypedFormControl(modSharedService.enmCountry.USA, []),
        'StateIdent': new UntypedFormControl(0, []),
        'Zip': new UntypedFormControl("", []),
        'ReCaptchaForm': new UntypedFormControl(undefined, [])
      });

      arTablesToRetrieve.push("State");

      this.svcMaintenanceService.GetAllByList(arTablesToRetrieve)
        .subscribe(

          (rspResponse: ApiResponse) => {

            if (rspResponse.Succeeded) {

              // Successful.
              this.marAllState = this.modShared.ExtractDropdownFromPayload(rspResponse, "StateGetAll");
              this.marState = this.modShared.ExtractDropdownFromPayload(rspResponse, "StateGetAll");

              // Set up the watchers for when a country box changes
              this.mfrmForm.get('CountryIdent').valueChanges.subscribe(intCountryIdent => {

                this.marState = this.marAllState.slice().filter(itmState => itmState.CountryIdent == intCountryIdent || itmState.Ident == 0);

                if (this.marState.find(itmState => itmState.Ident == this.mfrmForm.controls.StateIdent.value) == undefined) {

                  this.mfrmForm.controls.StateIdent.patchValue(0);

                }

              });


            } // Succeeded

          });


      // Have to get Country Separately because it pushed US to the top
      this.svcMaintenanceService.GetAllActiveCountry()
        .subscribe(

          (rspResponse: ApiResponse) => {

            if (rspResponse.Succeeded) {

              // Successful.
              // Don't want to extract Dropdown because we want US to bubble to the top
              this.marCountry = this.modShared.ExtractDataFromPayload(rspResponse, "Country");

            } // Succeeded

          });

      console.log(this.marCountry);

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  checkCaptcha(strCaptchaResponse: string) {

    try {

      this.mbolCaptchaResolved = (strCaptchaResponse && strCaptchaResponse.length > 0) ? true : false;
      this.mstrCaptchaResponse = strCaptchaResponse;

      console.log("Resp: " + strCaptchaResponse);

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  Login() {

    try {

      if (this.mbolCaptchaResolved) {

        if (this.modShared.ValidateForm(this.mfrmForm, [])) {

          this.svcAuth.AuthenticateByPaymentPortalDetails(this.mstrCaptchaResponse, this.mfrmForm.controls.ClaimIdent.value, this.mfrmForm.controls.Address1.value,
            this.mfrmForm.controls.City.value, this.mfrmForm.controls.CountryIdent.value, this.mfrmForm.controls.StateIdent.value, this.mfrmForm.controls.Zip.value)
            .subscribe(

              (rspResponse: ApiResponse) => {

                if (rspResponse.Succeeded) {

                  // Successful.
                  this.rtRouter.navigate(['/PaymentPortal/PaymentPortalPayment']);


                } // Succeeded

              });

        }

      } else {

        this.modShared.AddMessage(new ABCMessage(0, "You must accept the CAPTCHA below.", modSharedService.enmABCMessageSeverity.Severe, ""));

      } 


    } catch (ex) {

      this.modShared.LogClientError(ex);

    }


  }

  CheckScreenMode(intScreenMode: number): boolean {

    let bolResult: boolean = false;

    try {

      bolResult = (this.ScreenMode == intScreenMode);


    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

    return bolResult;

  }

  LoadABCFnHelp() {

    let rfModal: NgbModalRef = null;

    try {

      // Display the modal -- Note, omitting "Backdrop" as we want them to be able to hit Escape and/or click outside the modal
      rfModal = this.svcModalService.open(FileNumberHelpComponent, { size: 'md' });

      rfModal.result.then((objResult) => {

      }, (reason) => {

        // They dismissed without making a selection

      });

      setTimeout(() => {

        let modal = document.getElementsByClassName("modal");
        modal[0].classList.add("in");

      }, 100);

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }


  } 


}
