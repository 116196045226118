import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { modSharedService } from 'app/shared/services/modShared.service';
import { UntypedFormGroup, FormControl, Validators, AbstractControl, RequiredValidator } from '@angular/forms';
import { ApiResponse } from '../../../shared/models/api-response.model';
import { AccountService } from '../../../shared/services/account.service';

@Component({
  selector: 'app-file-number-help',
  templateUrl: './file-number-help.component.html',
  styleUrls: ['./file-number-help.component.css']
})
export class FileNumberHelpComponent {

  // Properties
  mfrmForm: UntypedFormGroup;

  // Input parameters

  // Global Arrays

  constructor(public activeModal: NgbActiveModal,
    private modShared: modSharedService,
    private svcAccountService: AccountService) { }

  ngOnInit() {

    try {

      this.modShared.SetModalDraggable();

      this.mfrmForm = new UntypedFormGroup({});

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  Cancel() {

    let objResult: any = {};

    try {

      // Don't need any logic here
      objResult.RecordSaved = false;
      this.activeModal.close(objResult);
      this.modShared.MessagesCleared.next();

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }


}
