import { Component, Input, OnInit, Output, EventEmitter, ViewChild, TemplateRef, ViewContainerRef, ViewEncapsulation, IterableDiffers, ChangeDetectionStrategy, OnChanges, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { modSharedService } from '../../../shared/services/modShared.service';
import { FormControlBase } from '../../../shared/dynamic-controls/form-controls/form-control-base';
import { FormControlService } from '../../../shared/dynamic-controls/form-controls/form-control.service';
import { DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { FormDataService } from '../../../shared/services/formdata.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MaintenanceService } from '../../../shared/services/maintenance.service';
import { ApiResponse } from '../../../shared/models/api-response.model';
import { resetFakeAsyncZone } from '@angular/core/testing';
import { DragulaService } from "ng2-dragula";
import { element } from 'protractor';
import { AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-table-builder',
  templateUrl: './table-builder.component.html',
  providers: [FormControlService],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./table-builder.component.css']
})

export class TableBuilderComponent implements OnInit, AfterViewInit {
  dynamicDataSourceControls: any;
  initialData: any[] = [];
  columns: any[] = [];
  iconsCss: any;
  mintRecordIdent: number = 0;
  mintCurrentRecordIndex: number = 0;
  editRowIdent: number = 0;
  private _entityName: string = "";
  private routeSub: any;
  @Input() showTable: boolean;
  @Input() entity: string;
  @Input() rows: any[] = [];
  @Input() pageSize: number;
  @Input() draggable: boolean;
  @Input() dataColumns: any[] = [];
  @Input() searchValues: any;
  @Input() controls: FormControlBase<any>[] = [];
  @Output() formSubmit = new EventEmitter<any>();
  @Output() formChange = new EventEmitter<any>();
  @Output() Edit: EventEmitter<any> = new EventEmitter<any>(true);
  form: UntypedFormGroup;
  @ViewChild('editTemplate', { static: false }) editTemplate: TemplateRef<any>;
  @ViewChild(DatatableComponent, { static: false }) mydatatable: DatatableComponent;


  SelectionType = SelectionType;

  constructor(private formControl: FormControlService, private iterableDiffers: IterableDiffers, private svcMaintenanceService: MaintenanceService, public formData: FormDataService, public modShared: modSharedService, private router: Router, private vcRef: ViewContainerRef, private activatedRoute: ActivatedRoute, private cdr: ChangeDetectorRef, private dragulaService: DragulaService) {
    var self = this;
  }

  ngOnInit() {

    try {
      if (this.rows && this.rows.length) {
        let keys = Object.keys(this.rows[0]);
        if (keys.includes('Rank'))
          this.rows.sort((a, b) => (a.Rank > b.Rank) ? 1 : -1);
      }
      this.iconsCss = { sortAscending: 'glyphicon glyphicon-chevron-down', sortDescending: 'glyphicon glyphicon-chevron-up', pagerLeftArrow: 'fa fa-angle-left', pagerRightArrow: 'fa fa-angle-right', pagerPrevious: 'fa fa-angle-double-left', pagerNext: 'fa fa-angle-double-right' };
      this.initialData = this.rows;


      if (this.controls) {
        this.form = this.formControl.toFormGroup(this.controls);
      }

      if (this.searchValues && Object.keys(this.searchValues).length > 1) {

        let sv = this.searchValues;
        delete sv.Active;
        let svKeys = Object.keys(sv);
        let formKeys = Object.keys(this.form.value);

        for (let key in formKeys) {
          let keyExists = svKeys.filter(x => x == formKeys[key]);
          if (!keyExists || keyExists.length == 0)
            sv[formKeys[key]] = "";
        }

        svKeys = Object.keys(sv);

        for (let key in svKeys) {
          let keyExists = formKeys.filter(x => x == svKeys[key]);
          if (!keyExists || keyExists.length == 0)
            delete sv[svKeys[key]];
        }

        this.form.setValue(sv);

      }

      this.form.valueChanges.subscribe(changes => {

        this.formChange.emit(this.form.value)

      });

      this.SetDynamicValues();

      //    this.mydatatable.onColumnSort({sorts: this.columns});

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  ngAfterViewInit() {
    
    this.columns.length = 0;
    this.columns.push({
      "prop": "Ident", "name": "", "cellTemplate": this.editTemplate, "width": 75
    });

    this.dataColumns.forEach(x => {

      if ((x.showInGrid || x.showInGrid === undefined) && x.key.toUpperCase() != "IDENT") {

        if (x.datasource != null && x.datasource != undefined && x.datasource != null) {

          if (x.datasource.entity) {

            this.columns.push({
              "prop": x.datasource.entity + '.' + x.datasource.value, "name": x.label, "width": x.width ? x.width : 135
            });

          }
          else if (x.datasource.proc) {

            this.columns.push({
              "prop": x.datasource.entityName + '.' + x.datasource.value, "name": x.label, "width": x.width ? x.width : 135
            });

          }

        } else {

          this.columns.push({
            "prop": x.key, "name": x.label, "width": x.width ? x.width : 135
          });
          this.columns = [...this.columns]
        }
      }
    });
  }

  ngOnChanges() {

    this.ngOnInit();
    this.cdr.detectChanges();

  }

  Reset() {

    try {

      this.form.reset();

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  EditRecord(value: string) {

    try {

      this.Edit.emit(value);
      this.svcMaintenanceService.SetEditIdent(value);

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  SetColumnLabels() {

    try {

      this.dataColumns.forEach(x => {

        x.label = x.label.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1");
        x.label = x.label.replace(/[^0-9](?=[0-9])/g, '$& ');

      });

    }
    catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  AdjustRank(btnClicked, currentRow) {

    if (btnClicked == 'top') {

      let storedValue = currentRow.Rank;

      if (storedValue != 1) {
        currentRow.Rank = 1;
        this.rows.forEach(element => {
          if ((element.Rank <= storedValue) && (element.Rank + 1 <= this.rows.length) && (element.Ident != currentRow.Ident)) {
            element.Rank = element.Rank + 1;
          }
        });
      }

    }
    else if (btnClicked == 'up') {

      let storedValue = currentRow.Rank;

      if (currentRow.Rank > 1) {
        currentRow.Rank--;
        this.rows.forEach(element => {
          if ((element.Rank == currentRow.Rank) && (element.Ident != currentRow.Ident)) {
            element.Rank = storedValue;
          }
        });
      }
    }
    else if (btnClicked == 'down') {

      let storedValue = currentRow.Rank;

      if (currentRow.Rank < this.rows.length) {
        currentRow.Rank++;
        this.rows.forEach(element => {
          if ((element.Rank == currentRow.Rank) && (element.Ident != currentRow.Ident)) {
            element.Rank = storedValue;
          }
        });
      }
    }
    else if (btnClicked == 'bottom') {

      let storedValue = currentRow.Rank;

      if (storedValue != this.rows.length) {
        currentRow.Rank = this.rows.length;
        this.rows.forEach(element => {
          if ((element.Rank >= storedValue) && (element.Ident != currentRow.Ident)) {
            element.Rank = element.Rank - 1;
          }
        });
      }
    }
    this.RecalibrateRows();
  }

  SaveInlineRank(rowData: any) {

    let entityParam = this.activatedRoute.snapshot.params["entity"];

    this._entityName = entityParam;
    this.svcMaintenanceService.Update(this._entityName, rowData)
      .subscribe((rspResponse: ApiResponse) => {
      });
  }

  onDrop(event) {
    this.rows = event;

    this.rows.forEach(element => {
      element.Rank = event.indexOf(element) + 1;
      this.rows[element.Rank - 1] = element;
    });

    this.RecalibrateRows();
  }

  RecalibrateRows() {
    this.ngOnInit();
    this.mydatatable.sorts = [{ prop: 'Rank', dir: 'asc' }];
    this.rows.forEach(element => {
      this.SaveInlineRank(element);
    });
  }

  SetDynamicValues() {

    try {
      this.dynamicDataSourceControls = [];
      this.dynamicDataSourceControls = this.controls.filter(control => control.datasource != undefined && control.datasource != null && control.datasource != null);

      this.dynamicDataSourceControls.forEach(x => {

        if (x.datasource.proc === "uspGetAllActiveABCUsersForStationAssignment") {

          this.svcMaintenanceService.GetABCUsersForStation().subscribe((rspResponse: ApiResponse) => {

            if (rspResponse.Succeeded) {

              var resultOptions: any = rspResponse.Payload[0].Data;

              x.options = [];

              x.options.push({
                "key": "", "value": "Please Select"
              });

              resultOptions.forEach(y => {

                x.options.push({
                  "key": y[x.datasource.key], "value": y[x.datasource.value]
                });

              });

            }

          });

          this.cdr.detectChanges();

        } else {
          this.svcMaintenanceService.GetAll(x.datasource.entity)
            .subscribe(
              (rspResponse: ApiResponse) => {

                if (rspResponse.Succeeded) {

                  var resultOptions: any = rspResponse.Payload[0].Data;

                  x.options = [];

                  x.options.push({
                    "key": "", "value": "Please Select"
                  });
                  resultOptions.forEach(y => {
                    x.options.push({
                      "key": y[x.datasource.key], "value": y[x.datasource.value]
                    });
                  });

                }
                
                this.cdr.detectChanges();

              });
        }

      });

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

}
