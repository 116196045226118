import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

// Custom Components
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ChangePasswordComponent } from './system/change-password/change-password.component';
import { SecureResetComponent } from './system/secure-reset/secure-reset.component';
import { ForgotPasswordComponent } from './system/forgot-password/forgot-password.component';
import { ReportViewerComponent } from './reports/report-viewer/report-viewer.component';
import { AccountsComponent } from './manage/accounts/accounts.component';
import { ClaimSubmissionComponent } from './claim-submission/claim-submission.component';
import { ReportsComponent } from './manage/reports/reports.component';
import { ClaimDetailComponent } from './reports/Client/claim-detail/claim-detail.component';
import { ClientSummaryComponent } from './reports/Client/client-summary/client-summary.component';
import { DailyStatisticsComponent } from './reports/Client/daily-statistics/daily-statistics.component';
import { MonthlyPerformanceComponent } from './reports/Client/monthly-performance/monthly-performance.component';
import { PaymentPortalLoginComponent } from './PaymentPortal/payment-portal-login/payment-portal-login.component';
import { PaymentPortalPaymentComponent } from './PaymentPortal/payment-portal-payment/payment-portal-payment.component';

const appRoutes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login/NotAuthorized', component: LoginComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'manage/Accounts', component: AccountsComponent },
  { path: 'system/SecureReset', component: SecureResetComponent },
  { path: 'system/ForgotPassword', component: ForgotPasswordComponent},
  { path: 'system/ChangePassword', component: ChangePasswordComponent },
  { path: 'system/ChangePassword', component: ChangePasswordComponent },
  { path: 'reporting/ReportViewer', component: ReportViewerComponent },
  { path: 'claimSubmission', component: ClaimSubmissionComponent },
  { path: 'manage/Reports',
    component: ReportsComponent,
    children: [
      { path: 'Client/ClaimDetail', component: ClaimDetailComponent },
      { path: 'Client/ClientSummary', component: ClientSummaryComponent },
      { path: 'Client/DailyStatistics', component: DailyStatisticsComponent },
      { path: 'Client/MonthlyPerformance', component: MonthlyPerformanceComponent }
    ]
  },
  { path: 'PaymentPortal', component: PaymentPortalLoginComponent },
  { path: 'PaymentPortal/PaymentPortalPayment', component: PaymentPortalPaymentComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { preloadingStrategy: PreloadAllModules, useHash: true, onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule {

}

