import { Component, OnInit } from '@angular/core';
import { modSharedService } from '../../shared/services/modShared.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { ApiResponse } from '../../shared/models/api-response.model';
import { ABCMessage } from '../../shared/models/abc-message.model';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

   mfrmForgotPassword: UntypedFormGroup;

  constructor(private modShared: modSharedService,
              private svcAuthenticationService: AuthenticationService,
              private rtRouter: Router,
              private rtLocation: ActivatedRoute) {

    try {

    } catch (ex) {

      modShared.LogClientError(ex);

    }

  }

  ngOnInit() {

    try {

           this.mfrmForgotPassword = new UntypedFormGroup({

        'UserName': new UntypedFormControl('', [Validators.required]),
        'EmailAddress': new UntypedFormControl('', [Validators.required])

      });


    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }


  Cancel() {

    try {

      //this.ClearControls();

      this.rtRouter.navigate(['/']);

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }


  }

  ForgotPassword() {

    let strUsername: string = "";
    let strEmail: string = "";
    
    try {

     

        
        this.svcAuthenticationService.ForgotPassword(this.mfrmForgotPassword.controls.UserName.value, this.mfrmForgotPassword.controls.EmailAddress.value)
            .subscribe(
              (rspResponse: ApiResponse) => {

                if (rspResponse.Succeeded) {

                  this.rtRouter.navigate(['/']);

                }

               

              });



    } catch (ex) {

      this.modShared.LogClientError(ex)

    }

  } 


}
