import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { modSharedService } from 'app/shared/services/modShared.service';
import { UntypedFormGroup, FormControl, Validators, AbstractControl, RequiredValidator } from '@angular/forms';
import { ApiResponse } from '../../../shared/models/api-response.model';
import { AccountService } from '../../../shared/services/account.service';

@Component({
  selector: 'app-attorney-information',
  templateUrl: './attorney-information.component.html',
  styleUrls: ['./attorney-information.component.css']
})
export class AttorneyInformationComponent implements OnInit {

  // Properties
  mfrmForm: UntypedFormGroup;
  mitmAttorney: any = {}; 

  // Input parameters
  @Input() mintAttorneyIdent: number = 0; 
  @Input() mstrAttorneyCaseNumber: string = ""; 

  // Global Arrays

  constructor(public activeModal: NgbActiveModal,
    private modShared: modSharedService,
    private svcAccountService: AccountService) { }

  ngOnInit() {

    try {

      this.mfrmForm = new UntypedFormGroup({});

      this.svcAccountService.GetAttorneyInformation(this.mintAttorneyIdent)
        .subscribe(

          (rspResponse: ApiResponse) => {

            if (rspResponse.Succeeded) {

              // Successful.
              this.mitmAttorney = this.modShared.ExtractDataFromPayload(rspResponse, "Attorney")[0];

              this.FormatAddress(this.mitmAttorney); 

            } // Succeeded

          });


    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  FormatAddress(itmItem: any) {

    try {

      itmItem.DisplayAddress = itmItem.Address1;

      if (itmItem.Address2.toString().trim() != "") {

        itmItem.DisplayAddress += "<br />" + itmItem.Address2;

      }

      if (itmItem.Address3.toString().trim() != "") {

        itmItem.DisplayAddress += "<br />" + itmItem.Address3;

      }

      itmItem.DisplayAddress += "<br />" + itmItem.CityStateCountry;

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  Cancel() {

    let objResult: any = {};

    try {

      // Don't need any logic here, either they added docs or not.
      objResult.RecordSaved = false;
      this.activeModal.close(objResult);
      this.modShared.MessagesCleared.next();

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

}
