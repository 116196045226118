import { Component } from '@angular/core';

@Component({
  selector: 'payment-portal-footer',
  templateUrl: './payment-portal-footer.component.html',
  styleUrls: ['./payment-portal-footer.component.css']
})
export class PaymentPortalFooterComponent {

}
