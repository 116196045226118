export class ABCDictionary {

  Key: string;
  Value: any;

  constructor(strKey: string, objValue: any) {

    this.Key = strKey;
    this.Value = objValue;

  }

} 
