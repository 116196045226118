import { Component, OnInit } from '@angular/core';
import { modSharedService } from '../../shared/services/modShared.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { ApiResponse } from '../../shared/models/api-response.model';
import { ABCMessage } from '../../shared/models/abc-message.model';

@Component({
  selector: 'app-secure-reset',
  templateUrl: './secure-reset.component.html',
  styleUrls: ['./secure-reset.component.scss']
})
export class SecureResetComponent implements OnInit {

  mfrmChangePassword: UntypedFormGroup;

  // Password requirments, used on screen and within component
  mintMinNumberPasswordRequirementsVariable: number;
  mintPasswordMinLengthVariable: number;
  mintPasswordNonAlphaVariable: number;
  mintPasswordNumberVariable: number;
  mintPasswordUpperCaseVariable: number;
  mintABCUserHistoryIntervalVariable: number;
  mstrSecureGUID: string;
  mbolValidURL: boolean = false; 

  constructor(private modShared: modSharedService,
              private svcAuthenticationService: AuthenticationService,
              private rtRouter: Router,
              private rtLocation: ActivatedRoute) {

    try {

      this.rtLocation.queryParams.subscribe(params => {

        this.mstrSecureGUID = params['guid'];

        // Verify that this link is valid, otherwise we're a no-go
        this.svcAuthenticationService.VerifyABCUserLink(this.mstrSecureGUID)
          .subscribe(

            (rspResponse: ApiResponse) => {

              this.mbolValidURL = rspResponse.Succeeded;

              if (rspResponse.Succeeded) {

                // Successful.


              } // Succeeded
              
            });

      });

    } catch (ex) {

      modShared.LogClientError(ex);

    }

  }

  ngOnInit() {

    try {

      let arResults: any[];

      this.mfrmChangePassword = new UntypedFormGroup({

        'NewPassword': new UntypedFormControl('', [Validators.required]),
        'ConfirmNewPassword': new UntypedFormControl('', [Validators.required])

      });

      // Get the password Requirments
      // This and the rest of this component need to use Authentication because they are not authenticated yet.
      this.svcAuthenticationService.GetPasswordRequirements()
        .subscribe(
          (rspResponse: ApiResponse) => {

            arResults = this.modShared.ExtractDataFromPayload(rspResponse, "PasswordRequirements");

            this.mintMinNumberPasswordRequirementsVariable = arResults[0].MinNumberPasswordRequirementsVariable;
            this.mintPasswordMinLengthVariable = arResults[0].PasswordMinLengthVariable;
            this.mintPasswordNonAlphaVariable = arResults[0].PasswordNonAlphaVariable;
            this.mintPasswordNumberVariable = arResults[0].PasswordNumberVariable;
            this.mintPasswordUpperCaseVariable = arResults[0].PasswordUpperCaseVariable;
            this.mintABCUserHistoryIntervalVariable = arResults[0].ABCUserHistoryIntervalVariable;

          });

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }


  ClearFormControls() {

    try {

      // Reset initial values
      this.mfrmChangePassword.setValue({
        NewPassword: '',
        ConfirmNewPassword: ''
      });

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }


  }

  ClearControls() {

    try {

      this.mfrmChangePassword.reset();

      this.ClearFormControls();

      this.modShared.MessagesCleared.next();

    } catch (ex) {

      this.modShared.LogClientError(ex)

    }

  }


  Cancel() {

    try {

      this.ClearControls();

      this.rtRouter.navigate(['/']);

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }


  }

  ChangePassword() {

    let strNewPassword: string = "";
    let strConfirmNewPassword: string = "";
    
    try {

      strNewPassword = this.mfrmChangePassword.controls.NewPassword.value;
      strConfirmNewPassword = this.mfrmChangePassword.controls.ConfirmNewPassword.value;

      if (this.modShared.ValidateForm(this.mfrmChangePassword, [])) {

        if (strNewPassword !== strConfirmNewPassword) {

          // TODO: Do we want to dynamically pull a message here? I don't think so, avoid
          // the round trip.
          this.modShared.AddMessage(new ABCMessage(0, "New Password and Confirm New Password must match", modSharedService.enmABCMessageSeverity.Severe, ""));

        } else {

          // Try to update
          this.svcAuthenticationService.ResetPassword(this.mstrSecureGUID, strNewPassword, strConfirmNewPassword)
            .subscribe(
              (rspResponse: ApiResponse) => {

                if (rspResponse.Succeeded) {

                  this.rtRouter.navigate(['/']);

                }

                this.ClearFormControls();

              });

        } // Else: New = Confirm

      } // this.modShared.ValidateForm


    } catch (ex) {

      this.modShared.LogClientError(ex)

    }

  } 


}
