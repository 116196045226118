import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { modSharedService } from '../../shared/services/modShared.service';
import { UntypedFormGroup, FormControl, Validators, AbstractControl, RequiredValidator } from '@angular/forms';
import { MaintenanceService } from '../../shared/services/maintenance.service';
import { ApiResponse } from '../../shared/models/api-response.model';
import { ABCDictionary } from '../../shared/models/abc.dictionary.model';

@Component({
  selector: 'app-printer-selection',
  templateUrl: './printer-selection.component.html',
  styleUrls: ['./printer-selection.component.scss']
})
export class PrinterSelectionComponent implements OnInit {

  constructor(private modShared: modSharedService,
              private svcMaintenance: MaintenanceService) { }


  @Output()
  OnPrinterSelectionChanged: EventEmitter<any[]> = new EventEmitter<any[]>();

  marPrinters: any[] = []; 
  mfrmForm: UntypedFormGroup;
  
  ngOnInit() {

    try {

      this.mfrmForm = new UntypedFormGroup({

      });


      this.svcMaintenance.GetAll("CorrespondencePrinter")
        .subscribe(

          (rspResponse: ApiResponse) => {

            if (rspResponse.Succeeded) {

              // Successful.
              this.marPrinters = this.modShared.ExtractDataFromPayload(rspResponse, "MaintenanceGetAll");

            } // Succeeded

          });

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  ChangeChecked(itmItem: any, intIndex: number) {

    try {

      // Because this was a stock maintenance form, we don't know if selected is even a property, so check that first
      if (itmItem.Selected == undefined) {

        itmItem.Selected = false;

      } 

      itmItem.Selected = !itmItem.Selected;

      // Raise up the value change
      this.OnPrinterSelectionChanged.emit(this.marPrinters.filter(itmItem => itmItem.Selected == true)); 


    } catch (ex) {

      this.modShared.LogClientError(ex);

    }


  }
  
}
