import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { modSharedService } from '../shared/services/modShared.service';
import { AuthenticationService } from '../shared/services/authentication.service';
import { ApiResponse } from '../shared/models/api-response.model';
import { ABCMessage } from '../shared/models/abc-message.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  mbolAuthorized: boolean = true;

  constructor(public modShared: modSharedService,
    private svcAuth: AuthenticationService,
    private rtRouter: Router,
    private svcModalService: NgbModal,
    private svcTitleService: Title) {


    try {

      if (this.rtRouter.getCurrentNavigation() != undefined
        && this.rtRouter.getCurrentNavigation().extras != undefined
        && this.rtRouter.getCurrentNavigation().extras.state != undefined
        && this.rtRouter.getCurrentNavigation().extras.state.data.IsAuthorized != undefined) {

        this.mbolAuthorized = this.rtRouter.getCurrentNavigation().extras.state.data.IsAuthorized;

      } else {

        console.log("No State!");

      }


    } catch (ex) {

      this.modShared.LogClientError(ex);

    }


  }


  ngOnInit() {

    try {

      this.modShared.mstrAuthToken = '';
      this.modShared.mstrUsername = "";
      this.modShared.marUserRoles = "";
      this.modShared.marUserPermissions = "";
      this.modShared.mstrClientFocusName = "";
      this.modShared.mintClientFocusIdent = 0;
      this.modShared.mstrClientFocusNumber = "";
      this.modShared.SetEscalations([]); 

      if (!this.mbolAuthorized) {

        this.modShared.MessagesCleared.next();
        this.modShared.AddMessage(new ABCMessage(0, "This user is not authorized to access this application.", modSharedService.enmABCMessageSeverity.Severe, ""));

      }

      this.svcTitleService.setTitle(this.modShared.mstrApplicationName);

      this.modShared.mintClientFocusIdent = 0; 

      this.svcModalService.dismissAll(); 

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }


  }

  onSignin(frmLoginForm: NgForm) {

    try {

      // Don't particularly like having the subscribe here. Consider moving it to the authentication service
      this.svcAuth.LoginUser(frmLoginForm.value.txtUsername,
        frmLoginForm.value.txtPassword)
        .subscribe(

          (rspResponse: ApiResponse) => {

            if (rspResponse.Succeeded) {

              rspResponse.Payload.forEach((item) => {
                if (item["ItemName"] == "UserIdent") {
                  this.svcAuth.SetUserIdent(item["Data"]);
                }
              });

              if (this.modShared.ExtractDataFromPayload(rspResponse, "PasswordExpied")) {

                this.modShared.mbolRestrictAccess = 'true';
                this.rtRouter.navigate(['/system/ChangePassword']);

              } else {

                this.rtRouter.navigate(['/dashboard']);
                this.modShared.mstrUsername = frmLoginForm.value.txtUsername;

              }

              this.modShared.marUserRoles = JSON.stringify(this.modShared.ExtractDataFromPayload(rspResponse, "Roles"));
              this.modShared.marUserPermissions = JSON.stringify(this.modShared.ExtractDataFromPayload(rspResponse, "Permissions"));

            }

          });


    } catch (ex) {

      // perform error handling
      console.log(ex);

    }

  }

  ForgotPassword() {

    try {

      this.rtRouter.navigate(['/system/ForgotPassword']);

    } catch (ex) {

      // perform error handling
      console.log(ex);

    }

  }


}
