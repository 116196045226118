import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule, FormControl } from '@angular/forms';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GlobalVars } from './globalvars';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { environment } from '../environments/environment';

// Angular Material Controls
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyRadioButton as MatRadioButton, MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';

import 'jquery-ui-dist/jquery-ui';

// Custom Components
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AppRoutingModule } from './app-routing.module';
import { modSharedService } from './shared/services/modShared.service';
import { AuthenticationService } from './shared/services/authentication.service';
import { HeaderComponent } from './core/header/header.component';
import { ReportingService } from './shared/services/reporting.service';
import { ABCMessageDisplayComponent } from './core/abc-message-display/abc-message-display.component';
import { DynamicComponent } from './maintenance/dynamic/dynamic.component';
import { ReferenceTablesService } from './shared/services/referenceTables.service';
import { MessagingService } from './shared/services/messaging.service';
import { DisableControlDirective } from './shared/directives/disable-control.directive';


// Third party
import { LeftRightComboBoxComponent } from './shared/dynamic-controls/control-components/left-right-combo-box/left-right-combo-box.component';
import { RecordLockingService } from './shared/services/record-locking.service';
import { CustomValidators } from './shared/services/custom-validators';
import { ChangePasswordComponent } from './system/change-password/change-password.component';
import { SecureResetComponent } from './system/secure-reset/secure-reset.component';
import { ReportViewerComponent } from './reports/report-viewer/report-viewer.component';
import { SearchableLookupComponent } from './core/searchable-lookup/searchable-lookup.component';
import { PrinterSelectionComponent } from './core/printer-selection/printer-selection.component';
import { PrinterOutputComponent } from './shared/modals/printer-output/printer-output.component';

// Third party
import { MaintenanceService } from './shared/services/maintenance.service';
import { DynamicFormControlComponent } from './shared/dynamic-controls/dynamic-form-control/dynamic-form-control.component';
import { FormControlService } from './shared/dynamic-controls/form-controls/form-control.service';
import { FormBuilderComponent } from './core/dynamic-form-components/form-builder/form-builder.component';
import { TableBuilderComponent } from './core/dynamic-form-components/table-builder/table-builder.component';
import { BaseFormComponent } from './shared/base-form/base-form.component';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { PanelMenuModule } from 'primeng/panelmenu';
import { TabViewModule } from 'primeng/tabview';
import { MenuModule } from 'primeng/primeng';
import { PasswordModule, InputTextModule } from 'primeng/primeng';
import { DragulaModule } from "ng2-dragula";
import { DragulaService } from "ng2-dragula";
import { DragulaExtendedDirective } from "../dragula-extended.directive";

// Custom Code
import { ForgotPasswordComponent } from './system/forgot-password/forgot-password.component';
import { MatTreeModule} from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { AngularSplitModule } from 'angular-split';
import { AccountService } from './shared/services/account.service';
import { ChangeClientFocusComponent } from './dashboard/change-client-focus/change-client-focus.component';
import { ManageUsersService } from './shared/services/manageUsers.service';
import { ClientsService } from './shared/services/clients.service';
import { AccountsComponent } from './manage/accounts/accounts.component';
import { AttorneyInformationComponent } from './manage/accounts/attorney-information/attorney-information.component';
import { CollectorInformationComponent } from './manage/accounts/collector-information/collector-information.component';
import { AddActivityComponent } from './manage/accounts/add-activity/add-activity.component';
import { ClaimSubmissionComponent } from './claim-submission/claim-submission.component';
import { ReportsComponent } from './manage/reports/reports.component';
import { ClaimDetailComponent } from './reports/Client/claim-detail/claim-detail.component';
import { ClientSummaryComponent } from './reports/Client/client-summary/client-summary.component';
import { DailyStatisticsComponent } from './reports/Client/daily-statistics/daily-statistics.component';
import { MonthlyPerformanceComponent } from './reports/Client/monthly-performance/monthly-performance.component';
import { PaymentPortalLoginComponent } from './PaymentPortal/payment-portal-login/payment-portal-login.component';
import { PaymentPortalFooterComponent } from './PaymentPortal/payment-portal-footer/payment-portal-footer.component';
import { PaymentPortalHeaderComponent } from './PaymentPortal/payment-portal-header/payment-portal-header.component';
import { FileNumberHelpComponent } from './PaymentPortal/payment-portal-login/file-number-help/file-number-help.component';
import { PaymentPortalPaymentComponent } from './PaymentPortal/payment-portal-payment/payment-portal-payment.component';
import { CCVHelpComponent } from './PaymentPortal/payment-portal-payment/ccv-help/ccvhelp.component';

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        DashboardComponent,
        HeaderComponent,
        ABCMessageDisplayComponent,
        DynamicComponent,
        FormBuilderComponent,
        TableBuilderComponent,
        BaseFormComponent,
        DynamicFormControlComponent,
        LeftRightComboBoxComponent,
        DragulaExtendedDirective,
        ChangePasswordComponent,
        SecureResetComponent,
        ForgotPasswordComponent,
        ReportViewerComponent,
        SearchableLookupComponent,
        PrinterSelectionComponent,
        DisableControlDirective,
        PrinterOutputComponent,
        ChangeClientFocusComponent,
        AccountsComponent,
        AttorneyInformationComponent,
        CollectorInformationComponent,
        AddActivityComponent,
        ClaimSubmissionComponent,
        ReportsComponent,
        ClaimDetailComponent,
        ClientSummaryComponent,
        DailyStatisticsComponent,
        MonthlyPerformanceComponent,
        PaymentPortalLoginComponent,
        PaymentPortalFooterComponent,
        PaymentPortalHeaderComponent,
        FileNumberHelpComponent,
        PaymentPortalPaymentComponent,
        CCVHelpComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        SharedModule,
        AppRoutingModule,
        FormsModule,
        Ng2GoogleChartsModule,
        ReactiveFormsModule,
        CommonModule,
        NgxDatatableModule,
        PanelMenuModule,
        TabViewModule,
        MenuModule,
        PasswordModule,
        InputTextModule,
        BrowserAnimationsModule,
        DragulaModule,
        NgbModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatInputModule,
        MatTabsModule,
        MatTreeModule,
        MatIconModule,
        MatButtonModule,
        MatCardModule,
        MatRadioModule,
        AngularSplitModule,
        RecaptchaModule
    ],
    exports: [FormBuilderComponent, TableBuilderComponent, BaseFormComponent, DragulaExtendedDirective],
    providers: [modSharedService, AuthenticationService, ReportingService, MaintenanceService,
        FormControlService, ReferenceTablesService, GlobalVars, DragulaService,
        CustomValidators, RecordLockingService, MessagingService, DatePipe,
      DecimalPipe, AccountService, ManageUsersService, ClientsService, {
        provide: RECAPTCHA_SETTINGS,
        useValue: {
          siteKey: environment.recaptcha.siteKey,
        } as RecaptchaSettings,
      }],
    bootstrap: [AppComponent]
})
export class AppModule { }
