import { Component, OnInit, Input } from '@angular/core';
import { modSharedService } from '../../../shared/services/modShared.service';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';
import { ApiResponse } from '../../../shared/models/api-response.model';
import { CustomValidators } from '../../../shared/services/custom-validators';
import { ReportingService } from '../../../shared/services/reporting.service';
import { ABCDictionary } from '../../../shared/models/abc.dictionary.model';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { MaintenanceService } from '../../../shared/services/maintenance.service';

@Component({
  selector: 'app-client-summary',
  templateUrl: './client-summary.component.html',
  styleUrls: ['./client-summary.component.css']
})
export class ClientSummaryComponent implements OnInit {

  // Properties
  mfrmForm: UntypedFormGroup;
  mintReportIdent: number = modSharedService.enmReports.ClientSummaryPlacement;
  marReportReportFormatXRef: any[] = [];
  marDateParamValue: any[] = [];

  menmReportType = {
    Placement: 1,
    Calendar: 2
  }

  mintReportIdentMonthly = modSharedService.enmReports.ClientSummaryMonthly;
  mintReportIdentPlacement = modSharedService.enmReports.ClientSummaryPlacement;

  constructor(private modShared: modSharedService,
    private svcReporting: ReportingService,
    private svcAuth: AuthenticationService,
    private svcMaintenanceService: MaintenanceService,
    private valCustomValidators: CustomValidators,
    private router: Router) { }

  ngOnInit() {

    try {

      this.mfrmForm = new UntypedFormGroup({
        'ReportReportFormatXRefIdent': new UntypedFormControl(0, [this.valCustomValidators.DropdownRequired]),
        'ClientIdent': new UntypedFormControl(0, []),
        'ParentClientIdent': new UntypedFormControl(0, []),
        'ReportTypeIdent': new UntypedFormControl(1, [this.valCustomValidators.DropdownRequired]),
        'DateParamIdent': new UntypedFormControl(0, [this.valCustomValidators.DropdownRequired]),
        'DateParamValue': new UntypedFormControl("", [this.valCustomValidators.DropdownRequired])
      });

      this.mfrmForm.get('ReportTypeIdent').valueChanges.subscribe(intReportTypeIdent => {

        switch (intReportTypeIdent) {

          case this.menmReportType.Calendar:

            this.mintReportIdent = this.mintReportIdentMonthly;
            break;

          case this.menmReportType.Placement:

            this.mintReportIdent = this.mintReportIdentPlacement;
            break;

        }

        this.mfrmForm.controls.ReportReportFormatXRefIdent.patchValue(0);

        this.GetReportFormats();

      });

      this.mfrmForm.controls.ParentClientIdent.patchValue(this.modShared.mintClientFocusIdent); 

      this.PopulateDateDropdown();
      this.GetReportFormats();

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  GetReportFormats() {

    try {

      this.svcReporting.GetReportReportFormatXRefByReportIdent(this.mintReportIdent)
        .subscribe(

          (rspResponse: ApiResponse) => {

            if (rspResponse.Succeeded) {

              // Successful.
              this.marReportReportFormatXRef = this.modShared.ExtractDropdownFromPayload(rspResponse, "ReportReportFormatXRef");

            } // Succeeded

          });


    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  RunReport() {

    let intReportIdent: number;
    let intReportFormatIdent: number;
    let strFilename: string;
    let strLogoPath: string;
    let intStoredProcedureTypeIdent: number;
    let intReportReportFormatXRefIdent: number;
    let arTableNames: string[];
    let slParameters: ABCDictionary[];
    let strReportName: string;
    let dtStartDate: Date = new Date();

    try {

      if (this.modShared.ValidateForm(this.mfrmForm, [])) {

        intReportIdent = this.mintReportIdent;
        intReportFormatIdent = 0;
        intReportReportFormatXRefIdent = this.mfrmForm.controls.ReportReportFormatXRefIdent.value;
        strFilename = "ClientSummary";
        strLogoPath = "template/abc_report_logo.gif";
        intStoredProcedureTypeIdent = modSharedService.enmStoredProcedureType.Single;

        arTableNames = [];

        if (this.mfrmForm.controls.ReportTypeIdent.value == 1) {

          this.mintReportIdent = modSharedService.enmReports.ClientSummaryPlacement;

          arTableNames.push("PlacementsYTD");
          arTableNames.push("CollectionsYTD");
          arTableNames.push("InterestYTD");
          arTableNames.push("InHouseYTD");
          arTableNames.push("AttorneyYTD");
          arTableNames.push("FeesTotal");
          arTableNames.push("CourtCostsYTD");
          arTableNames.push("SuitFeesYTD");
          arTableNames.push("TotalLegal");
          arTableNames.push("WriteOffs");
          arTableNames.push("ActiveCounts");
          arTableNames.push("ReportParameters");
          arTableNames.push("ColumnHeaders");


        } else {

          this.mintReportIdent = modSharedService.enmReports.ClientSummaryMonthly;

          arTableNames.push("PlacementsMTD");
          arTableNames.push("PlacementsYTD");
          arTableNames.push("CollectionsMTD");
          arTableNames.push("CollectionsYTD");
          arTableNames.push("InterestMTD");
          arTableNames.push("InterestYTD");
          arTableNames.push("FeesMTD");
          arTableNames.push("InHouseYTD");
          arTableNames.push("AttorneyYTD");
          arTableNames.push("FeesTotal");
          arTableNames.push("CourtCostsMTD");
          arTableNames.push("CourtCostsYTD");
          arTableNames.push("SuitFeesMTD");
          arTableNames.push("SuitFeesYTD");
          arTableNames.push("TotalLegal");
          arTableNames.push("WriteOffsMTD");
          arTableNames.push("WriteOffsYTD");
          arTableNames.push("ReportParameters");
          arTableNames.push("ColumnHeaders");

        }

        slParameters = [];
        slParameters.push(new ABCDictionary("ClientIdent", this.mfrmForm.controls.ClientIdent.value));
        slParameters.push(new ABCDictionary("ByCalenderPeriod", this.mfrmForm.controls.ReportTypeIdent.value == 2));

        if (this.mfrmForm.controls.DateParamIdent.value == 1) {

          // Yesterday
          dtStartDate = new Date((dtStartDate.getMonth() + 1) + "/" + (dtStartDate.getDate() - 1) + "/" + dtStartDate.getFullYear());

          slParameters.push(new ABCDictionary("RunDate", dtStartDate));
          slParameters.push(new ABCDictionary("AsOfYesterday", true));

        } else {

          // End of Month
          slParameters.push(new ABCDictionary("RunDate", this.mfrmForm.controls.DateParamValue.value));
          slParameters.push(new ABCDictionary("AsOfYesterday", false));

        }

        strReportName = "Client Summary"

        this.svcReporting.GenerateReport(intReportIdent, intReportFormatIdent, strFilename,
          strLogoPath, intStoredProcedureTypeIdent,
          intReportReportFormatXRefIdent, arTableNames,
          slParameters, strReportName, 1)
          .subscribe(

            (rspResponse: ApiResponse) => {

              // Nothing to do here, it's a report call.

            });
      }

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  PopulateDateDropdown() {

    let datCurrentDate: Date = new Date();
    let strDateString: string = "";

    try {

      for (let intCounter = 1; intCounter <= 13; intCounter++) {

        datCurrentDate = new Date(datCurrentDate.setMonth(datCurrentDate.getMonth() - 1));

        strDateString = datCurrentDate.toLocaleString('default', { month: 'long' }) + " " + datCurrentDate.getFullYear();

        this.marDateParamValue.push(
          {
            DateText: strDateString,
            DateValue: datCurrentDate.toLocaleDateString()
          });

        if (intCounter == 1) {

          // Mark this as the selected value.
          this.mfrmForm.controls.DateParamValue.patchValue(datCurrentDate.toLocaleDateString());

        }

      }

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }


  }


  Cancel() {

    this.router.navigateByUrl('/manage/Reports');

  }

}
