import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { MaintenanceService } from '../../../services/maintenance.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { ApiResponse } from '../../../models/api-response.model';

@Component({
  selector: 'left-right-combo-box',
  templateUrl: './left-right-combo-box.component.html',
  styleUrls: ['./left-right-combo-box.component.scss']
})
export class LeftRightComboBoxComponent implements OnInit {

  @Input() leftActive: boolean = true; // if true, the left select box will be considered the active box.
  @Input() leftLabel: string = "Active";
  @Input() rightLabel: string = "Not Active";
  @Input() dataSource = {}; // Data source config

  @Output() leftChanged: EventEmitter<any[]> = new EventEmitter();
  @Output() rightChanged: EventEmitter<any[]> = new EventEmitter();

  // leftValue and rightValue are the actual output values of the field
  public leftValue: any[] = [];
  public rightValue: any[] = [];

  //leftDisplay and rightDisplay are the arrays of what items are actually shown in the boxes (includes filtering)
  public leftDisplay: any[] = [];
  public rightDisplay: any[] = [];

  // leftSelection and rightSelection represent the arrays of the selected items in each box
  public leftSelection: any[] = [];
  public rightSelection: any[] = [];

  // leftFilterModel and rightFilterModel are the filter terms for each box
  public leftFilterModel: string = "";
  public rightFilterModel: string = "";

  public selectedStatesForNewAttorney: any[] = [];

  constructor(
    private svcMaintenanceService: MaintenanceService,
    private svcAuth: AuthenticationService
  ) { }

  ngOnInit() {
    // Get the options to populate the boxes from the database based on the datasource config.
    // Show active items in one, and inactive items in another.
    this.selectedStatesForNewAttorney = [];

    setTimeout(() => {
      this.getOptions();
    }, 1000)
    
  }

  // Update the display arrays to reflect the current actual value arrays, then filter
  private updateDisplayValues() {
    this.leftDisplay = this.leftValue;
    this.rightDisplay = this.rightValue;
    this.leftSelection = [];
    this.rightSelection = [];
    this.leftFilter();
    this.rightFilter();
  }

  // Move the selected items from the left box to the right box
  public moveSelectionRight() {
    if (this.leftActive) {
      this.setSelectionActive(this.leftSelection, false);
    }
    else {
      this.setSelectionActive(this.leftSelection, true);
    }

    this.rightValue = this.rightValue.concat(this.leftSelection);
    this.leftValue = this.leftValue.filter(v => this.leftSelection.indexOf(v) < 0);
    this.updateDisplayValues();
    this.emitOutputValues();
    return false;
  }

  // Move all of the items from the left box to the right box
  public moveAllRight() {
    if (this.leftActive) {
      this.setSelectionActive(this.leftValue, false);
    }
    else {
      this.setSelectionActive(this.leftValue, true);
    }

    this.rightValue = this.rightValue.concat(this.leftValue);
    this.leftValue = [];
    this.updateDisplayValues();
    this.emitOutputValues();
    return false;
  }

   // Move the selected items from the right box to the left box
  public moveSelectionLeft() {
    if (this.leftActive) {
      this.setSelectionActive(this.rightSelection, true);
    }
    else {
      this.setSelectionActive(this.rightSelection, false);
    }

    this.leftValue = this.leftValue.concat(this.rightSelection);
    this.rightValue = this.rightValue.filter(v => this.rightSelection.indexOf(v) < 0);
    this.updateDisplayValues();
    this.emitOutputValues();
    return false;
  }

  // Move all of the items from the right box to the left box
  public moveAllLeft() {
    if (this.leftActive) {
      this.setSelectionActive(this.rightValue, true);
    }
    else {
      this.setSelectionActive(this.rightValue, false);
    }

    this.leftValue = this.leftValue.concat(this.rightValue);
    this.rightValue = [];
    this.updateDisplayValues();
    this.emitOutputValues();
    return false;
  }

  // Build the text to display in the box for an item. Is built via string interpolation to use values from the database columns
  public buildDisplay(item) {
    var display: string = this.dataSource["display"];

    const keys = Object.keys(this.dataSource["bindings"]);
    for (const key of keys) {
      var value = this.dataSource["bindings"][key];
      display = display.replace(key, item[value]);
    }

    return display;
  }

  // Filter the left box based on the entered search term. Then sort alphabetically.
  public leftFilter() {
    var display = [];

    if (this.leftFilterModel == "") {
      display = this.leftValue || [];
    }
    else {
      display = this.leftValue.filter(v => this.buildDisplay(v).toLowerCase().includes(this.leftFilterModel.toLowerCase()));
    }

    this.leftDisplay = display.sort((a, b) => {
      a = this.buildDisplay(a);
      b = this.buildDisplay(b);
      if (a < b) return -1;
      else if (a > b) return 1;
      else if (a == b) return 0;
    });
  }

  // Filter the right box based on the entered search term. Then sort alphabetically.
  public rightFilter() {
    var display = [];

    if (this.rightFilterModel == "") {
      display = this.rightValue || [];
    }
    else {
      display = this.rightValue.filter(v => this.buildDisplay(v).toLowerCase().includes(this.rightFilterModel.toLowerCase()));
    }

    this.rightDisplay = display.sort((a, b) => {
      a = this.buildDisplay(a);
      b = this.buildDisplay(b);
      if (a < b) return -1;
      else if (a > b) return 1;
      else if (a == b) return 0;
    });
  }

  // Emit the current value for each box to the component's outputs
  private emitOutputValues() {
    this.leftChanged.emit(this.leftValue);
    this.rightChanged.emit(this.rightValue);
  }

  // Make an API call to retrieve the options to display in the table.
  private getOptions() {
      if (this.dataSource != null && this.dataSource != undefined) {

        // First get all of the source entities
        return this.svcMaintenanceService.Search(this.dataSource["sourceEntity"], {}).subscribe(
          (rspResponse: ApiResponse) => {
            if (rspResponse.Succeeded) {
              var sourceData: any = rspResponse.Payload[0].Data;

              // Next get all of the xref entities
              var editIdent = this.svcMaintenanceService.GetEditIdent();
              var xrefIdent = this.dataSource["xrefIdents"]["xref"];
              var searchParams = {};
              searchParams[xrefIdent] = editIdent;

              return this.svcMaintenanceService.Search(this.dataSource["xrefEntity"], searchParams).subscribe(
                (xrefRspResponse: ApiResponse) => {

                  var xrefData: any = xrefRspResponse.Payload[0].Data;
                  var options = [];

                  if (editIdent) {
                    options = this.mapXrefToSource(sourceData, xrefData);
                  }
                  else {
                    options = this.mapXrefToSource(sourceData, []);
                  }
                  //return options;
                  if (this.leftActive == true) {
                    this.leftValue = options.filter(i => i["Active"] == true) || [];
                    this.rightValue = options.filter(i => i["Active"] == false) || [];
                  }
                  else {
                    this.rightValue = options.filter(i => i["Active"] == true) || [];
                    this.leftValue = options.filter(i => i["Active"] == false) || [];
                  }

                  this.updateDisplayValues();
                  this.emitOutputValues(); // Emit right away so the control's value gets updated with the values from the database
                });
            }
            else {
              //return [];
              if (this.leftActive == true) {
                this.leftValue = [];
                this.rightValue = [];
              }
              else {
                this.rightValue = [];
                this.leftValue = [];
              }

              this.updateDisplayValues();
              this.emitOutputValues(); // Emit right away so the control's value gets updated with the values from the database
            }
          });
      }
      else {
        //return [];
        if (this.leftActive == true) {
          this.leftValue = [];
          this.rightValue = [];
        }
        else {
          this.rightValue = [];
          this.leftValue = [];
        }

        this.updateDisplayValues();
        this.emitOutputValues(); // Emit right away so the control's value gets updated with the values from the database
      }
  }

  private mapXrefToSource(sourceData, xrefData): any[] {
    var sourceIdent = this.dataSource["xrefIdents"]["source"];
    var xrefIdent = this.dataSource["xrefIdents"]["xref"];

    sourceData.forEach(sourceObj => {
      var xrefObj = xrefData.find(x => x[sourceIdent] == sourceObj["Ident"]);

      if (xrefObj) {
        sourceObj["Active"] = xrefObj["Active"];
        sourceObj[sourceIdent] = xrefObj[sourceIdent];
        sourceObj[xrefIdent] = xrefObj[xrefIdent];
        sourceObj["Ident"] = xrefObj["Ident"];
        sourceObj["New"] = false;
      }
      else {
        sourceObj["Active"] = false;
        sourceObj[sourceIdent] = sourceObj["Ident"];
        sourceObj[xrefIdent] = this.svcMaintenanceService.GetEditIdent();
        sourceObj["New"] = true;
        delete sourceObj["Ident"];
      }
    });

    return sourceData;
  }

  // Update the "Active" field for all items in the given selection to the given boolean
  private setSelectionActive(options, active) {
    options.forEach(option => {
      option["Active"] = active;
      this.updateOption(option);
    });
  }

  // Make an API call to upsert the values of the given item in the database.
  private updateOption(option) {
    // Update audit details
    if (!this.svcMaintenanceService.GetEditIdent())
    {
      option["AddABCUserIdent"] = this.svcAuth.GetUserIdent() || 0;
      option["AddDateTime"] = new Date().toISOString();

      this.selectedStatesForNewAttorney.push(option);
      this.svcMaintenanceService.SetSelectedStatesForNewAttorney(this.selectedStatesForNewAttorney);
    }
    else
    {
      if (option['New'])
      {
        option["AddABCUserIdent"] = this.svcAuth.GetUserIdent() || 0;
        option["AddDateTime"] = new Date().toISOString();
      }
      else
      {
        option["EditABCUserIdent"] = this.svcAuth.GetUserIdent() || 0;
        option["EditDateTime"] = new Date().toISOString();
      }

      return this.svcMaintenanceService.Upsert(this.dataSource["xrefEntity"], option).subscribe(
        (rspResponse: ApiResponse) => {
          if (rspResponse.Succeeded) {
            return true;
          }
        });
    }
  }
}
