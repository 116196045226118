import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter, ViewChild, ViewContainerRef, ElementRef, QueryList, ViewChildren, ApplicationRef, ChangeDetectionStrategy, OnChanges, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { modSharedService } from '../../shared/services/modShared.service';
import { ABCMessage } from '../../shared/models/abc-message.model';
import { UntypedFormGroup } from '@angular/forms';
import { UntypedFormControl } from '@angular/forms';
import { FormControlBase } from '../../shared/dynamic-controls/form-controls/form-control-base';
import { Validators, NgForm } from '@angular/forms';
import { MaintenanceService } from '../../shared/services/maintenance.service';
import { ApiResponse } from '../../shared/models/api-response.model';
import { FormDataService } from '../../shared/services/formdata.service';
import { FormControlService } from '../../shared/dynamic-controls/form-controls/form-control.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { GlobalVars } from './../../globalvars';
import { Subject } from 'rxjs';
import { TableBuilderComponent } from '../../core/dynamic-form-components/table-builder/table-builder.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '../../shared/services/authentication.service';

@Component({
  selector: 'base-form',
  templateUrl: './base-form.component.html',
  styleUrls: ['./base-form.component.scss'],
  providers: [FormDataService],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class BaseFormComponent implements OnInit, OnChanges {

  @Input() entity: string;
  @Input() parent: string;
  @Input() ident: number;
  @Input() pIdent: number;
  @Input() entityFullName: string;
  saveSumbitEvent: Subject<boolean> = new Subject<boolean>();
  frmMaintenance: UntypedFormGroup;
  rows: any[] = [];
  showTable: boolean = false;
  unassignedClaims: boolean = false;
  settings: any;
  searchValues: any;
  childForms: any;
  dataColumns: any[] = [];
  tabsColumns: any[] = [];
  searchColumns: any[] = [];
  uniqueColumns: any[] = [];
  anySearchables: any[] = [];
  anyUniques: any[] = [];
  payLoad: any;
  gridPageSize: number = 1;
  gridDraggable: boolean = false;
  currentScreenMode: number = 0;
  rowsCount: number = 0;
  private _searchParameters: any;
  private _entityName: string = "";
  editRowIdent: number = 0;
  private _pIdent: number = 0;
  menmScreenModes = {
    Search: 0,
    Edit: 1,
    Add: 2
  }

  @ViewChild('appTableBuilder', { static: false }) appTableBuilder: TableBuilderComponent;

  ScreenMode: number = this.menmScreenModes.Search;
  mintRecordIdent: number = 0;
  mintCurrentRecordIndex: number = 0;

  constructor(private modShared: modSharedService, private svcMaintenanceService: MaintenanceService, public formData: FormDataService, private vcRef: ViewContainerRef, private globalVars: GlobalVars, private router: Router, private cdr: ChangeDetectorRef, private modalService: NgbModal, private svcAuth: AuthenticationService) { }

  ngOnInit() {

    if(location.href.includes(';'))
      this.entityFullName = location.href.split(';')[1].split('=')[1].replace(/([A-Z])/g, ' $1').trim();

    setTimeout(() => {


      this.searchColumns = [];
      this.uniqueColumns = [];
      this.dataColumns = [];
      this.initChildComponent();

      if (this._entityName) {
        this.svcMaintenanceService.GetAll(this._entityName)
          .subscribe(
            (rspResponse: ApiResponse) => {

              if (rspResponse.Succeeded) {

                this.rows = this.modShared.ExtractDataFromPayload(rspResponse, "MaintenanceGetAll");
                this.rowsCount = this.rows.length;

              }

            });
      }

    }, 500)

  }

  initChildComponent(entity?) {

    this.modShared.MessagesCleared.next();

    this.searchValues = {};

    var targetEntity = entity ? entity : this.entity;

    if (targetEntity == null) {
      targetEntity = "";
    }

    if (this.entity == "AttorneyContact") {

      let arResults: any;

      if (this.parent !== null) {

        this.svcMaintenanceService.Get(this.parent, this.pIdent)
          .subscribe(
            (rspResponse: ApiResponse) => {
              arResults = this.modShared.ExtractDataFromPayload(rspResponse, "MaintenanceGet");
              (<HTMLLabelElement>document.getElementById("attorneyFirmName")).innerHTML = "Firm Name: " + arResults.Name1;
            });
      }

    }

    if (this.entity == "VendorContact") {

      let arResults: any;

      if (this.parent !== null) {
        this.svcMaintenanceService.Get(this.parent, this.pIdent)
          .subscribe(
            (rspResponse: ApiResponse) => {
              arResults = this.modShared.ExtractDataFromPayload(rspResponse, "MaintenanceGet");
              (<HTMLLabelElement>document.getElementById("vendorName")).innerHTML = "Vendor Name: " + arResults.Name1;
              this.GetChildRows();
            });
      }
    }

    try {
      //If the entity comes in as a null value, quit because nothing else will work
      if (!targetEntity) return;

      this._entityName = targetEntity;
      this._pIdent = this.pIdent

      this.formData.getJSON(this._entityName).subscribe(data => {
        this.settings = data[0].settings;
        this.SetSettings(this.settings);
        if (data[1].form !== undefined)
          this.tabsColumns = data[1].form.tabs;

        //Check for any tabs in this form
        if (this.tabsColumns === undefined || this.tabsColumns.length == 0) {

          this.tabsColumns = [];

          //There are no tabs in this form, gather the search and unique columns
          this.searchColumns = data[1].form.filter(x => x.searchable);
          this.uniqueColumns = data[1].form.filter(x => x.unique);
          this.dataColumns = data[1].form;

        } else {

          //Tabs exists, parse through each tab's controls to gather search, unique, and dataColumsn columns
          this.tabsColumns.forEach((tab) => {

            //Gather the searchable controls
            this.anySearchables = (tab.controls.filter(x => x.searchable));

            if (this.anySearchables != undefined && this.anySearchables.length > 0) {

              this.anySearchables.forEach((searhableControl) => {
                this.searchColumns.push(searhableControl);
              });

            }

            //Gather the unique controls
            this.anyUniques = (tab.controls.filter(x => x.unique));

            if (this.anyUniques != undefined && this.anyUniques.length > 0) {

              this.anyUniques.forEach((uniqueControl) => {
                this.uniqueColumns.push(uniqueControl);
              });

            }

            //Sort
            tab.controls = this.SortArray(tab.controls);

            //Gather the data columns
            tab.controls.forEach((control) => {

              this.dataColumns.push(control);

            });

          });

        }

        //Sorting
        this.tabsColumns = this.SortArray(this.tabsColumns);
        this.searchColumns = this.SortArray(this.searchColumns);
        this.uniqueColumns = this.SortArray(this.uniqueColumns);
        this.dataColumns = this.SortArray(this.dataColumns);

        if (data.length > 2) {

          this.childForms = data[2].children;

        }
        else {
          this.childForms = [];
        }

        //Need to wait for the JSON to be parsed before resetting the child component
        setTimeout(() => {
          this.cdr.markForCheck();
        }, 500)

      });

      this.frmMaintenance = new UntypedFormGroup({

        'SearchActiveOnly': new UntypedFormControl(true, [])

      });

      setTimeout(() => {
        if (this.ident) {
          this.EditRecord(this.ident)
        }
      }, 500)

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }
  }

  ngOnChanges(e) {
    this.searchColumns = [];
    this.uniqueColumns = [];
    this.dataColumns = [];
    this.initChildComponent(e.currentValue);
  }

  SetSettings(settings: any) {

    try {

      this.gridPageSize = (!!settings.pageSize) ? settings.pageSize : this.globalVars.pageSize;
      this.gridDraggable = (!!settings.draggable) ? settings.draggable : this.globalVars.draggable;

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  SetSearchValues(payload: any) {

    try {

      this.searchValues = payload;

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  Search(searchValues) {
    var self = this;
    var payload = searchValues === undefined ? JSON.parse('{}') : searchValues;
    
    try {

      // Check for existence of a Parent record which must be passed back to
      // the dynamic back-end in order to filter child rows properly
      let pIdent: string = '';
      this.parent == null ? pIdent = null : pIdent = this.parent + "Ident";

      if (this.pIdent != null && this.parent != null) {

        payload[pIdent] = this.pIdent;

      }

      payload.Active = this.frmMaintenance && this.frmMaintenance.controls.SearchActiveOnly.value == true ? true : null;

      if (this._entityName && this._entityName != "") {
        this.svcMaintenanceService.Search(this._entityName, payload)
          .subscribe(
            (rspResponse: ApiResponse) => {

              self.showTable = true;
              if (rspResponse.Succeeded) {

                self.rows = this.modShared.ExtractDataFromPayload(rspResponse, "MaintenanceSearch");
              }
              else {
                self.rows = [];
              }

              if (this.pIdent == null && this.parent == null) {
                self.cdr.detectChanges();
              }
            });
    }

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  EditEnabled(): boolean {

    try {

      return this.ScreenMode === this.menmScreenModes.Edit;

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  AddEnabled(): boolean {

    try {

      return this.ScreenMode === this.menmScreenModes.Add;

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  Cancel() {

    let bolContinue: boolean = true;

    try {

      if (this.ScreenMode == this.menmScreenModes.Edit) {

        if (this.frmMaintenance.dirty) {

          // They made changes, make sure they want to exit
          bolContinue = confirm('You have made changes to this record, are you sure you want to cancel? Click OK to leave this record.');

        }

        if (bolContinue) {

          this.ClearControls();

          this.editRowIdent = 0;
          this.ident = null;

        }

      } else {

        this.ClearControls();

        this.editRowIdent = 0;
        this.ident = null;

      }

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  GlobalSave(saveContinue: boolean) {

    this.saveSumbitEvent.next(saveContinue);
    setTimeout(() => { this.Reset() }, 500);


  }

  DetermineCancelClick() {

    if (this.parent == null || this.parent == undefined) {

      this.Cancel();

    } else {

      if (this.ScreenMode == this.menmScreenModes.Search) {

        this.NavigateToParent(this.parent);

      } else {

        this.Cancel();

      }
    }
  }

  Reset() {

    try {

      this.searchColumns.forEach(x => {

        if (x.controlType === "textbox") {

          x.value = "";

        }
        else if (x.controlType === "textarea") {

          x.value = "";

        }
        else if (x.controlType === "checkbox") {

          x.value = true;

        }
        else if (x.controlType === "select") {

          x.value = "";

        } else if (x.controlType === "xref-combo") {

          x.value = [];

        }

      });

      this.tabsColumns.forEach(x => {

        x.controls.forEach(c => {

          if (c.controlType === "textbox") {

            c.value = "";

          } else if (c.controlType === "checkbox") {

            c.value = true;

          } else if (c.controlType === "select") {

            c.value = "";

          }

        });

      });

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  ClearControls() {

    let bolSearchActiveOnly: boolean;


    try {

      bolSearchActiveOnly = this.frmMaintenance && this.frmMaintenance.controls?this.frmMaintenance.controls.SearchActiveOnly.value:undefined;
      this.Reset();

      if (this.frmMaintenance) {
        this.frmMaintenance.reset();
      }

      this.mintRecordIdent = 0;
      this.mintCurrentRecordIndex = 0;

      // Reset initial values
      if (this.frmMaintenance) {

        this.frmMaintenance.setValue({

          SearchActiveOnly: bolSearchActiveOnly

        });

      }

      //refresh data
      if (this.pIdent == null || this.pIdent == 0) {
        
        this.Search(this.searchValues);

      } else {

        this.GetChildRows();

      }

      //Reset view to search and grid
      this.ScreenMode = this.menmScreenModes.Search;
      this.cdr.detectChanges();

      //call reset of form values in table builder
      if (this.appTableBuilder != undefined) {

        this.appTableBuilder.Reset();

      }

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }
  }

  GetAll() {

    try {

      if (this._entityName) {
        this.svcMaintenanceService.GetAll(this._entityName)
          .subscribe(
            (rspResponse: ApiResponse) => {

              if (rspResponse.Succeeded) {

                this.rows = this.modShared.ExtractDataFromPayload(rspResponse, "MaintenanceGetAll");

              }

            });
      }

      this.dataColumns.forEach(x => {

        if (x.controlType === "select") {

          if (x.datasource.proc === "uspGetAllActiveABCUsersForStationAssignment") {

            this.svcMaintenanceService.GetABCUsersForStation().subscribe((rspResponse: ApiResponse) => {

              if (rspResponse.Succeeded) {

                var resultOptions: any = rspResponse.Payload[0].Data;

                resultOptions.forEach(y => {

                  x.options.push({
                    "key": y[x.datasource.key], "value": y[x.datasource.value]
                  });

                });

              }

            });

          }

        }

      });

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  GetChildRows() {

    var payload = JSON.parse('{}');
    let pIdent: string = this.parent + "Ident";

    try {

      if (this.pIdent != null && this.parent != null) {

        payload[pIdent] = this.pIdent;
        this.Search(payload);
      }

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  AddRecord() {

    this.editRowIdent = 0;
    this.ScreenMode = this.menmScreenModes.Edit;
    this.svcMaintenanceService.SetEditIdent(undefined);
    this.cdr.detectChanges();

    try {

      this.dataColumns.forEach(x => {
        
        if (x.controlType === "textbox") {

          x.value = "";

        } else if (x.controlType === "checkbox") {

          x.value = true;

        } else if (x.controlType === "select") {

          if (!x.options.filter(x => { return x.value == "Please Select" })) {
            x.options.unshift({

              "key": "", "value": "Please Select"

            });
            this.cdr.detectChanges();
          }

          if (x.datasource.proc === "uspGetAllActiveABCUsersForStationAssignment") {

            this.svcMaintenanceService.GetABCUsersForStation().subscribe((rspResponse: ApiResponse) => {

              if (rspResponse.Succeeded) {

                var resultOptions: any = rspResponse.Payload[0].Data;

                resultOptions.forEach(y => {

                  if (x.options && !x.options.filter(z => { return z.key == y[x.datasource.key] && z.value == y[x.datasource.value] })) {
                    x.options.push({
                      "key": y[x.datasource.key], "value": y[x.datasource.value]
                    });
                  }

                });
                this.cdr.detectChanges();

              }

            });

          }

          x.value = "";

        } else if (x.controlType === "xref-combo") {

          x.value = [];

        }

      });

      this.tabsColumns.forEach(x => {

        x.controls.forEach(c => {

          if (c.controlType === "textbox") {

            c.value = "";

          } else if (c.controlType === "textarea") {

            c.value = "";

          } else if (c.controlType === "checkbox") {

            c.value = true;

          } else if (c.controlType === "select") {

            c.value = "";

          }

        });

      });

      this.ScreenMode = this.menmScreenModes.Edit;
      this.editRowIdent = 0;
      this.cdr.detectChanges();

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

    //this.ScreenMode = this.menmScreenModes.Add;

  }

  display(payload) {

    try {

      if (this.modShared.ValidateForm(payload.form, this.dataColumns)) {

        this.payLoad = JSON.stringify(payload.form.getRawValue()); // gives form value along with those fields that have been disabled.
        this.SaveRecord(payload.saveAndContinue);

      }

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  EditRecord(itmIdent: number) {

    this.editRowIdent = itmIdent;
    let arResults: any;
    this.mintRecordIdent = itmIdent;
    let en = this._entityName;

    try {

      this.svcMaintenanceService.Get(en, this.mintRecordIdent)
        .subscribe(
          (rspResponse: ApiResponse) => {

            arResults = this.modShared.ExtractDataFromPayload(rspResponse, "MaintenanceGet");

            if (arResults.IsClaimed) {
              this.unassignedClaims = arResults.IsClaimed;
            }
            else {
              this.unassignedClaims = false;
            }

            this.dataColumns.forEach(x => {

              Object.keys(arResults).forEach(key => {

                if (key.toLowerCase() === x.key.toLowerCase()) {

                  x.value = Object.values(arResults)[Object.keys(arResults).indexOf(key)];

                }

              });

              if (x.controlType === "select") {

                if (x.datasource.proc === "uspGetAllActiveABCUsersForStationAssignment") {

                  this.svcMaintenanceService.GetABCUsersForStation().subscribe((rspResponse: ApiResponse) => {

                    if (rspResponse.Succeeded) {

                      var resultOptions: any = rspResponse.Payload[0].Data;

                      resultOptions.forEach(y => {

                        x.options.push({
                          "key": y[x.datasource.key], "value": y[x.datasource.value]
                        });

                      });

                    }

                  });

                }

              }

            });

            this.ScreenMode = this.menmScreenModes.Edit;
            this.cdr.detectChanges();

          });

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  AddNewRecord(bolCloseAfterSave, record) {

    record.Ident = "";
    this.svcMaintenanceService.Add(this._entityName, record)
      .subscribe((rspResponse: ApiResponse) => {

        if (rspResponse.Succeeded) {

          this.editRowIdent = rspResponse.Payload[0].Data.Ident;

          if (this._entityName == "Attorney") {
            this.updateAttorneyOptions(this.editRowIdent);
          }

          if (this._entityName == "Station") {
            this.svcMaintenanceService.UpdateStationHistory(this.editRowIdent).subscribe();
          }

          if (bolCloseAfterSave) {

            this.ClearControls();
            this.Reset();
            this.ScreenMode = this.menmScreenModes.Search;
            this.cdr.detectChanges();

          }

        }

      });

  }

  SaveRecord(bolCloseAfterSave: boolean) {

    var payload = JSON.parse(this.payLoad);
    let pIdent: string = this.parent + "Ident";
    let record = JSON.parse(this.payLoad);

    if (this.ScreenMode != this.menmScreenModes.Edit && this.entity == "AttorneyContact") {
      payload.Address1 = (<HTMLInputElement>document.getElementById("Address1")).value;
      payload.Address2 = (<HTMLInputElement>document.getElementById("Address2")).value;
      payload.Address3 = (<HTMLInputElement>document.getElementById("Address3")).value;
      payload.City = (<HTMLInputElement>document.getElementById("City")).value;
      payload.Zip = (<HTMLInputElement>document.getElementById("Zip")).value;
      payload.Phone = (<HTMLInputElement>document.getElementById("Phone")).value;
      payload.Fax = (<HTMLInputElement>document.getElementById("Fax")).value;
      payload.Email = (<HTMLInputElement>document.getElementById("Email")).value;
    }

    if (this._entityName == "LawList") {
      payload.DistributionPercentage = 0;
      record.DistributionPercentage = 0;
    }

    if (this.entity == "VendorContact" || this.entity == "AttorneyContact" || this.entity == "LawListContact") {

      var firstName = record.FirstName;
      var lastName = record.LastName;
      record.Name1 = (firstName + '' + lastName);

    }

    Object.keys(record).forEach(x => {
      let selectTypeEl = this.dataColumns.filter(y => { return y.key == x && y.controlType == "select" });
      if (selectTypeEl && selectTypeEl.length && record[x] == "") {
        record[x] = 0;
      }
    });

    try {

      if (record.RemitToCountryIdent == "" || record.RemitToCountryIdent == null) {
        record.RemitToCountryIdent = 0;
      }
      if (record.RemitToStateIdent == "" || record.RemitToStateIdent == null) {
        record.RemitToStateIdent = 0;
      }

      if (this.pIdent != null && this.parent != null) {

        payload[pIdent] = this.pIdent;
        this.payLoad = JSON.stringify(payload);
        record = JSON.parse(this.payLoad);

      }

      this.uniqueColumns.forEach(x => {

        Object.keys(record).forEach(function (key) {

          if (x.key === key) {

            var newkey = "unique" + key;
            record[newkey] = record[key];
            var newUniqueKeyLabel = "uniqueLabel" + key;
            record[newUniqueKeyLabel] = x.label;
            delete record[key];

          }

        });

      });

      if (this.editRowIdent === 0) {

        if (this._entityName) {
          this.svcMaintenanceService.GetAll(this._entityName)
            .subscribe(
              (rspResponse: ApiResponse) => {

                if (rspResponse.Succeeded) {

                  this.rows = this.modShared.ExtractDataFromPayload(rspResponse, "MaintenanceGetAll");
                  this.rowsCount = this.rows.length;

                  if (record.Rank == "" || record.Rank == null) {
                    record.Rank = this.rowsCount + 1;
                  }

                  this.AddNewRecord(bolCloseAfterSave, record);

                }

              });
        }

      } else {
        
        record.Ident = this.editRowIdent;

        if (this.entity == "Attorney") {
          this.svcMaintenanceService.UpdateStagingInvoicesAndDisbursement(record.Ident)
            .subscribe(
            );
        }

        if (this._entityName == "Station") {
          this.svcMaintenanceService.UpdateStationHistory(record.Ident).subscribe();
        }

        if (record.Rank != 0 && record.Active == false) {

          this.rows.forEach(element => {
            if (element.Rank > record.Rank) {
              element.Rank--;
              this.UpdateRow(element, bolCloseAfterSave);
            }
          });

          record.Rank = 0;

          if (this.entity == "VendorContact") {
            this.UpdateRankByVendorContactIdent(record.Ident, 0, this.svcAuth.GetUserIdent(), bolCloseAfterSave);            
          }

        }
        else if (record.Rank == 0 && record.Active == true) {
          record.Rank = this.rows.length;

          if (this.entity == "VendorContact") {
            this.UpdateRankByVendorContactIdent(record.Ident, record.Rank, this.svcAuth.GetUserIdent(), bolCloseAfterSave);
          }

        }

        this.UpdateRow(record, bolCloseAfterSave);

      }

    } catch (ex) {

      this.modShared.LogClientError(ex)

    }

  }

  UpdateRow(currentRecord, bolCloseAfterSave) {

    this.svcMaintenanceService.Update(this._entityName, currentRecord)
      .subscribe((rspResponse: ApiResponse) => {

        if (rspResponse.Succeeded) {

          if (bolCloseAfterSave) {

            this.ClearControls();
            this.Reset();
            this.ScreenMode = this.menmScreenModes.Search;
            this.cdr.detectChanges();

          }
          else {
            this.ScreenMode = this.menmScreenModes.Edit;
          }

        }

      });
  }

  UpdateRankByVendorContactIdent(RecordIdent, sortOrder, userIdent, bolCloseAfterSave) {

    try {

      this.svcMaintenanceService.UpdateRankByVendorContactIdent(RecordIdent, sortOrder, userIdent).subscribe((rspResponse: ApiResponse) => {

          if (rspResponse.Succeeded) {

            if (bolCloseAfterSave) {

              this.ClearControls();
              this.Reset();
              this.ScreenMode = this.menmScreenModes.Search;
              this.cdr.detectChanges();

            }
            else {
              this.ScreenMode = this.menmScreenModes.Edit;
              this.cdr.detectChanges();
            }

          }

      });

    } catch (ex) {

      this.modShared.LogClientError(ex)

    }

  }

  NavigateToChild(entity: string) {

    try {

        setTimeout(() => {
          
          this.router.navigate(['/system/referenceTables', { entity: entity, parent: this.entity, pIdent: this.editRowIdent }]);

        }, 500);

    } catch (ex) {

      this.modShared.LogClientError(ex)

    }

  }

  NavigateToParent(entity: string) {

    try {

      if (this.editRowIdent !== 0 && (this.pIdent === null || this.pIdent === 0)) {

        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigate(['/system/referenceTables', { entity: entity, ident: this.editRowIdent }]));

      } else {

        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigate(['/system/referenceTables', { entity: entity, ident: this.pIdent }]));

      }

    } catch (ex) {

      this.modShared.LogClientError(ex)

    }

  }

  SortArray(array: any) {

    return array.sort((n1, n2) => {

      if (n1.order > n2.order) { return 1; }
      if (n1.order < n2.order) { return -1; }
      return 0;

    });

  }

  PopulateAttorneyContact() {

    let arResults: any;

    this.svcMaintenanceService.Get(this.parent, this.pIdent)
      .subscribe(
        (rspResponse: ApiResponse) => {

          arResults = this.modShared.ExtractDataFromPayload(rspResponse, "MaintenanceGet");

          this.dataColumns.forEach(element => {

            if (element.key == "Address1" || element.key == "Address2" || element.key == "Address3" || element.key == "City" || element.key == "State" || element.key == "Country" || element.key == "Zip" || element.key == "Phone" || element.key == "Fax" || element.key == "Eamil") {

              let currentKey = element.key;

              element.value = arResults[currentKey];

            }
            else {
              element.value = element.value;
            }

          });

        });

  }

  UnassignClaims() {

    try {

      var stationIdent = this.editRowIdent;

      this.svcMaintenanceService.UnassignClaims(stationIdent)
        .subscribe(x => {
          this.unassignedClaims = true;
          this.cdr.detectChanges();
        });

    } catch (ex) {

      this.modShared.LogClientError(ex)

    }

  }

   // Make an API call to upsert the values of the given item in the database.
  private updateAttorneyOptions(attorneyId) {

    let aOptions = this.svcMaintenanceService.GetSelectedStatesForNewAttorney();

    try {

      aOptions.forEach(option => {

        option["AddABCUserIdent"] = this.svcAuth.GetUserIdent() || 0;
        option["AddDateTime"] = new Date().toISOString();
        option['AttorneyIdent'] = attorneyId;

        return this.svcMaintenanceService.Upsert('AttorneyStateXref', option).subscribe(
          (rspResponse: ApiResponse) => {
            if (rspResponse.Succeeded) {
              return true;
            }
          });

      });

    } catch (ex) {

      this.modShared.LogClientError(ex)

    }

  }

}
