import { Injectable } from "@angular/core";
import { modSharedService } from "./modShared.service";

@Injectable()
export class ManageUsersService {

  mintRecordIdent: number = 0; 

  constructor(private modShared: modSharedService) { }

  ResetPassword(strCurrentPassword: string, strNewPassword: string, strConfirmNewPassword: string) {

    try {


      // Update the user's password
      return this.modShared.CreateHttpPostRequest('/ManageUsers/ResetPassword', {
        CurrentPassword: strCurrentPassword,
        NewPassword: strNewPassword,
        ConfirmNewPassword: strConfirmNewPassword
      });


    } catch (ex) {

      this.modShared.LogClientError(ex);
      
    }

  }


  GetPasswordRequirements() {

    try {

      // Get the password requirements from the server
      return this.modShared.CreateHttpPostRequest('/ManageUsers/GetPasswordRequirements', {});

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }


  SendResetLink(intABCUserIdent: number, bolForNewUser: boolean) {

    try {

      // Search the ABC User Type table
      return this.modShared.CreateHttpPostRequest('/ManageUsers/SendResetLink', {

        ABCUserIdent: intABCUserIdent,
        ForNewUser: bolForNewUser

      });

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  GetABCUserRoles(intABCUserIdent: number) {

    try {

      // Search the ABC User Type table
      return this.modShared.CreateHttpPostRequest('/ManageUsers/GetABCUserRoles', {

        ABCUserIdent: intABCUserIdent

      });

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  GetWebUserDetails() {

    try {

      // Call the Services API
      return this.modShared.CreateHttpPostRequest('/ManageUsers/GetWebUserDetails', {

      });

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }



}
