import { Component, OnInit } from '@angular/core';
import { modSharedService } from '../../shared/services/modShared.service';
import { ActivatedRoute } from '@angular/router';
import { ReportingService } from '../../shared/services/reporting.service';
import { ApiResponse } from '../../shared/models/api-response.model';

@Component({
  selector: 'app-report-viewer',
  templateUrl: './report-viewer.component.html',
  styleUrls: ['./report-viewer.component.scss']
})
export class ReportViewerComponent implements OnInit {

  mstrData: string = "<strong>whee!</strong>";
  mstrReportRawXML: string = "";
  mstrExcelXSLTFile: string = "";
  mstrPDFXSLTFile: string = "";
  mstrReportFileName: string = "";
  mstrPDFType: string = "1";
  mstrLogoPath: string = ""; 

  constructor(private rtRoute: ActivatedRoute,
              private modShared: modSharedService,
              private svcReporting: ReportingService) { }

  ngOnInit() {

    try {

      this.rtRoute.queryParams.subscribe(queryParams => {

        // This is because if they click "run report" without closing the window Angular
        // does not refresh
        this.mstrData = localStorage.getItem("ReportData");
        this.mstrReportRawXML = localStorage.getItem("ReportRawXML");
        this.mstrExcelXSLTFile = localStorage.getItem("ExcelXSLTFile");
        this.mstrPDFXSLTFile = localStorage.getItem("PDFXSLTFile");
        this.mstrReportFileName = localStorage.getItem("ReportFileName");
        this.mstrPDFType = localStorage.getItem("PDFType");
        this.mstrLogoPath = localStorage.getItem("LogoPath");


      });

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  ExportReportToExcel() {

    try {


      this.svcReporting.GenerateRawReport(modSharedService.enmReportFormat.Excel, this.mstrReportRawXML, this.mstrExcelXSLTFile, this.mstrReportFileName, parseInt(this.mstrPDFType), this.mstrLogoPath)
        .subscribe(

          (rspResponse: ApiResponse) => {

            // Nothing to do here, just a report call.

          });


    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  ExportReportToPDF() {

    try {


      this.svcReporting.GenerateRawReport(modSharedService.enmReportFormat.PDF, this.mstrReportRawXML, this.mstrPDFXSLTFile, this.mstrReportFileName, parseInt(this.mstrPDFType), this.mstrLogoPath)
        .subscribe(

          (rspResponse: ApiResponse) => {

            if (rspResponse.Succeeded) {

              // Successful.


            } // Succeeded

          });


    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }


}
