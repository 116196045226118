import { Injectable } from "@angular/core";

import { modSharedService } from "./modShared.service";
import { ABCDictionary } from "../models/abc.dictionary.model";

@Injectable()
export class AuthenticationService {

  private userIdent: number;

  constructor(private modShared: modSharedService) { }


  AuthenticateByPaymentPortalGUID(strLinkGuid: string) {

    try {

      // Call the Services API
      return this.modShared.CreateHttpPostRequest('/Authentication/AuthenticateByPaymentPortalGUID', {

        LinkGuid: strLinkGuid

      });

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  AuthenticateByPaymentPortalDetails(strCaptchaResponse: string, intClaimIdent: number, strAddress1: string,
                                      strCity: string, intCountryIdent: number, intStateIdent: number, strZip: string) {

    try {

      // Call the Services API
      return this.modShared.CreateHttpPostRequest('/Authentication/AuthenticateByPaymentPortalDetails', {

        CaptchaResponse: strCaptchaResponse,
        ClaimIdent: intClaimIdent,
        Address1: strAddress1,
        City: strCity,
        CountryIdent: intCountryIdent,
        StateIdent: intStateIdent,
        Zip: strZip

      });

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }



  LoginUser(strUsername: string, strPassword: string) {

    try {

      // Attempt to log into the system
      return this.modShared.CreateHttpPostRequest('/Authentication/Login', { username: strUsername, password: strPassword });


    } catch (ex) {

      this.modShared.LogClientError(ex);

    } 


  }

  // TODO: Clean up this service, it was older so we don't have code standards in place.
  SetUserIdent(ident: number) {
    this.userIdent = ident;
    localStorage.setItem("UserIdent", this.userIdent.toString());
  }

  GetUserIdent() {
    if (this.userIdent == undefined) {
      return Number(localStorage.getItem("UserIdent"));
    }
    else {
      return this.userIdent;
    }
  }

  GetPasswordRequirements() {

    try {

      // Get the password requirements from the server
      return this.modShared.CreateHttpPostRequest('/Authentication/GetPasswordRequirements', {});

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }
  
  VerifyABCUserLink(strGUID: string) {

    try {

      // Search the ABC User Type table
      return this.modShared.CreateHttpPostRequest('/Authentication/VerifyABCUserLink', {

        SecureGUID: strGUID

      });

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }
    
  }


  ResetPassword(strGUID: string, strNewPassword: string, strConfirmNewPassword: string) {

    try {


      // Update the user's password
      return this.modShared.CreateHttpPostRequest('/Authentication/ResetPassword', {
        SecureGUID: strGUID,
        NewPassword: strNewPassword,
        ConfirmNewPassword: strConfirmNewPassword
      });


    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

    }

  ForgotPassword(strUsername: string, strEmailAddress: string) {

      try {

          //User forgot their password
          return this.modShared.CreateHttpPostRequest('/Authentication/ForgotPassword', {
              Username: strUsername,
              EmailAddress: strEmailAddress
          });


      } catch (ex) {

          this.modShared.LogClientError(ex);

      }

  }


}
