import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { modSharedService } from '../../services/modShared.service';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl, RequiredValidator } from '@angular/forms';
import { ABCMessage } from '../../models/abc-message.model';

@Component({
  selector: 'app-printer-output',
  templateUrl: './printer-output.component.html',
  styleUrls: ['./printer-output.component.scss']
})
export class PrinterOutputComponent implements OnInit {

  mfrmForm: UntypedFormGroup;

  // Properties
  marSelectedPrinter: any[] = [];

  // Result object
  mobjResult: any = {
    'OutputToPrinter': false,
    'OutputToFile': false,
    'OutputItems': false,
    'OutputPath': "",
    'OutputNotes': "",
    'CorrespondencePrinterIdent': 0
  }

  constructor(public activeModal: NgbActiveModal,
    private modShared: modSharedService) { }

  Cancel() {

    try {

      // Dismiss the popup, mobjResult returns any work they did. 
      this.activeModal.close(this.mobjResult);

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  OutputToFile() {

    try {

      this.modShared.MessagesCleared.next();

      this.mobjResult.OutputItems = true;
      this.mobjResult.OutputPath = "";
      this.mobjResult.OutputNotes = "";
      this.mobjResult.OutputToPrinter = false;
      this.mobjResult.OutputToFile = true;
      this.mobjResult.CorrespondencePrinterIdent = 0;

      this.Cancel();


    } catch (ex) {

      this.modShared.LogClientError(ex);

    }


  }

  PrinterSelected() {

    try {

      this.modShared.MessagesCleared.next();

      // TODO: Verify they selected a printer.
      if (this.marSelectedPrinter.length == 1) {

        this.mobjResult.OutputItems = true;
        this.mobjResult.OutputPath = this.marSelectedPrinter[0].FileSavePath;
        this.mobjResult.OutputNotes = "";
        this.mobjResult.OutputToPrinter = true;
        this.mobjResult.OutputToFile = false;
        this.mobjResult.CorrespondencePrinterIdent = this.marSelectedPrinter[0].Ident;

        this.Cancel();

      } else {

        this.mobjResult.OutputItems = false;
        this.mobjResult.OutputPath = "";
        this.mobjResult.OutputNotes = "";
        this.mobjResult.OutputToPrinter = false;
        this.mobjResult.OutputToFile = false;
        this.mobjResult.CorrespondencePrinterIdent = 0;

        // TODO: Actual messaging, I think. Is this worth a round trip?
        this.modShared.AddMessage(new ABCMessage(0, "You must select one printer in order to output.", modSharedService.enmABCMessageSeverity.Severe, ""));

      }

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  PrinterSelectionChanged(arSelected: any[]) {

    try {

      this.marSelectedPrinter = arSelected;

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  ngOnInit() {

    try {

      this.mfrmForm = new UntypedFormGroup({

        'CorrespondencePrinterIdent': new UntypedFormControl(0, [])

      });


    } catch (ex) {

      this.modShared.LogClientError(ex);

    }


  }

}
