import { Injectable } from '@angular/core';
import { modSharedService } from "./modShared.service";
import { ABCDictionary } from "../models/abc.dictionary.model";


@Injectable()
export class RecordLockingService {

  constructor(private modShared: modSharedService) { }

  UnlockRecord(intTableToUnlock: number, intRecordIdent: number) {
    
    try {

      // Unlock the individual record
      return this.modShared.CreateHttpPostRequest('/Locking/UnlockBatch', {

        RecordsToUnlock: intRecordIdent.toString(),
        TableToUnlock: intTableToUnlock

      });

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  
  UnlockRecordBatch(intTableToUnlock: number, arItems: any[]) {

    let strRecords: string = "";

    try {

      for (let itmItem of arItems) {

        strRecords += itmItem.Ident.toString() + ",";

      }

      // Search the ABC User Type table
      return this.modShared.CreateHttpPostRequest('/Locking/UnlockBatch', {

        RecordsToUnlock: strRecords,
        TableToUnlock: intTableToUnlock

      });

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  LockRecord(intTableToLock: number, intRecordIdent: number, bolClearMessages: boolean = true) {
    
    try {
           
      // Lock an individual record
      return this.modShared.CreateHttpPostRequest('/Locking/LockBatch', {

        RecordsToLock: intRecordIdent.toString(),
        TableToLock: intTableToLock

      }, false, bolClearMessages);

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  
  LockRecordBatch(intTableToLock: number, arItems: any[]) {

    let strRecords: string = ""; 

    try {

      for (let itmItem of arItems) {

        strRecords += itmItem.Ident.toString() + ",";

      } 
      
      // Search the ABC User Type table
      return this.modShared.CreateHttpPostRequest('/Locking/LockBatch', {

        RecordsToLock: strRecords,
        TableToLock: intTableToLock

      });

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }
   
}
