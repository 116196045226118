import { Input, MultipleChoice, Checkbox, TextArea, Upload, Telephone, Email, CustomButton  } from "../dynamic-controls/form-controls/form-controls";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';


@Injectable()
export class FormDataService {

  constructor(private http: HttpClient) {
    
  }

  jsonFolder: string = "../../../assets/entities/";
  ext: string = ".json";

  public getJSON(fileName): any {

    return this.http.get(this.jsonFolder + fileName + this.ext);

  }

}
