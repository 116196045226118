import { Component, OnDestroy, OnInit } from '@angular/core';
import { modSharedService } from '../shared/services/modShared.service';
import { UntypedFormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { ApiResponse } from '../shared/models/api-response.model';
import { MaintenanceService } from '../shared/services/maintenance.service';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ABCMessage } from '../shared/models/abc-message.model';
import { AccountService } from '../shared/services/account.service';
import { ABCDictionary } from '../shared/models/abc.dictionary.model';
import { DatePipe } from '@angular/common';
import { ChangeClientFocusComponent } from './change-client-focus/change-client-focus.component';
import { ManageUsersService } from '../shared/services/manageUsers.service';
import { ClientsService } from '../shared/services/clients.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  // Dropdowns

  menmScreenModes = {
    Search: 0
  }

  // Properties
  ScreenMode: number = this.menmScreenModes.Search;
  mfrmForm: UntypedFormGroup;

  constructor(private modShared: modSharedService,
    private svcAccountService: AccountService,
    private svcManageUsersService: ManageUsersService,
    private svcClientsService: ClientsService, 
    private svcModalService: NgbModal,
    private svcDatePipe: DatePipe,
    private rtRouter: Router) { }

  ngOnInit() {

    let rfModal: NgbModalRef = null;

    try {

      if (!this.modShared.CheckUserInPermission(modSharedService.enmABCPermission.ClickABCStaffAccess)
        && !this.modShared.CheckUserInPermission(modSharedService.enmABCPermission.ClickABCClientAccess)) {

        this.rtRouter.navigate(['/'], { state: { data: { IsAuthorized: false } } });

      }

      this.mfrmForm = new UntypedFormGroup({});

      if (this.modShared.CheckUserInPermission(modSharedService.enmABCPermission.ClickABCStaffAccess)) {

        if (this.modShared.mintClientFocusIdent == 0) {

          this.OpenClientFocus(); 

        }

      } else {

        if (this.modShared.mintClientFocusIdent == 0) {

          this.svcManageUsersService.GetWebUserDetails()
            .subscribe(

              (rspResponse: ApiResponse) => {

                if (rspResponse.Succeeded) {

                  // Successful.
                  this.modShared.mstrClientFocusName = this.modShared.ExtractValueFromPayload(rspResponse, "ClientFocusName");
                  this.modShared.mstrClientFocusNumber = this.modShared.ExtractValueFromPayload(rspResponse, "ClientFocusNumber");
                  this.modShared.mintClientFocusIdent = this.modShared.ExtractValueFromPayload(rspResponse, "ClientFocusIdent");

                  this.modShared.SetEscalations(this.modShared.ExtractDataFromPayload(rspResponse, "Escalation"));

                } else {

                  this.rtRouter.navigate(['/'], { state: { data: { IsAuthorized: false } } });

                } // ELSE: Succeeded

              });

        } 


      } 




    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  OpenClientFocus() {

    let rfModal: NgbModalRef = null;
    let itmClient: any = {};

    try {

      // Display the modal
      rfModal = this.svcModalService.open(ChangeClientFocusComponent, { backdrop: 'static', size: 'xl' });
      rfModal.result.then((objResult) => {

        if (objResult.RecordSaved) {

          this.svcClientsService.GetClientAndStructureByIdent(objResult.ClientIdent)
            .subscribe(

              (rspResponse: ApiResponse) => {

                if (rspResponse.Succeeded) {

                  // Successful.
                  itmClient = this.modShared.ExtractDataFromPayload(rspResponse, "Client")[0];
                  
                  this.modShared.mstrClientFocusName = itmClient.PublicName1;
                  this.modShared.mstrClientFocusNumber = itmClient.ClientNumber;
                  this.modShared.mintClientFocusIdent = itmClient.Ident;

                  this.modShared.SetEscalations(this.modShared.ExtractDataFromPayload(rspResponse, "Escalation"));

                } // Succeeded

              });

          this.modShared.SetLoading(false); 

        }

      }, (reason) => {

        // They dismissed without making a selection

      });


    } catch (ex) {

      this.modShared.LogClientError(ex);

    }


  } 

  ClientFocusIdent(): number {

    try {

      return this.modShared.mintClientFocusIdent;

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }


  } 


}
