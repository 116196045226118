
export class ABCMessage {

  Ident: number;
  PublicDescription: string;
  ABCMessageSeverityIdent: number;
  MessageNumber: string;

  constructor(intIdent: number, strPublicDescription: string, intABCMessageSeverityIdent: number, strMessageNumber: string) {

    this.Ident = intIdent;
    this.PublicDescription = strPublicDescription;
    this.ABCMessageSeverityIdent = intABCMessageSeverityIdent;
    this.MessageNumber = strMessageNumber;

  }

} 
