import { Injectable } from '@angular/core';
import { modSharedService } from "./modShared.service";
import { ABCDictionary } from "../models/abc.dictionary.model";
import { UntypedFormGroup } from '@angular/forms';

@Injectable()
export class AccountService {

  constructor(private modShared: modSharedService) { }


  GetScreenInformationForAccountSearch(intClientIdent: number) {

    try {

      // Call the Services API
      return this.modShared.CreateHttpPostRequest('/Accounts/GetScreenInformationForAccountSearch', {

        ClientIdent: intClientIdent

      });

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  SearchAccounts(intClientIdent: number, strDocumentNumber: string, strAccountNumber: string, strCustomerName: string, strClaimNumber: string,
                  intEscalationCustomerResponseIdent: number, intCustomerResponseIdent: number, bolIncludeWorkEscalation: boolean) {

    try {

      // Call the Services API
      return this.modShared.CreateHttpPostRequest('/Accounts/SearchAccounts', {

        ClientIdent: intClientIdent,
        DocumentNumber: strDocumentNumber,
        AccountNumber: strAccountNumber,
        CustomerName: strCustomerName,
        ClaimNumber: strClaimNumber,
        EscalationCustomerResponseIdent: intEscalationCustomerResponseIdent,
        CustomerResponseIdent: intCustomerResponseIdent,
        IncludeWorkEscalation: bolIncludeWorkEscalation,

      });

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  GetABCUserInformation(intABCUserIdent: number) {

    try {

      // Call the Services API
      return this.modShared.CreateHttpPostRequest('/Accounts/GetABCUserInformation', {

        ABCUserIdent: intABCUserIdent

      });

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }

  GetAttorneyInformation(intAttorneyIdent: number) {

    try {

      // Call the Services API
      return this.modShared.CreateHttpPostRequest('/Accounts/GetAttorneyInformation', {

        AttorneyIdent: intAttorneyIdent

      });

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }


  GetAccountDetails(intAccountIdent: number, intClaimIdent: number) {

    try {

      // Call the Services API
      return this.modShared.CreateHttpPostRequest('/Accounts/GetAccountDetails', {

        AccountIdent: intAccountIdent,
        ClaimIdent: intClaimIdent

      });

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }


  AddActivity(intAccountIdent: number, strNotes: string, intFollowUpSettingIdent: number) {

    try {

      // Call the Services API
      return this.modShared.CreateHttpPostRequest('/Accounts/AddActivity', {

        AccountIdent: intAccountIdent,
        Notes: strNotes,
        FollowUpSettingIdent: intFollowUpSettingIdent

      });

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }


  ClearAttachmentsFromClaimSubmission(strUserFolderGUID: string, bolExcludeForwarding: boolean = false) {

    try {

      // Call the Services API
      return this.modShared.CreateHttpPostRequest('/Accounts/ClearAttachmentsFromClaimSubmission', {

        UserFolderGUID: strUserFolderGUID,
        ExcludeForwarding: bolExcludeForwarding

      });

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }


  AddClaimSubmission(frmForm: UntypedFormGroup, strUserGUID: string, arStatus: any[], arDocuments: any[]) {

    let objParameters: any;
    let strSelectedStatus: string = ""; 
    let strSelectedDocuments: string = "";

    try {


      for (let itmItem of arStatus) {

        strSelectedStatus += itmItem.toString() + ",";

      }

      for (let itmItem of arDocuments) {

        strSelectedDocuments += itmItem.toString() + ",";

      }

      // Call the Services API
      objParameters = this.modShared.CreateParametersForSave(frmForm);
      objParameters.UserGuid = strUserGUID;
      objParameters.SelectedStatus = strSelectedStatus;
      objParameters.SelectedDocuments = strSelectedDocuments;

      return this.modShared.CreateHttpPostRequest('/Accounts/AddClaimSubmission', objParameters);

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }



  AddAttachmentToClaimSubmission(filFileAttachment: string, strAttachmentFileName: string, strUserFolderGUID: string) {

    try {

      // Call the Services API
      return this.modShared.CreateHttpPostRequest('/Accounts/AddAttachmentToClaimSubmission', {

        AttachmentFileName: strAttachmentFileName,
        FileAttachmentBytes: filFileAttachment,
        UserFolderGUID: strUserFolderGUID

      });

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }


  GetClientAndDebtorInformation() {

    try {

      // Call the Services API
      return this.modShared.CreateHttpPostRequest('/Accounts/GetClientAndDebtorInformation', {

      });

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }


  CompletePaymentPortalPayment(mnyPaymentAmount: number, strReceiptEmailAddress: string, strBillingFirstName: string,
    strBillingLastName: string, strBillingAddress1: string, strBillingCity: string, intBillingStateIdent: number, strBillingZipCode: string,
    strCardNumber: string, intExpirationMonth: number, intExpirationYear: number, strCardCCV: string, arDocumentsToPay: any[]) {

    try {

      // Call the Services API
      return this.modShared.CreateHttpPostRequest('/Accounts/CompletePaymentPortalPayment', {

        PaymentAmount: mnyPaymentAmount,
        ReceiptEmailAddress: strReceiptEmailAddress,
        BillingFirstName: strBillingFirstName,
        BillingLastName: strBillingLastName,
        BillingAddress1: strBillingAddress1,
        BillingCity: strBillingCity,
        BillingStateIdent: intBillingStateIdent,
        BillingZipCode: strBillingZipCode,
        CardNumber: strCardNumber,
        ExpirationMonth: intExpirationMonth,
        ExpirationYear: intExpirationYear,
        CardCCV: strCardCCV,
        DocumentsToPay: arDocumentsToPay

      });

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }

  }




}

