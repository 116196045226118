// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .navbar-nav > li > .dropdown-menu {
  background: #dc6a3f !important;
  background-color: #dc6a3f !important;
  color: #fff !important;
}

::ng-deep .dropdown-item:hover, .dropdown-item.active, .dropdown-item:active {
  color: #000 !important;
  text-decoration: none;
  background-color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/app/core/header/header.component.scss","webpack://./../../../../ABC%20Core/Client%20Portal/Website/Web/src/app/core/header/header.component.scss","webpack://./src/theme/abcCollectVariables.scss"],"names":[],"mappings":"AAEA;EACE,8BAAA;EACA,oCAAA;EACA,sBAAA;ACDF;;ADIA;EACE,sBAAA;EACA,qBAAA;EACA,sBEVmB;ADSrB","sourcesContent":["@import '../../../theme/abcCollectVariables.scss';\r\n\r\n::ng-deep .navbar-nav > li > .dropdown-menu {\r\n  background: $primary-color !important;\r\n  background-color: $primary-color !important;\r\n  color: $primary-text-color !important;\r\n}\r\n\r\n::ng-deep .dropdown-item:hover, .dropdown-item.active, .dropdown-item:active {\r\n  color: $secondary-text-color !important;\r\n  text-decoration: none;\r\n  background-color: $primary-text-color;\r\n}","::ng-deep .navbar-nav > li > .dropdown-menu {\n  background: #dc6a3f !important;\n  background-color: #dc6a3f !important;\n  color: #fff !important;\n}\n\n::ng-deep .dropdown-item:hover, .dropdown-item.active, .dropdown-item:active {\n  color: #000 !important;\n  text-decoration: none;\n  background-color: #fff;\n}","$primary-color :#dc6a3f;\r\n$primary-text-color: #fff;\r\n$secondary-color :#69b3e7;\r\n$secondary-text-color: #000;"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
