import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { modSharedService } from '../../shared/services/modShared.service';
import { ManageUsersService } from '../../shared/services/manageUsers.service';
import { ApiResponse } from '../../shared/models/api-response.model';
import { ABCMessage } from '../../shared/models/abc-message.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  mfrmChangePassword: UntypedFormGroup;

  // Password requirments, used on screen and within component
  mintMinNumberPasswordRequirementsVariable: number;
  mintPasswordMinLengthVariable: number;
  mintPasswordNonAlphaVariable: number;
  mintPasswordNumberVariable: number;
  mintPasswordUpperCaseVariable: number;
  mintABCUserHistoryIntervalVariable: number;

  constructor(private modShared: modSharedService,
              private svcManageUsersService: ManageUsersService,
              private rtRouter: Router) {  }

  ngOnInit() {

    try {

      let arResults: any[];

      this.mfrmChangePassword = new UntypedFormGroup({

        'CurrentPassword': new UntypedFormControl('', [Validators.required]),
        'NewPassword': new UntypedFormControl('', [Validators.required]),
        'ConfirmNewPassword': new UntypedFormControl('', [Validators.required])

      });

      // Get the password Requirments
      this.svcManageUsersService.GetPasswordRequirements()
        .subscribe(
          (rspResponse: ApiResponse) => {

            arResults = this.modShared.ExtractDataFromPayload(rspResponse, "PasswordRequirements");

            this.mintMinNumberPasswordRequirementsVariable = arResults[0].MinNumberPasswordRequirementsVariable;
            this.mintPasswordMinLengthVariable = arResults[0].PasswordMinLengthVariable;
            this.mintPasswordNonAlphaVariable = arResults[0].PasswordNonAlphaVariable;
            this.mintPasswordNumberVariable = arResults[0].PasswordNumberVariable;
            this.mintPasswordUpperCaseVariable = arResults[0].PasswordUpperCaseVariable;
            this.mintABCUserHistoryIntervalVariable = arResults[0].ABCUserHistoryIntervalVariable;

            });

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }
    
  }

  ClearFormControls() {

    try {

      // Reset initial values
      this.mfrmChangePassword.setValue({
        CurrentPassword: '',
        NewPassword: '',
        ConfirmNewPassword: ''
      });

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }


  }

  ClearControls() {

    try {

      this.mfrmChangePassword.reset();

      this.ClearFormControls(); 

      this.modShared.MessagesCleared.next();

    } catch (ex) {

      this.modShared.LogClientError(ex)

    }

  }


  Cancel() {

    try {

      this.ClearControls();

      this.rtRouter.navigate(['/dashboard']);

    } catch (ex) {

      this.modShared.LogClientError(ex);

    }


  }

  ChangePassword() {

    let strCurrentPassword: string = "";
    let strNewPassword: string = "";
    let strConfirmNewPassword: string = "";

    try {

      strCurrentPassword = this.mfrmChangePassword.controls.CurrentPassword.value;
      strNewPassword = this.mfrmChangePassword.controls.NewPassword.value;
      strConfirmNewPassword = this.mfrmChangePassword.controls.ConfirmNewPassword.value;
      
      if (this.modShared.ValidateForm(this.mfrmChangePassword, [])) {

        if (strNewPassword !== strConfirmNewPassword) {

          // TODO: Do we want to dynamically pull a message here? I don't think so, avoid
          // the round trip.
          this.modShared.AddMessage(new ABCMessage(0, "New Password and Confirm New Password must match", modSharedService.enmABCMessageSeverity.Severe, ""));

        } else {   

          // Try to update
          this.svcManageUsersService.ResetPassword(strCurrentPassword, strNewPassword, strConfirmNewPassword)
            .subscribe(
              (rspResponse: ApiResponse) => {

                if (rspResponse.Succeeded) {

                  //Password updated, removing restriction on access.
                  this.modShared.mbolRestrictAccess = 'false';
                  this.rtRouter.navigate(['/']);
                  
                }

                this.ClearFormControls();

              });
          
        } // Else: New = Confirm

      } // this.modShared.ValidateForm


    } catch (ex) {

      this.modShared.LogClientError(ex)

    }
       
  } 
  
}
